import React from 'react';

const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
}

const layoutWidthTypes = {
  FLUID: "fluid",
  BOXED: "boxed",
  SCROLLABLE: "scrollable",
}

const layoutModeTypes = {
  DARK: "dark",
  LIGHT: "light",
}

const topBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
  COLORED: "colored",
}

const leftBarThemeImageTypes = {
  NONE: "none",
  IMG1: "img1",
  IMG2: "img2",
  IMG3: "img3",
  IMG4: "img4",
}

const leftSidebarTypes = {
  DEFAULT: "default",
  COMPACT: "compact",
  ICON: "icon",
}

const leftSideBarThemeTypes = {
  LIGHT: "light",
  COLORED: "colored",
  DARK: "dark",
  WINTER: "winter",
  LADYLIP: "ladylip",
  PLUMPLATE: "plumplate",
  STRONGBLISS: "strongbliss",
  GREATWHALE: "greatwhale",
}
const userRoleList = {
  SuperAdmin: 2,
  Admin: 4,
  Client: 5,
  DataEntry: 6,
  Doctor: 7
}
const errorMessages = {
  NEWPASSWORD_ERROR: 'Please enter password',
  CONFORMPASSWORD_ERROR: 'Please enter confirm password',
  EQUALCONFORMPASSWORD_ERROR: 'password and confirm password dose not match',
  PASSWORD_VALIDATION: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
  PASSWORD_VALIDATIONERROR: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
  CONFIRMPASSWORD_ERROR: 'Please enter confirm password',
  EQUALCONFIRMPASSWORD_ERROR: 'password and confirm password dose not match'
}
const invalidRefreshToken = 498

const textboxLength = {
  u_name: 50,
  u_loginid: 15,
  u_password: 30,
  ur_name: 50,
  uhid: 15,
  age: 2,
  reportno: 30,
  pt_name: 50,
  ph_num: 15,
  city: 50,
  template_name: 50
}

const genderMaster = [
  { id: 1, g_name: 'Male' },
  { id: 2, g_name: 'Female' },
  { id: 3, g_name: 'Transgender' }
]

const MENUDETAILS = { MenuDetails: [{ MenuId: 1, InputLabel: 'Hospital Name', MenuTitle: 'Hospital' }, { MenuId: 2, InputLabel: 'Referrer Name', MenuTitle: 'Referrer' }, { MenuId: 3, InputLabel: 'Mitral Valve', MenuTitle: 'Mitral Valve' }, { MenuId: 4, InputLabel: 'Aortic Valve', MenuTitle: 'Aortic Valve' }, { MenuId: 5, InputLabel: 'Tricuspid Valve', MenuTitle: 'Tricuspid Valve' }, { MenuId: 6, InputLabel: 'Pulmonary Valve', MenuTitle: 'Pulmonary Valve' }, { MenuId: 7, InputLabel: 'IAS & IVS Valve', MenuTitle: 'IAS & IVS Valve' }, { MenuId: 8, InputLabel: 'Left Atrium', MenuTitle: 'Left Atrium' }, { MenuId: 14, InputLabel: 'Right Atrium', MenuTitle: 'Right Atrium' }, { MenuId: 9, InputLabel: 'Right Ventricle', MenuTitle: 'Right Ventricle' }, { MenuId: 10, InputLabel: 'Great Vessels', MenuTitle: 'Great Vessels' }, { MenuId: 11, InputLabel: 'Pericardium', MenuTitle: 'Pericardium' }, { MenuId: 12, InputLabel: 'Left Ventricle', MenuTitle: 'Left Ventricle' }, { MenuId: 13, InputLabel: 'Impression', MenuTitle: 'Impression' }] }

const capitalize = (value) => {
  return value?.replace(/\b\w/g, (char) => char?.toUpperCase());
}

const fullCaps = (value) => {
  return value?.toUpperCase();
}
const fullCapsBold = (value) => {
  return <strong style={{ textTransform: 'uppercase' }}>{value}</strong>;
};

const menu_id = {
  reportreq: 1,
  reportapp: 2,
  report: 3,
  reporttemplate: 4
}

export {
  layoutTypes,
  layoutModeTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
  MENUDETAILS,
  errorMessages,
  textboxLength,
  invalidRefreshToken, genderMaster,
  capitalize,
  fullCaps,
  userRoleList,
  fullCapsBold,
  menu_id
}
