import PropTypes from "prop-types";
import React, { useMemo, useEffect, useState, useRef } from "react";
import {
    Container
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import TableContainer from '../../components/Common/TableContainer';

//i18n
import { withTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge, InputGroup } from "reactstrap";
import ".././../assets/css/custom.css";

import Spinners from "../../components/Common/Spinner";
import { useFormik } from "formik";
import * as Yup from "yup";
import { MENUDETAILS } from "constants/layout";
import DeleteModal from "../../components/Common/DeleteModal";
// import API from '../Authentication/api/index';
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import { getRefreshToken } from "../../helpers/fakebackend_helper";
import axios from "axios"
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { ENDPOINT } from "../Authentication/api/index"
import * as constants from "constants/layout";
import Logout from "pages/Authentication/Logout";
import { useNavigate } from "react-router-dom";

const MasterScreens = props => {
    const navigate = useNavigate()

    const menuType = useLocation();
    const user = getLoggedInUser();

    const [list, setList] = useState("");
    const [menuDetailsList, setMenuDetailsList] = useState([]);
    const [title, setTitle] = useState("");
    const [menuColumns, setMenuColumns] = useState([]);
    const [columns, setColumns] = useState([]);
    const [menu, setMenu] = useState(0);
    const [modal, setModal] = useState(false);
    const [hospitalName, setHospitalName] = useState('');
    const [label, setLabel] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteData, setDeleteDate] = useState('');
    const [submitbtn, setSubmitbtn] = useState(false);
    const [bold, setBold] = useState(false);
    const [canCreate, setCanCreate] = useState(false)
    const [status, setStatus] = useState(true);
    const [statusFlag, setStatusFlag] = useState();
    const [editData, setEditData] = useState([]);
    const [isButtonDisabled, setButtonDisabled] = useState(false);

    const masterLabelRef = useRef(null);

    // const contentToPrint = useRef(null);


    const toggleStatus = () => {
        setStatus(!status);
        console.log('toogle');
        // status_text = status == true ? 0 : 1;
        // validation.values.status = status_text
    };

    useEffect(() => {
        // getExamBoardList()
        document.getElementById('headerTitle').innerHTML = title;
    }, [title]);

    const Details = MENUDETAILS


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            labelname: (hospitalName) || '',
            bold: (bold) || false,
        },
        validationSchema: Yup.object({
            labelname: Yup.string().required('Please enter ' + label.toLocaleLowerCase()).matches(/^[a-zA-Z0-9&' \-_.,]+$/, 'Values only contain letters and numbers.'),
        }),
        // onSubmit: (values) => {
        //     handleSave()
        // }
        onSubmit: (values) => {
            const data = {
                user_id: Number(user.uid),
                rm_type: menu,
                rm_value: Number(menu) === 2 ? String(values.labelname.toUpperCase()) : String(nameValidation(values.labelname)),
                rm_bold: values.bold == true ? 1 : 0,
                rm_id: isEdit ? editData.mvid : 0,
                rm_status: status == true ? 1 : 2,
                process_type: isEdit ? 'update' : 'save',
            }
            insertMasterData(data)
            setButtonDisabled(true)
        },
    });

    const handleEditClick = (editRowData) => {

        setIsEdit(true)
        setModal(true)
        setEditData(editRowData)
        validation.setFieldValue('labelname', editRowData.mv_value)
        var bold_text = editRowData.mv_bold == 1 ? true : false;
        validation.setFieldValue('bold', bold_text)
        if (editRowData.mv_status === 1) {
            setStatus(true)
        } else {
            setStatus(false)
        }
    };

    useEffect(() => {
        if (menuType?.state) {
            if (menuType?.state?.menuId) {
                setMenu(menuType?.state?.menuId)
                MasterDataFetch(menuType?.state?.menuId)
            }
        }
    }, [menuType])

    // if (modal === true) {

    //     // useEffect(() => {
    //     masterLabelRef.current.handleFocus()
    //     // }, [modal])

    // }

    // if (masterLabelRef.current) {
    //     masterLabelRef.current.focus();
    // }

    // if (modal === true) {
    //     // useEffect(() => {
    //     if (masterLabelRef.current) {
    //         masterLabelRef.current.focus();
    //     }
    //     // }, [modal === true]);
    // }

    // Modal Open
    const toggle = () => {
        setModal(prev => !prev);
        resetForm()
    };

    useEffect(() => {
        if (modal === true) {

            // useEffect(() => {
            if (masterLabelRef.current) {
                masterLabelRef.current.focus();
            }
            // }, [modal === true]);
        }

    }, [modal])

    const MasterDataFetch = (menu) => {
        var menuData = Details?.MenuDetails.filter(item => item.MenuId === menu)

        setTitle(menuData[0].MenuTitle)
        setLabel(menuData[0].InputLabel)
        const columns = [
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        #
                    </div>
                ),
                accessorKey: 'SNo',
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ row, table }) => {


                    return (
                        <div className="text-center">
                            {(table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1}
                        </div>

                    );
                }
            },

            {
                header: (

                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Name ▼
                    </div>
                ),
                accessorKey: 'mv_value',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const name = cellProps.row.original.mv_value;

                    return (

                        // <div className="d-flex align-items-center">
                        <div className="text-right">
                            {name}

                        </div >
                    );
                },
            },
            {
                header: (

                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Status ▼
                    </div>
                ),
                accessorKey: 'mv_status',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const status = cellProps.row.original.mv_status;

                    return (
                        <>
                            {Number(status) === 1 && (
                                <div className="text-center">
                                    <span className="badge badge-soft-success rounded-pill ms-1 font-size-12">
                                        <i className="text-center" style={{ fontSize: '16px' }} />
                                        Active
                                    </span>
                                </div>
                            )}
                            {Number(status) === 2 && (
                                <div className="text-center">
                                    <span className="badge badge-soft-danger rounded-pill ms-1 font-size-12">
                                        <i className="text-center" style={{ fontSize: '16px' }} />
                                        Inactive
                                    </span>
                                </div>
                            )}
                        </>
                    );
                },
            },
            {
                header: (

                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Created By ▼
                    </div>
                ),
                // header: 'Created By',
                accessorKey: 'created_at',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const created_at = cellProps.row.original.created_at;

                    return (

                        // <div className="d-flex align-items-center">
                        <div className="text-center">
                            {created_at}

                        </div >
                    );
                },
            },
            {
                header: (

                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Updated By ▼
                    </div>
                ),
                // header: 'Updated By',
                accessorKey: 'updated',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const updated = cellProps.row.original.updated;

                    return (

                        // <div className="d-flex align-items-center">
                        <div className="text-center">
                            {updated}

                        </div >
                    );
                },
            },
            {
                header: (

                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Actions
                    </div>
                ),
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: false,
                cell: (cellProps) => {
                    const id = cellProps.row.original.mvid
                    const default_value = cellProps.row.original.mv_default
                    return (
                        <div className="d-flex gap-3 justify-content-center" style={{ cursor: 'pointer' }}>
                            <div
                                to="#"
                                className="btn btn-sm btn-soft-info pointer"
                                onClick={() => {
                                    const editRowData = cellProps.row.original;
                                    handleEditClick(editRowData);
                                }}
                            >
                                <i className="mdi mdi-pencil-outline pointer" id={"edittooltip" + id} />
                                <UncontrolledTooltip placement="top" target={"edittooltip" + id}>
                                    Edit
                                </UncontrolledTooltip>
                            </div>
                            {default_value !== 1 && <div
                                to="#"
                                className="btn btn-sm btn-soft-danger pointer"
                                onClick={() => {
                                    const deleteData = cellProps.row.original; onClickDelete(deleteData);
                                }}>
                                <i className="mdi mdi-delete-outline pointer" id={"deletetooltip" + id} />
                                <UncontrolledTooltip placement="top" target={"deletetooltip" + id}>
                                    Delete
                                </UncontrolledTooltip>
                            </div>}
                        </div>
                    );
                }
            },
        ]
        setColumns(columns);

        //fetch list data for corresponding menu 
        dataFetch(menu);
    }

    const dataFetch = async (menuid) => {
        // setList(data);
        setLoading(true)

        const data = {
            user_id: Number(user.uid),
            rm_type: menuid
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTMASTERSLIST, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");  
                     navigate('/login')
                 }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");  
                        navigate('/login')
                    } else {
                        await dataFetch(menuid);
                    }
                }
            }

            if (response?.data?.body?.MasterList) {
                setList(response.data.body.MasterList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error("Master List API Error:", error);
        }
    }

    const insertMasterData = async (data) => {
        // resetForm()
        try {
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTMASTERSSAVE, data, config);
            setButtonDisabled(false)
            if (response) {
                if (response && response?.data?.body?.statusFlag === 3) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                    setTimeout(() => {
                        localStorage.removeItem("authUser");  
                         navigate('/login')
                     }, 2000);
                }

                if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                    const refresh = await getRefreshToken();

                    if (refresh) {
                        if (Number(refresh) === constants.invalidRefreshToken) {
                            localStorage.removeItem("authUser");  
                         navigate('/login')
                        } else {
                            await insertMasterData(data);
                        }
                    }
                }

                setStatusFlag(response?.data?.body?.statusFlag)
                if (response && response?.data?.body?.statusFlag === 1) {
                    toggle()
                    resetForm()
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                    dataFetch(menu)
                } else if (response && response?.data?.body?.statusFlag === 2) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                    setModal(true)
                } else if (response && response?.data?.body?.statusFlag === 4) {
                    toggle()

                    resetForm()
                    dataFetch(menu)
                    toast.success(response?.data?.body?.message, { autoClose: 2000 })
                    // toast.success(response?.data?.body?.message, { autoClose: 2000 });
                    // dataFetch(menu)
                }
                else {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 })
                }
            }
        } catch (error) {
            setButtonDisabled(false)
            console.log('error in Add Master', error)
            toast.error("Data added failed", { autoClose: 2000 });
        }
    }

    const handleAddClick = () => {
        if (masterLabelRef.current) {
            masterLabelRef.current.focus();
        }
        setIsEdit(false)
        setModal(true)
        if (masterLabelRef.current) {
            masterLabelRef.current.focus();
        }
    }

    const resetForm = () => {

        setModal(false)
        validation.setTouched('labelname', false)
        validation.values.labelname = '';

        validation.values.bold = false;
        setHospitalName("")
        // setLabel('')
        setStatus(true)
    }

    const onClickDelete = (deleteData) => {
        setDeleteDate(deleteData);
        setDeleteModal(true);
    };

    const handleDelete = async () => {
        try {
            const data = {
                user_id: Number(user.uid),
                rm_type: deleteData.mv_mrid,
                rm_id: deleteData.mvid,
            }
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTMASTERSDELETE, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");  
                     navigate('/login')
                 }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");  
                        navigate('/login')
                    } else {
                        await handleDelete();
                    }
                }
            }

            if (response && response?.data?.body?.statusFlag === 1) {
                setDeleteModal(false)
                dataFetch(menu)
                toast.success(response?.data?.body?.message, { autoClose: 2000 });
            } else {
                setDeleteModal(false)
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
            }
        } catch (error) {
            toast.error("Master Deleted Failed", { autoClose: 2000 });
        }
    };

    useEffect(() => {
        setCanCreate(true)
    }, [])

    useEffect(() => {
        if (submitbtn) {
            if (submitbtn !== 'savecontinue') {

            }
            setSubmitbtn('')
        }
    }, [submitbtn])

    const nameValidation = (value) => {
        var val = value.charAt(0).toUpperCase() + value.slice(1);
        return val;
    };

    const referrerValidation = (event) => {
        var val = event.target.value.toUpperCase();
        event.target.value = val;
    };

    //meta title
    document.title = title;

    return (
        <React.Fragment>
            <div className="page-content">
                <ToastContainer />
                <DeleteModal
                    show={deleteModal}
                    onDeleteClick={handleDelete}
                    onCloseClick={() => setDeleteModal(false)}
                />
                <Container fluid>
                    {isLoading ?
                        <Spinners setLoading={setLoading} />
                        :
                        <TableContainer
                            columns={columns}
                            data={list || []}
                            isGlobalFilter={true}
                            isPagination={true}
                            isAddButton={canCreate}
                            buttonName="Add"
                            SearchPlaceholder="Search..."
                            pagination="pagination"
                            handleUserClick={handleAddClick}
                            // isCustomPageSize={true}
                            buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        // className="table-row"
                        />
                    }
                </Container>
            </div>
            <Modal isOpen={modal} toggle={toggle} backdrop="static">
                <ModalHeader toggle={toggle} tag="h4">
                    {isEdit ? 'Edit ' : 'Add'} {title}
                </ModalHeader>
                <ModalBody>
                    <Form
                        onSubmit={validation.handleSubmit}
                    >
                        <Row>
                            <Col className="col-12">

                                <>
                                    {title === 'Referrer' ? (


                                        <div className="mb-3">
                                            <Label>{label}<span style={{ color: 'red' }}>*</span></Label>
                                            <InputGroup>
                                                <div className="input-group-text">Dr.</div>
                                                <Input
                                                    className="upper"
                                                    name="labelname"
                                                    type="text"
                                                    placeholder={label}
                                                    maxLength="150"
                                                    autoComplete="off"
                                                    innerRef={masterLabelRef}
                                                    onChange={validation.handleChange}
                                                    // onKeyUp={(e) => referrerValidation(e)}
                                                    value={validation.values.labelname || ""}
                                                    invalid={validation.touched.labelname && validation.errors.labelname}
                                                />
                                                {validation.touched.labelname && validation.errors.labelname && (
                                                    <FormFeedback type="invalid">{validation.errors.labelname}</FormFeedback>
                                                )}
                                            </InputGroup>
                                        </div>
                                    ) : (
                                        <div className="mb-3">
                                            <Label>{label}<span style={{ color: 'red' }}>*</span></Label>
                                            <Input
                                                className="caps"
                                                name="labelname"
                                                type="text"
                                                placeholder={label}
                                                maxLength="150"
                                                autoComplete="off"
                                                innerRef={masterLabelRef}
                                                // onKeyUp={(e) => nameValidation(e)}
                                                onChange={validation.handleChange}
                                                value={validation.values.labelname}
                                                invalid={validation.touched.labelname && validation.errors.labelname}
                                                autoFocus />
                                            {validation.touched.labelname && validation.errors.labelname && (
                                                <FormFeedback type="invalid">{validation.errors.labelname}</FormFeedback>
                                            )}
                                        </div>
                                    )}
                                </>
                            </Col>
                        </Row>
                        <Row>
                            {/* <Col className="col-6 text-end">
                                <div className="form-check form-switch form-switch-md mb-3 me-2"><input type="checkbox" className="form-check-input" name="bold"
                                    onChange={validation.handleChange} value={validation.values.bold} id="customSwitchsizemd" checked={validation.values.bold == 1} /><label className="form-check-label">Bold</label></div>
                            </Col> */}
                            <Col>
                                {isEdit && (
                                    <div className="mb-3">
                                        <Label>Status</Label>
                                        <Col lg="2">
                                            <div className="square-switch">
                                                <input
                                                    type="checkbox"
                                                    id="statusMark"
                                                    className="switch switch-bool"
                                                    defaultChecked={status} // Reflect status state
                                                    onClick={toggleStatus} // Toggle status when switch changes
                                                />
                                                <label
                                                    htmlFor="statusMark"
                                                    data-on-label="active"
                                                    data-off-label="Inactive"
                                                />
                                            </div>
                                        </Col>
                                    </div>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-end">
                                    <Button
                                        color="success"
                                        type="submit"
                                        className="save-user"
                                        onClick={() => {
                                            setSubmitbtn('save')
                                        }}
                                        disabled={isButtonDisabled}

                                    >
                                        {isEdit ? 'Update' : 'Save'}
                                    </Button>
                                    <Button
                                        color="info"
                                        className="save-and-continue-user"
                                        onClick={() => resetForm()}
                                        style={{ marginLeft: '10px' }}
                                    // disabled={isButtonDisabled}

                                    >
                                        Close
                                    </Button>

                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>

        </React.Fragment >
    );
};

MasterScreens.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(MasterScreens);
