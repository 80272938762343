import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState, useRef } from "react";
import {
    Row, Col, Label, Button, UncontrolledTooltip,
    Card, CardBody, FormGroup, InputGroup, FormFeedback, Form, Collapse,
} from "reactstrap";
import Select from "react-select";
import { API, ENDPOINT } from '../Authentication/api/index';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import Spinners from "../../components/Common/Spinner";
import { ToastContainer } from "react-toastify";

import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import '../../components/Common/common.scss'
//i18n
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// const inputRef = useRef(null);
import DeleteModal from "../../components/Common/DeleteModal";
import './reportlist.scss'
import axios from "axios"
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import { getRefreshToken } from "../../helpers/fakebackend_helper";
import CommonFilter from "pages/Common/CommonFilter";
import moment from 'moment';
import * as constants from "../../constants/layout"
import Logout from "pages/Authentication/Logout";

const ReportList = props => {
    //meta title
    document.title = "ReportList | Care AKPS";

    const navigate = useNavigate()

    const pstatus = [
        { id: 1, p_name: "Active" },
        { id: 2, p_name: "Inactive" },
        { id: 2, p_name: "Pending" }
    ]
    const userRole = [
        { id: 1, role_name: "Admin" },
        { id: 2, role_name: "Super Admin" }
    ]
    const [filterData, setFilterData] = useState({
        selectedHospital: 0,
        selectedHospital: 0 - 0,
        selectedHospital: 0,
        selectedHospital: 0,
        selectedHospital: 0,
    });
    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(false);
    const [selectedPatient, setSelectedPatient] = useState(false);
    const [selectedDoctor, setSelectedDoctor] = useState(false);

    const [hospital, setHospital] = useState([]);
    const [doctor, setDoctor] = useState([]);
    const [patient, setPatient] = useState([]);


    const [isEdit, setIsEdit] = useState(false);
    const [selectedHospital, setselectedhospital] = useState(null);
    const [modal, setModal] = useState(false);
    const [canCreate, setCanCreate] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    // const [selectedDates, setSelectedDates] = useState([]);
    const [view, setView] = useState(false);
    const today = new Date();
    const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);
    const [defaultDate, setDefaultDate] = useState([new Date(), new Date()]);
    const flatpickrRef = React.useRef(null);
    const [reportList, setReportList] = useState(false);
    const user = getLoggedInUser();

    useEffect(() => {
        document.getElementById('headerTitle').innerHTML = 'Reports';
        setCanCreate(true)
        getReportEntryList()
    }, []);

    useEffect(() => {
        if (user?.user_previleges.filter(e => Number(e.u_urid) == Number(user?.u_urid) && Number(e.urp_mid) == 1 && Number(e.urp_smid) == 2)) {

        } else {
            localStorage.removeItem("authUser");
            navigate('/login')
        }
    }, [user])

    const onClickView = async (data) => {
        console.log('data', data)
        // navigate('/report-view')
        navigate('/report-view', { state: { rpt_id: data?.rpt_id } })

    }


    const getReportEntryList = async (fildata) => {
        setLoading(true)
        var varFromDate = new Date()
        var varToDate = new Date()
        if (fildata != undefined) {
            if (fildata[0].selectedDates != null) {
                varFromDate = fildata[0].selectedDates[0]
                varToDate = fildata[0].selectedDates[1]
            }
        }

        var date_options = fildata !== undefined && fildata[0].DateOption !== '' && fildata[0].DateOption !== null && fildata[0].DateOption !== undefined ? fildata[0].DateOption : false

        var data = {
            user_id: Number(user.uid),
            hospital_id: fildata !== undefined && fildata[0].selectedHospital !== '' && fildata[0].selectedHospital?.value !== null && fildata[0].selectedHospital?.value !== undefined ? String(fildata[0].selectedHospital.value) : String(0),
            patient_id: fildata !== undefined && fildata[0].selectedPatient?.value !== '' && fildata[0].selectedPatient?.value !== null && fildata[0].selectedPatient?.value !== undefined ? String(fildata[0].selectedPatient.value) : String(0),
            referrer_id: fildata !== undefined && fildata[0].selectedDoctor?.value !== '' && fildata[0].selectedDoctor?.value !== null && fildata[0].selectedDoctor?.value !== undefined ? String(fildata[0].selectedDoctor.value) : String(0),
            from_date: moment(varFromDate).format("YYYY-MM-DD"),
            to_date: moment(varToDate).format("YYYY-MM-DD"),
            rpt_status: [2, 2],
            data_fetch: date_options === false ? 0 : 1
        };
        if (Number(user.u_urid) == 5) // For Client User
        {
            data = {
                user_id: Number(user.uid),
                hospital_id: String(user.u_hospital_id),
                patient_id: fildata !== undefined && fildata[0].selectedPatient !== '' ? String(fildata[0].selectedPatient.value) : String(0),
                referrer_id: fildata !== undefined && fildata[0].selectedDoctor !== '' ? String(fildata[0].selectedDoctor.value) : String(0),
                from_date: moment(varFromDate).format("YYYY-MM-DD"),
                to_date: moment(varToDate).format("YYYY-MM-DD"),
                rpt_status: [2, 2]
            }
        }


        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTLIST, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });

                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getReportEntryList();
                    }
                }
            }

            if (response?.data?.body?.reportEntryList) {
                setReportList(response.data.body.reportEntryList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("Report Entry API Error:", error);
        }
    }

    // Add click

    const handleAddClick = () => {
        navigate('/add-userrole', { state: { urid: '' } })
    };

    const handleEditClick = (userData) => {
        setModal(true)
        setIsEdit(true)
    };

    const handleDeleteUserRole = () => {
        setDeleteTrigger(true)
    };
    // Modal Open
    const toggle = () => {
        setModal(prev => !prev);
    };
    const toggleStatus = () => {
        setStatus(!status);
    };

    const handleApply = () => {
        setView(true)

        setFilterData({
            selectedHospital: selectedHospital ? selectedHospital.value : 0,
            selectedPatient: selectedPatient ? selectedPatient.value : 0,
            selectedSubjectName: selectedHospital ? selectedHospital.value : 0 - 0,
            selectedStatus: selectedStatus ? selectedStatus.value : 0,
            selectedExamPaper: selectedDates.map(date => date.toLocaleDateString()).join(' - ')

        })
    }

    const columns = useMemo(
        () => [
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        #
                    </div>
                ),
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) => {

                    return (

                        <div className="text-center">
                            {(table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1}
                        </div>
                    );
                }
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Report No. ▼
                    </div>
                ),

                accessorKey: 'rpt_report_no',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const rpt_report_no = cellProps.row.original.rpt_report_no;

                    return (

                        <div className="text-center">

                            <Row>

                                <div>{rpt_report_no}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Report Date ▼
                    </div>
                ),

                accessorKey: 'rpt_report_date',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const rpt_report_date = moment(cellProps.row.original.rpt_report_date).format("DD-MM-YYYY");

                    return (

                        <div className="text-center">

                            <Row>

                                <div>{rpt_report_date}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-right" style={{ cursor: 'pointer' }}>
                        Patient Details ▼
                    </div>
                ),

                accessorKey: 'rpt_patient_data.patient_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const patient = cellProps.row.original.rpt_patient_data.patient_name;
                    const age = cellProps.row.original.rpt_patient_data.patient_age;
                    const gender = cellProps.row.original.rpt_patient_data.patient_gender == 1 ? "Female" : "Male";
                    const uhid = cellProps.row.original.rpt_patient_data.patient_uhid;

                    return (
                        // <div className="d-flex align-items-center">
                        <div className="text-right">

                            <Row>

                                <div>{constants.fullCapsBold(patient)}</div>
                            </Row>
                            <Row>

                                <div>
                                    {age ? `${age}Yrs` : ''}
                                    {gender && ' | '}

                                    {gender ? `${gender}` : ''}
                                    {uhid && ' | '}

                                    {uhid ? `UHID:${uhid}` : ''}
                                </div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-right" style={{ cursor: 'pointer' }}>
                        Doctor ▼
                    </div>
                ),

                accessorKey: 'rpt_doctor_data.referrer_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const hospital_name = cellProps.row.original.rpt_hospital_data.hospital_name;
                    const doctor_name = cellProps.row.original.rpt_doctor_data.referrer_name;
                    return (
                        // <div className="d-flex align-items-center">
                        <div className="text-right">

                            <Row>
                                <div>{constants?.fullCapsBold('DR')}.{constants?.fullCapsBold(doctor_name)}</div>
                                <div>{hospital_name}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Approved By. ▼
                    </div>
                ),

                accessorKey: 'u_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const approved_by = cellProps.row.original.u_name;

                    return (

                        <div className="text-center">

                            <Row>

                                <div>{approved_by}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Status ▼
                    </div>
                ),

                accessorKey: 'ur_stsid',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const ur_stsid = cellProps.row.original.rpt_status;

                    return (

                        <div className="text-center">
                            <span style={{ width: 'auto' }} className="badge badge-soft-success rounded-pill  ms-1 font-size-12">

                                <i className="me-1" style={{ fontSize: '16px' }} />
                                {"Approved"}
                            </span>

                        </div>
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Actions ▼
                    </div>
                ),
                // header: 'Actions',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,

                width: '8%',
                // width: (cell) => cell.value.length * 10, 
                cell: (cellProps) => {
                    const id = cellProps.row.original.rpt_id;
                    return (
                        <div className="d-flex gap-3 justify-content-center" style={{ cursor: 'pointer' }}>

                            {/* <Button
                                to="#"
                                className="btn btn-sm btn-soft-info cust-btn pointer"
                                onClick={() => {
                                    const orderData = cellProps.row.original;
                                    handleEditClick(orderData);
                                }}
                            >
                                <i className="bx bxs-printer cust-i pointer" id={"printToolTip" + id} />
                                <UncontrolledTooltip placement="top" target={"printToolTip" + id}>
                                    Print
                                </UncontrolledTooltip>
                            </Button> */}


                            <div
                                to="#"
                                className="btn btn-sm btn-soft-info pointer"
                                onClick={() => {
                                    const orderData = cellProps.row.original;
                                    onClickView(orderData);
                                }}>
                                <i className="mdi mdi-eye pointer" id={"viewtooltip" + id} />
                                <UncontrolledTooltip placement="top" target={"viewtooltip" + id}>
                                    View
                                </UncontrolledTooltip>
                            </div>


                        </div>




                        // <div className="text-center" style={{ cursor: 'pointer' }}>

                        //     <Button
                        //         to="#"
                        //         className="btn btn-sm btn-soft-info"
                        //         onClick={() => {
                        //             const orderData = cellProps.row.original;
                        //             handleEditClick(orderData);
                        //         }}
                        //     >
                        //         <i className="bx bxs-printer" id="printToolTip" />
                        //         <UncontrolledTooltip placement="top" target="printToolTip">
                        //             Print
                        //         </UncontrolledTooltip>
                        //     </Button>


                        // </div>
                    );
                }
            },

        ],
        []
    );



    return (
        <React.Fragment>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteUserRole}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content" style={{ height: '100%' }}>
                <div className="container-fluid">
                    <CommonFilter onFilter={getReportEntryList} />
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={reportList || []}
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                // isAddButton={canCreate}
                                                // buttonName="Add"
                                                SearchPlaceholder="Search..."
                                                pagination="pagination"
                                                handleUserClick={handleAddClick}
                                                // isCustomPageSize={true}
                                                //  paginationWrapper='dataTables_paginate paging_simple_numbers'

                                                buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                theadClass="table-light"

                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                </div>
            </div >
            <ToastContainer />
        </React.Fragment >
    );
};

ReportList.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ReportList);
