const API = {
    MASTER: "api/user-master",
    DEFAULTMASTER: "api/master",
    DEFAULTPATIENT: "api/patient",
    DEFAULTREPORT: "api/report",
    DEFAULTREPORTENTRY: "api/report-entry",
}
const ENDPOINT = {
    LOGIN: "login",
    USERLIST: "getUserList",
    SAVEUSER: "saveUser",
    DELETEUSER: "deleteUser",
    MASTER: "getMaster",
    RESETPASSWORD: "resetPassword",
    CHANGEPASSWORD: "changePassword",
    REPORTMASTERSSAVE: "api/report-master/saveReportMaster",
    REPORTMASTERSLIST: "api/report-master/getReportMasterList",
    REPORTMASTERSDELETE: "api/report-master/deleteReportMaster",
    GETREPORTNO: "api/report-entry/getReportNo",
    GETPRINTSETTINGS: "api/report-entry/getPrintSettings",
    GETPATIENT: "getPatient",
    SAVEPATIENT: "savePatient",
    DELETEPATIENT: "deletePatient",
    SAVETEMPLATE: "saveTemplate",
    GETTEMPLATE: "getTemplate",
    DELETETEMPLATE: "deleteTemplate",
    EDITLOADTEMPLATE: "editLoadTemplate",
    GETHOSPITALMASTER: "/getHospitalMaster",
    GETMASTERS: '/getHospital',
    REPORTLIST: 'api/report-entry/getReportEntryList',
    REPORTSAVE: "api/report-entry/saveReportEntry",
    REPORTDELETE: "api/report-entry/deleteReportEntry",
    HOSPITALMASTER: "getHospitalMaster",
    REPORTAPPROVE: "api/report-master/approveReportMaster",
    REPORTENTRYEDITLOAD: "api/report-entry/editLoadReportEntry"
}


export {
    API,
    ENDPOINT
}
