import React, { useState, } from "react";
import { ToastContainer } from "react-toastify";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import { Button, Col, Row, Input, Label, Card, CardBody, FormGroup } from "reactstrap";
import TableContainer from '../../components/Common/TableContainer';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import './userrole.scss'
import * as constants from "../../constants/layout"
import { toast } from "react-toastify";

const Userrole = (props) => {
    const navigate = useNavigate()
    const [isEdit, setIsEdit] = useState('')
    const [userRoleName, setuserRoleName] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [changeEvent, setChangeEvent] = useState(false)
    const [mainMenu, setmainMenu] = useState('')

    const [columns, setColumns] = useState(
        [
            {
                header: 'S.No.',
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
            },
            // {
            //     header: 'Main Menu',
            //     accessorKey: 'Main',
            //     enableColumnFilter: false,
            //     enableSorting: true,
            // },
            {
                header: 'Sub Menu',
                accessorKey: 'm_smname',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'View',
                accessorKey: "view",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Create',
                accessorKey: "create",
                enableColumnFilter: false,
                enableSorting: true,
            },

            {
                header: 'Modify',
                accessorKey: "modify",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Remove',
                accessorKey: "remove",
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Special',
                accessorKey: "special",
                enableColumnFilter: false,
                enableSorting: true,
            },

        ],
        []
    );
    const saveUserRoleMenus = () => {
        if (!userRoleName || (userRoleName && userRoleName.trim() === '')) {
            toast.error('Please enter user role name', { autoClose: 2000 });
            return
        }
    }
    const menuList = [
        {
            mid: "1",
            m_name: "Master",
            m_smname: "Hospital",
            m_smid: "1"
        },
        {
            mid: "1",
            m_name: "Master",
            m_smname: "Referrer",
            m_smid: "2"
        },
        {
            mid: "1",
            m_name: "Master",
            m_smname: "Mitral Valve",
            m_smid: "3"
        },
        {
            mid: "1",
            m_name: "Master",
            m_smname: "Aortic Valve",
            m_smid: "4"
        },
        {
            mid: "1",
            m_name: "Master",
            m_smname: "Tricuspid valve",
            m_smid: "5"
        },
        {
            mid: "1",
            m_name: "Master",
            m_smname: "Pulmonary Valve",
            m_smid: "6"
        },
        {
            mid: "2",
            m_name: "Reports",
            m_smname: "Report list",
            m_smid: "14"
        },
        {
            mid: "2",
            m_name: "Reports",
            m_smname: "Report Approval",
            m_smid: "15"
        },
        {
            mid: "2",
            m_name: "Reports",
            m_smname: "Report Entry",
            m_smid: "16"
        },
        {
            mid: "3",
            m_name: "User Management",
            m_smname: "User List",
            m_smid: "17"
        },
        {
            mid: "3",
            m_name: "User Management",
            m_smname: "User Role",
            m_smid: "18"
        },
        {
            mid: "1",
            m_name: "Master",
            m_smname: "IAS & IVS Value",
            m_smid: "7"
        },
        {
            mid: "1",
            m_name: "Master",
            m_smname: "Left Atrium",
            m_smid: "8"
        },
        {
            mid: "1",
            m_name: "Masters",
            m_smname: "Right Vetricle",
            m_smid: "9"
        },
        {
            mid: "1",
            m_name: "Masters",
            m_smname: "Great Vessels",
            m_smid: "10"
        },
        {
            mid: "1",
            m_name: "Masters",
            m_smname: "Pericardium",
            m_smid: "11"
        },
        {
            mid: "1",
            m_name: "Masters",
            m_smname: "Left ventricle",
            m_smid: "12"
        }
        ,
        {
            mid: "1",
            m_name: "Masters",
            m_smname: "Impressions",
            m_smid: "13"
        }
    ]


    const [tableData, setTableData] = useState([])

    const handleCheckboxChange = (row, action) => {

        // console.log(row, menuList, 'handleCheckboxChange')
        const updatedSelectedCheckboxes = Object.assign([], tableData)
        updatedSelectedCheckboxes.forEach(ch => {
            if (ch.m_smid === row.m_smid) {
                if (action === 'view') {
                    ch.view = !ch.view
                    if (ch.view === false) {
                        ch.create = false
                        ch.modify = false
                        ch.remove = false
                        ch.special = false
                    }
                }
                if (action === 'create') {
                    ch.create = !ch.create
                }
                if (action === 'modify') {
                    ch.modify = !ch.modify
                }
                if (action === 'remove') {
                    ch.remove = !ch.remove
                }
                if (action === 'special') {
                    ch.special = !ch.special
                }
            }
        });
        setTableData(updatedSelectedCheckboxes)
        setSelectedCheckboxes(updatedSelectedCheckboxes)
        setChangeEvent(true)
    };
    const handleuserRoleNameChange = (e) => {
        setuserRoleName(e.target.value);
        // setChangeEvent(true)
    };
    const handleClose = () => {

        navigate("/user-role");
    }

    const handleMainMenuData = (menu) => {
        if (changeEvent) {
            toast.error('Please save this menu before leave', { autoClose: 2000 });
            return
        }
        setTableData(menu.subMenu)
        setmainMenu(menu)
        setSelectAll(false);
        if (menu && menu.mid) {
            setTableData(menuList.filter((e) => e.mid == menu.mid))
            // setLoading(true)
            // const reqData = {
            //     "user_id": Number(user.uid),
            //     // "urid": Number(userRoleId),
            //     "mid": Number(menu.mid)
            // }
            // dispatch(getUserRoleDetails(reqData));
        }

    }

    const handleSelectAllChange = () => {
        const allSelected = !selectAll;
        setSelectAll(allSelected);
        const updatedSelectedCheckboxes = Object.assign([], tableData)
        updatedSelectedCheckboxes.forEach(ch => {
            ch.view = allSelected
            ch.create = allSelected
            ch.modify = allSelected
            ch.remove = allSelected
            ch.special = allSelected
        });
        setTableData(updatedSelectedCheckboxes)
        setSelectedCheckboxes(updatedSelectedCheckboxes)
        setChangeEvent(true)
    };
    //meta title
    document.title = "User Management | User Role";

    return (
        <React.Fragment>
            <div className="page-content" style={{ height: '100%' }}>
                <div className="container-fluid">
                    <Breadcrumbs title="User Management" breadcrumbItem="User Role" />
                    <Row>
                        {/* {
                            isLoading ? <Spinners setLoading={setLoading} />
                                : */}
                        <Col xs="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xs="12" sm="12" lg="4" md="3">
                                            <FormGroup className="mb-3 d-flex align-items-center">
                                                <Label className="me-2 flex-shrink-0" style={{ marginTop: '10px' }}>User Role Name</Label>
                                                <Input
                                                    type="text"
                                                    placeholder="Enter user role name"
                                                    value={userRoleName}
                                                    onChange={handleuserRoleNameChange}
                                                    maxLength={constants.textboxLength.ur_name}
                                                    autoFocus
                                                />
                                            </FormGroup>
                                        </Col>
                                        {isEdit && (

                                            <Col sm="9">
                                                <div className="square-switch" style={{ float: 'right' }}>
                                                    <input
                                                        type="checkbox"
                                                        id="statusUserRole"
                                                        className="switch switch-bool"
                                                        checked={statusUserRole} // Reflect status state
                                                        onClick={toggleStatus} // Toggle status when switch changes
                                                    />
                                                    <label
                                                        htmlFor="statusUserRole"
                                                        data-on-label="active"
                                                        data-off-label="Inactive"
                                                    />
                                                </div>
                                            </Col>
                                        )}

                                    </Row>
                                    <Row className="mb-3">
                                        <div className="d-flex flex-wrap gap-2 justify-content-start">
                                            {menuList && menuList.length && menuList.map((m, i) => {
                                                return (
                                                    <div key={i + "_selected"} >
                                                        {/* {m && tableData && tableData[0] && m.mid === tableData[0].mid && (
                                                            <Button type="button"
                                                                style={{ color: '#fff', backgroundColor: '#556ee6', borderColor: 'transparent' }}
                                                                // style={m.mid === tableData[0].mid ? {} : ''}
                                                                onClick={() => {
                                                                    handleMainMenuData(m)
                                                                }}
                                                                className="btn btn-soft-primary waves-effect waves-light">{m.m_name}</Button>

                                                        )} */}

                                                        {/* // m && tableData && tableData[0] && m.mid !== tableData[0].mid && ( */}
                                                        {m && menuList && menuList[0] && m.mid !== menuList.mid && (
                                                            <Button type="button"
                                                                onClick={() => {
                                                                    handleMainMenuData(m)
                                                                }}
                                                                className="btn btn-soft-warning waves-effect waves-light">
                                                                <span className="userrolebtn">

                                                                    {m.m_name}
                                                                </span>

                                                            </Button>
                                                        )}

                                                        {/* <Button type="button" onClick={() => {
                                                            handleMainMenuData('qa')
                                                        }} className="btn btn-soft-primary waves-effect waves-light">Q&A</Button> */}

                                                        {/* <Button type="button" onClick={() => {
                                                            handleMainMenuData('blueprint')
                                                        }} className="btn btn-soft-primary waves-effect waves-light">Exam Blue PRint</Button> */}
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </Row>
                                    <Row className="mb-2">
                                        <div className="d-flex flex-wrap gap-1 align-items-center justify-content-start">
                                            <Input
                                                type="checkbox"
                                                id="selectAllCheckbox"
                                                checked={selectAll}
                                                onClick={handleSelectAllChange}
                                            />
                                            <Label className="mb-0 ms-2">All</Label>
                                        </div>

                                    </Row>
                                    <TableContainer
                                        columns={columns.map((column) => {
                                            if (column.accessorKey === "view") {
                                                // Add a custom cell for the checkbox column
                                                return {
                                                    ...column,
                                                    cell: (cellProps) => (
                                                        <FormGroup check className="font-size-16" key={cellProps.row.index + '_viewform'}>
                                                            <Label check>
                                                                <Input
                                                                    id={cellProps.row.index + "_view_checkbox"}
                                                                    name={cellProps.row.index + "_view_checkbox"}
                                                                    type="checkbox"
                                                                    checked={cellProps?.row?.original?.view ? true : false}
                                                                    onChange={() => handleCheckboxChange(cellProps.row.original, 'view')}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    ),
                                                };
                                            }
                                            if (column.accessorKey === "create") {
                                                // Add a custom cell for the checkbox column
                                                return {
                                                    ...column,
                                                    cell: (cellProps) => (
                                                        <FormGroup check className="font-size-16" key={cellProps.row.index + '_createform'}>
                                                            <Label check>
                                                                <Input
                                                                    id={cellProps.row.index + "_create_checkbox"}
                                                                    name={cellProps.row.index + "_create_checkbox"}
                                                                    type="checkbox"
                                                                    disabled={cellProps?.row?.original?.view ? false : true}
                                                                    checked={cellProps?.row?.original?.create ? true : false}
                                                                    onChange={() => handleCheckboxChange(cellProps.row.original, 'create')}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    ),
                                                };
                                            }
                                            if (column.accessorKey === "modify") {
                                                // Add a custom cell for the checkbox column
                                                return {
                                                    ...column,
                                                    cell: (cellProps) => (
                                                        <FormGroup check className="font-size-16" key={cellProps.row.index + '_modifyform'}>
                                                            <Label check>
                                                                <Input
                                                                    id={cellProps.row.index + "_modify_checkbox"}
                                                                    name={cellProps.row.index + "_modify_checkbox"}
                                                                    type="checkbox"
                                                                    disabled={cellProps?.row?.original?.view ? false : true}
                                                                    checked={cellProps?.row?.original?.modify ? true : false}
                                                                    onChange={() => handleCheckboxChange(cellProps.row.original, 'modify')}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    ),
                                                };
                                            }
                                            if (column.accessorKey === "remove") {
                                                // Add a custom cell for the checkbox column
                                                return {
                                                    ...column,
                                                    cell: (cellProps) => (
                                                        <FormGroup check className="font-size-16" key={cellProps.row.index + '_removeform'}>
                                                            <Label check>
                                                                <Input
                                                                    id={cellProps.row.index + "_remove_checkbox"}
                                                                    name={cellProps.row.index + "_remove_checkbox"}
                                                                    type="checkbox"
                                                                    disabled={cellProps?.row?.original?.view ? false : true}
                                                                    checked={cellProps?.row?.original?.remove ? true : false}
                                                                    onChange={() => handleCheckboxChange(cellProps.row.original, 'remove')}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    ),
                                                };
                                            }
                                            if (column.accessorKey === "special") {
                                                // Add a custom cell for the checkbox column
                                                return {
                                                    ...column,
                                                    cell: (cellProps) => (
                                                        <FormGroup check className="font-size-16" key={cellProps.row.index + '_specialform'}>
                                                            <Label check>
                                                                <Input
                                                                    id={cellProps.row.index + "_special_checkbox"}
                                                                    name={cellProps.row.index + "_special_checkbox"}
                                                                    type="checkbox"
                                                                    disabled={cellProps?.row?.original?.view ? false : true}
                                                                    checked={cellProps?.row?.original?.special ? true : false}
                                                                    onChange={() => handleCheckboxChange(cellProps.row.original, 'special')}
                                                                />
                                                            </Label>
                                                        </FormGroup>
                                                    ),
                                                };
                                            }
                                            return column;
                                        })}
                                        data={tableData || []}

                                        pageSize={tableData.length}

                                        pagination="pagination"

                                        theadClass="table-light"
                                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                    />

                                    <div className="text-end mb-4">

                                        <Button color="success"
                                            type="button"
                                            className="save-user"
                                            // disabled={!changeEvent}
                                            onClick={() => {
                                                saveUserRoleMenus()
                                            }}
                                        >
                                            {isEdit && !isClone ? "Update" : "Save"}
                                        </Button>
                                        <Link to={'/user-role'}>
                                            <button type="button" style={{ marginLeft: "10px" }}
                                                onClick={handleClose}
                                                className="btn btn-danger">Close</button>
                                        </Link>

                                    </div>
                                </CardBody>
                            </Card>

                        </Col>
                        {/* } */}
                    </Row>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </React.Fragment >
    );
}
Userrole.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};
export default Userrole;