import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState, useRef } from "react";
import {
    Container, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, Label, Input, Button, FormFeedback, UncontrolledTooltip,

} from "reactstrap";
import Select from "react-select";

import * as constants from "../../constants/layout"
import { useFormik } from "formik";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb3";
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import './patient.scss'
import DeleteModal from "../../components/Common/DeleteModal";
import * as api from "../../pages/Authentication/api/index"
import axios from "axios"
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Spinners from "../../components/Common/Spinner";

//i18n
import { withTranslation } from "react-i18next";
// const inputRef = useRef(null);
import Logout from "pages/Authentication/Logout";
const PatientList = props => {
    //meta title
    document.title = "Patient List | Care AKPS";

    // MasterData Hospital
    const hospital = [
        { id: 1, hospital_name: "AKPS" },
        { id: 2, hospital_name: "Kalidas Hospital" }
    ]
    const [genderMaster, setGenderMaster] = useState([
        { id: 1, g_name: "Male" },
        { id: 2, g_name: "Female" },
        { id: 3, g_name: "Transgender" }
    ])

    const [hospitalName, setHospitalName] = useState({ label: 'Select Hospital', value: 0 });

    const [deleteModal, setDeleteModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [selectedHospital, setselectedhospital] = useState(null);
    const [modal, setModal] = useState(false);
    const [canCreate, setCanCreate] = useState(false)
    const [patient, setPatient] = useState(null);
    const [selectedBoardName, setselectedBoardName] = useState(null);
    const [submitbtn, setSubmitbtn] = useState('')
    const [board, setBoard] = useState(null);
    const [statusPatientReg, setStatusPatientReg] = useState(true)
    const [status, setStaus] = useState(false)
    const [statusUserRole, setStatusUserRole] = useState(true)
    const [patientList, setPatientList] = useState([])
    const [hospitalMasterList, setHospitalMasterList] = useState([])
    const [statusFlag, setStatusFlag] = useState();
    const [patientData, setPatientData] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [deleteTrigger, setDeleteTrigger] = useState(false)
    const [patientId, setPatientId] = useState()
    const [address, setAddress] = useState("");
    const [defaultHosp, setDefaultHosp] = useState([]);

    const user = getLoggedInUser();
    const hrRef = useRef(null);
    var hpmaster;
    const radioOptions = [
        { value: 1, label: "Option 1" },
        { value: 2, label: "Option 2" },
        { value: 3, label: "Option 3" }
    ];

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            patientname: props.patientname || '',
            selectedHospital: '',
            uhid: '',
            age: '',
            gender: '',
            address: '',
            city: '',
            phnum: ''
        },
        validationSchema: Yup.object({
            patientname: Yup.string().required("Please enter patient name"),
            selectedHospital: Yup.object().required("Please select hospital"),
            uhid: Yup.string().required("Please enter UHID"),
            gender: Yup.string().required("Please select gender"),
        }),

        onSubmit: (values) => {
            setLoading(true);
            if (isEdit) { //update

                const gender = genderMaster.filter(e => e.g_name == values.gender)

                const newaddPatient = {
                    ptid: Number(patientData?.ptid),
                    user_id: Number(user.uid),
                    process_type: 'update',
                    pt_name: values.patientname,
                    pt_uhid: values.uhid,
                    pt_age: Number(values.age),
                    pt_gender: Number(gender[0].id),
                    pt_hospital_id: Number(values.selectedHospital.value),
                    pt_address: address || '',
                    pt_city: values.city || '',
                    pt_contact_number: Number(values.phnum),
                    pt_stsid: status == true ? 1 : 2
                };
                addPatient(newaddPatient)

            } else {
                const gender = genderMaster.filter(e => e.g_name == values.gender)
                const newaddPatient = {
                    user_id: Number(user.uid),
                    process_type: 'save',
                    pt_name: values.patientname,
                    pt_uhid: values.uhid,
                    pt_age: Number(values.age),
                    pt_gender: Number(gender[0].id),
                    pt_hospital_id: values.selectedHospital.value,
                    pt_address: address || '',
                    pt_city: values.city || '',
                    pt_contact_number: Number(values.phnum),
                    pt_stsid: 1
                };
                addPatient(newaddPatient)
            }
        }
    });

    // function capitalize(value) {
    //     return value.replace(/\b\w/g, (char) => char.toUpperCase());
    // }

    const textareachange = (event) => {
        setAddress(event.target.value);
    };

    const addPatient = async (req) => {
        try {
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.DEFAULTPATIENT + "/" + api.ENDPOINT.SAVEPATIENT, req, config);
            if (response) {

                setStatusFlag(response?.data?.body?.statusFlag)
                if (response && response?.data?.body?.statusFlag === 1) {
                    toggle()
                    validation.resetForm()
                    setAddress('')
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                    getPatientList()
                } else if (response && response?.data?.body?.statusFlag === 2) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                    setModal(true)
                } else if (response && response?.data?.body?.statusFlag === 4) {
                    toggle()
                    validation.resetForm()
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                    getPatientList()
                }
                else {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 })
                }
            }
        } catch (error) {
            toast.error("Patient registration Failed", { autoClose: 2000 });
        }
    }


    useEffect(() => {
        document.getElementById('headerTitle').innerHTML = "Patient Registration";
        getPatientList()
        getHospitalMasterList()
    }, []);

    const getPatientList = async () => {
        setLoading(true);

        const data = {
            user_id: Number(user.uid),
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.DEFAULTPATIENT + "/" + api.ENDPOINT.GETPATIENT, data, config);
            if (response && response.statusFlag === 3) {
                toast.error(response.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                window.location.reload();
                return;
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        Logout();
                    } else {
                        await getPatientList();
                    }
                }
            }

            if (response?.data?.body?.patientList) {
                setPatientList(response.data.body.patientList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("Patient List API Error:", error);
        }
    }
    const getHospitalMasterList = async () => {
        setLoading(true);

        const data = {
            user_id: Number(user.uid),
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.DEFAULTMASTER + "/" + api.ENDPOINT.HOSPITALMASTER, data, config);
            if (response && response.statusFlag === 3) {
                toast.error(response.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                window.location.reload();
                return;
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        Logout();
                    } else {
                        await getHospitalMasterList();
                    }
                }
            }

            if (response?.data?.body?.hospitalMasterList) {
                setHospitalMasterList(response?.data?.body?.hospitalMasterList);
                hpmaster = response?.data?.body?.hospitalMasterList;
                const data = hpmaster.filter(e => e.mv_default == 1)
                setDefaultHosp(data)

                setselectedhospital(data)
                validation.setFieldValue({
                    selectedHospital: {
                        label: data[0].mv_value,
                        value: data[0]?.mvid

                    },
                });
                setselectedhospital({
                    label: data[0].mv_value,
                    value: data[0]?.mvid
                }
                );
                const event = {
                    target: {
                        name: 'selectedHospital', value: {
                            label: data[0].mv_value,
                            value: data[0]?.mvid,
                        }
                    }
                };
                validation.handleChange(event);
            } else {
                toast.error(response?.data?.body?.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("Hospital Master List API Error:", error);
        }
    }



    const handleEditClick = (boardcategoryData) => {
        setModal(true)
        setIsEdit(true)
        setPatientData(boardcategoryData)
        validation.setFieldValue('patientname', boardcategoryData.pt_name)
        validation.setFieldValue('age', boardcategoryData.pt_age)
        validation.setFieldValue('uhid', boardcategoryData.pt_uhid)
        setAddress(boardcategoryData?.pt_address)
        validation.setFieldValue('city', boardcategoryData?.pt_city)
        validation.setFieldValue('phnum', boardcategoryData?.pt_contact_number)
        const gender = genderMaster.filter(e => e.id == boardcategoryData?.pt_gender)
        validation.setFieldValue('gender', gender[0].g_name)
        const hp1 = hpmaster.filter(e => Number(e.mvid) == Number(boardcategoryData?.pt_hospital_id))
        validation.setFieldValue({
            selectedHospital: {
                label: hp1[0].mv_value,
                value: boardcategoryData?.pt_hospital_id

            },
        });
        setselectedhospital({
            label: hp1[0].mv_value,
            value: boardcategoryData?.pt_hospital_id
        }
        );
        const event = {
            target: {
                name: 'selectedHospital', value: {
                    label: hp1[0].mv_value,
                    value: boardcategoryData?.pt_hospital_id,
                }
            }
        };
        validation.handleChange(event);
        if (boardcategoryData?.pt_stsid == 1) {
            setStaus(true)
        } else {
            setStaus(false)
        }
    };
    const onClickDelete = (boardcategoryData) => {
        setPatientId(boardcategoryData?.ptid);
        setDeleteModal(true);
    };

    const toggleStatus = () => {
        setStatusPatientReg(!statusUserRole);
        // setChangeEvent(true)
    };

    // Add click
    const handleAddClick = () => {
        validation.resetForm();
        setAddress('')
        setselectedhospital(null)
        if (defaultHosp?.length > 0) {
            setselectedhospital(defaultHosp)
            validation.setFieldValue({
                selectedHospital: {
                    label: defaultHosp[0].mv_value,
                    value: defaultHosp[0]?.mvid

                },
            });
            setselectedhospital({
                label: defaultHosp[0].mv_value,
                value: defaultHosp[0]?.mvid
            }
            );
            const event = {
                target: {
                    name: 'selectedHospital', value: {
                        label: defaultHosp[0].mv_value,
                        value: defaultHosp[0]?.mvid,
                    }
                }
            };
            validation.handleChange(event);
        }
        setIsEdit(false);
        toggle();

    }

    // Modal Open
    const toggle = () => {
        setModal(prev => !prev);
    };

    // Initial Load
    useEffect(() => {
        setCanCreate(true)
    }, [])

    // Columns Value
    const columns = useMemo(
        () => [
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        #
                    </div>
                ),
                accessorKey: 'SNo',
                enableColumnFilter: false,
                enableSorting: true,
                cell: ({ row, table }) => {


                    return (
                        <div className="text-center">


                            {(table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1}
                        </div>

                    );
                }
            },
            {
                header: (
                    <div className="text-left" style={{ cursor: 'pointer' }}>
                        Hospital ▼
                    </div>
                ),

                accessorKey: 'mv_value',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const office = cellProps.row.original.mv_value;


                    return (
                        <div className="text-left">

                            <Row>

                                <div className="align-items-left">{office}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-right" style={{ cursor: 'pointer' }}>
                        Patient Details ▼
                    </div>
                ),

                accessorKey: 'pt_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const patient = cellProps.row.original.pt_name;
                    const age = cellProps.row.original.pt_age;
                    const gender = cellProps.row.original.pt_gender;
                    const uhid = cellProps.row.original.pt_uhid;
                    return (
                        <div className="text-right">

                            <Row>

                                <div className="text-bold">{patient.toUpperCase()}</div>
                            </Row>
                            <Row>

                                <div>
                                    {age} | {gender} | UHID:{uhid}
                                </div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (

                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Status ▼
                    </div>
                ),

                accessorKey: 'pt_stsid',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    var pt_stsid = cellProps.row.original.pt_stsid;

                    return (
                        <div className="d-flex gap-3 justify-content-center" style={{ cursor: 'pointer' }}>
                            {pt_stsid == 1 ? (
                                <span style={{ width: 'auto' }} className="badge badge-soft-success rounded-pill  ms-1 font-size-12">

                                    <i className="text-success" style={{ fontSize: '14px' }} />
                                    {'Active'}</span>

                            ) : (
                                <span style={{ width: 'auto' }} className="badge badge-soft-danger rounded-pill  ms-1 font-size-12">

                                    <i className="text-danger" style={{ fontSize: '14px' }} />
                                    {'Inactive'}</span>
                            )}
                        </div>
                    );

                },
            },
            {
                header: (

                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Created By
                    </div>
                ),
                accessorKey: 'created_at',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const created_at = cellProps.row.original.created_at;

                    return (

                        // <div className="d-flex align-items-center">
                        <div className="text-center">
                            {created_at}

                        </div >
                    );
                },
            },
            {
                header: (

                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Updated By
                    </div>
                ),
                accessorKey: 'updated',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const updated = cellProps.row.original.updated;

                    return (

                        // <div className="d-flex align-items-center">
                        <div className="text-center">
                            {updated}

                        </div >
                    );
                },
            },
            {
                header: (

                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Actions
                    </div>
                ),
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3 justify-content-center" style={{ cursor: 'pointer' }}>

                            <div
                                to="#"
                                className="btn btn-sm btn-soft-info"
                                onClick={() => {
                                    const patientData = cellProps.row.original;
                                    handleEditClick(patientData);
                                }}
                            >
                                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </div>

                            <div
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    const patientData = cellProps.row.original; onClickDelete(patientData);
                                }}>
                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </div>
                        </div>
                    );
                }
            },
        ],
        []

    );

    const handleDeleteOrder = () => {
        setDeleteTrigger(true)
        setLoading(true)
        const result = {
            user_id: Number(user.uid), ptid: Number(patientId)
        }
        deletePatient(result)
    };

    const deletePatient = async (data) => {
        try {
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.DEFAULTPATIENT + "/" + api.ENDPOINT.DELETEPATIENT, data, config);
            if (response && response?.data?.body?.statusFlag === 1) {
                setDeleteModal(false)
                getPatientList()
                toast.success(response?.data?.body?.message, { autoClose: 2000 });
            } else {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
            }
        } catch (error) {
            toast.error("Patient Deleted Failed", { autoClose: 2000 });
        }
    }
    const handleSelectHospital = (selectedOption) => {
        setselectedhospital(selectedOption);
    };
    return (
        <React.Fragment >
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    {isLoading ?
                        <Spinners setLoading={setLoading} />
                        :

                        <TableContainer
                            columns={columns}
                            data={patientList || []}
                            isGlobalFilter={true}
                            isPagination={true}
                            isAddButton={canCreate}
                            buttonName="Add"
                            SearchPlaceholder="Search..."
                            pagination="pagination"
                            handleUserClick={handleAddClick}
                            buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        />
                    }
                    <Modal isOpen={modal} toggle={toggle} backdrop="static" className="modal1" >
                        <ModalHeader toggle={toggle} tag="h4">
                            {!!isEdit ? "Edit Patient" : "Add Patient"}
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault(); // Prevent default form submission
                                    validation.handleSubmit(); // Call Formik's handleSubmit
                                }}
                            >
                                <Row className="col-12">
                                    <Col className="col-6">
                                        <Row>
                                            <div className="mb-3 mt-3">
                                                <Label>Hospital<span style={{ color: 'red' }}>*</span></Label>
                                                <Select
                                                    ref={hrRef}
                                                    value={selectedHospital}
                                                    onChange={(selectedOption) => {
                                                        handleSelectHospital(selectedOption);
                                                        validation.setFieldValue("selectedHospital", selectedOption);
                                                    }}
                                                    options={hospitalMasterList.map((hp) => ({
                                                        label: hp.mv_value,
                                                        value: hp.mvid,
                                                    }))}
                                                    className={`select2-selection ${validation.touched.selectedHospital && validation.errors.selectedHospital ? 'is-invalid' : ''}`}
                                                    invalid={validation.touched.selectedHospital && validation.errors.selectedHospital}
                                                />
                                                {validation.touched.selectedHospital && validation.errors.selectedHospital ? (
                                                    <FormFeedback type="invalid">{validation.errors.selectedHospital}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="mb-3">
                                                <Label>UHID<span style={{ color: 'red' }}>*</span></Label>
                                                <Input
                                                    name="uhid"
                                                    type="text"
                                                    placeholder="UHID"
                                                    validate={{ required: { value: true } }}
                                                    // maxLength={constants.textboxLength.ex_name}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.uhid || ""}
                                                    invalid={validation.touched.uhid && validation.errors.uhid}
                                                // innerRef={inputRef}

                                                />
                                                {validation.touched.uhid && validation.errors.uhid ? (
                                                    <FormFeedback type="invalid">{validation.errors.uhid}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="mb-3">
                                                <Label>Patient Name<span style={{ color: 'red' }}>*</span></Label>
                                                <Input
                                                    name="patientname"
                                                    type="text"
                                                    placeholder="Patient Name"
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={constants.capitalize(validation.values.patientname)}
                                                    invalid={validation.touched.patientname && !!validation.errors.patientname}
                                                />
                                                {validation.touched.patientname && validation.errors.patientname ? (
                                                    <FormFeedback type="invalid">{validation.errors.patientname}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="mb-3">
                                                <Label>Age</Label>
                                                <Input
                                                    name="age"
                                                    type="text"
                                                    placeholder="Age"
                                                    validate={{ required: { value: true } }}
                                                    // maxLength={constants.textboxLength.ex_name}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.age || ""}
                                                    invalid={validation.touched.age && validation.errors.age}
                                                // innerRef={inputRef}

                                                />
                                                {validation.touched.age && validation.errors.age ? (
                                                    <FormFeedback type="invalid">{validation.errors.age}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <Row>
                                            <div>
                                                <Label className="d-block mb-3">Gender<span style={{ color: 'red' }}>*</span></Label>
                                                <div className="d-flex">
                                                    <div className="form-check form-check-inline mx-2">
                                                        <Input
                                                            type="radio"
                                                            id="customRadioInline1"
                                                            className="form-check-input"
                                                            name="gender"
                                                            value="Male"
                                                            checked={validation.values.gender === "Male"}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={validation.touched.gender && !!validation.errors.gender}
                                                        />
                                                        <label className="form-check-label" htmlFor="customRadioInline1">Male</label>
                                                        {validation.touched.gender && validation.errors.gender ? (
                                                            <FormFeedback>{validation.errors.gender}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="form-check form-check-inline mx-2">
                                                        <Input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="customRadioInline2"
                                                            name="gender"
                                                            value="Female"
                                                            checked={validation.values.gender === "Female"}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={validation.touched.gender && !!validation.errors.gender}
                                                        />
                                                        <label className="form-check-label" htmlFor="customRadioInline2">Female</label>
                                                    </div>
                                                    <div className="form-check form-check-inline mx-2">
                                                        <Input
                                                            type="radio"
                                                            className="form-check-input"
                                                            id="customRadioInline3"
                                                            name="gender"
                                                            value="Transgender"
                                                            checked={validation.values.gender === "Transgender"}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            invalid={validation.touched.gender && !!validation.errors.gender}
                                                        />
                                                        <label className="form-check-label" htmlFor="customRadioInline3">Transgender</label>

                                                    </div>
                                                </div>
                                            </div>
                                        </Row>


                                    </Col>
                                    <Col className="col-6">
                                        <Row>
                                            <div className="mb-3 mt-3">
                                                <Label>Address</Label>

                                                <Input
                                                    type="textarea"
                                                    maxLength="225"
                                                    rows="3"
                                                    placeholder="Address"

                                                    id="address"
                                                    name="address"
                                                    value={address}
                                                    onChange={e => {
                                                        textareachange(e)
                                                    }}
                                                    invalid={validation.touched.address && !!validation.errors.address} // Example boolean expression

                                                />
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="mb-3">
                                                <Label>City</Label>
                                                <Input
                                                    name="city"
                                                    type="text"
                                                    placeholder="City"
                                                    validate={{ required: { value: true } }}
                                                    // maxLength={constants.textboxLength.ex_name}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.city || ""}
                                                    invalid={validation.touched.city && validation.errors.city}
                                                // innerRef={inputRef}

                                                />
                                                {validation.touched.city && validation.errors.city ? (
                                                    <FormFeedback type="invalid">{validation.errors.city}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <Row>
                                            <div className="mb-3">
                                                <Label>Phone Number</Label>
                                                <Input
                                                    name="phnum"
                                                    type="text"
                                                    placeholder="Phone Number"
                                                    validate={{ required: { value: true } }}
                                                    // maxLength={constants.textboxLength.ex_name}
                                                    onChange={validation.handleChange}
                                                    onBlur={validation.handleBlur}
                                                    value={validation.values.phnum || ""}
                                                    invalid={validation.touched.phnum && validation.errors.phnum}
                                                // innerRef={inputRef}

                                                />
                                                {validation.touched.phnum && validation.errors.phnum ? (
                                                    <FormFeedback type="invalid">{validation.errors.phnum}</FormFeedback>
                                                ) : null}
                                            </div>
                                        </Row>
                                        <Row>
                                            {isEdit && (
                                                <div className="mb-3">
                                                    <Label>Status</Label>
                                                    <Col lg="2">
                                                        <div className="square-switch">
                                                            <input
                                                                type="checkbox"
                                                                id="statusMark"
                                                                className="switch switch-bool"
                                                                checked={status}
                                                                onClick={() => setStaus(!status)}

                                                            />
                                                            <label
                                                                htmlFor="statusMark"
                                                                data-on-label="active"
                                                                data-off-label="Inactive"
                                                            />
                                                        </div>
                                                    </Col>
                                                </div>)}
                                        </Row>
                                    </Col>
                                    {/* <Col>
                                    </Col> */}
                                </Row>


                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button
                                                color="success"
                                                type="submit"
                                                className="save-user"
                                                onClick={() => setSubmitbtn('savebtn')}
                                            // disabled={isButtonDisabled}

                                            >
                                                {!!isEdit ? "Update" : "Save"}
                                            </Button>
                                            {"" && (
                                                <Button
                                                    color="primary"
                                                    type="submit"
                                                    className="save-and-continue-user"
                                                    onClick={() => setSubmitbtn('savecontinue')}
                                                    style={{ marginLeft: '10px' }}
                                                    disabled={isButtonDisabled}

                                                >
                                                    Save and Continue
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>
                </Container>
            </div >
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </React.Fragment >
    );
};

PatientList.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(PatientList);
