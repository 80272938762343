import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
    Container, Modal, ModalHeader, ModalBody, Form, Row, Col, Label, Input, Button, FormFeedback, UncontrolledTooltip
} from "reactstrap";
import Select from "react-select";

import { useFormik } from "formik";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"

import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import * as constants from "../../constants/layout"
import DeleteModal from "../../components/Common/DeleteModal";
import * as api from "../../pages/Authentication/api/index"
import axios from "axios"
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Spinners from "../../components/Common/Spinner";
//i18n
import { withTranslation } from "react-i18next";
// const inputRef = useRef(null);
import Logout from "pages/Authentication/Logout";
import { useNavigate } from "react-router-dom";
const UserList = props => {
    //meta title
    document.title = "UserList | Care AKPS";
    const regex = /^[a-zA-Z0-9]*$/;

    const navigate = useNavigate()

    const [isEdit, setIsEdit] = useState(false);
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [canCreate, setCanCreate] = useState(false);
    const [newpasswordShow, setNewPasswordShow] = useState(false);
    const [confmpasswordShow, setConfmPasswordShow] = useState(false);
    const [confmpasswordShow1, setConfmPasswordShow1] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [newpassword, setnewpassword] = useState('')
    const [newpasswordError, SetnewpasswordError] = useState(false)
    const [userconfirmpassword, setuserconfirmpassword] = useState('')
    const [conformpassword, setconformpassword] = useState(false)
    const [userRole, setUserRole] = useState(null);
    const [userroleName, setuserroleName] = useState(null);
    const [hospital, setHospital] = useState(null);
    const password_validation = constants.errorMessages.PASSWORD_VALIDATION
    const [submitbtn, setSubmitbtn] = useState('')
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteTrigger, setDeleteTrigger] = useState(false)
    const [isLoading, setLoading] = useState(false);
    const user = getLoggedInUser();
    const [userList, setUserList] = useState([])
    const [masterList, setMasterList] = useState([])
    const [hospitalList, setHospitalList] = useState([])
    const [isEditing, setIsEditing] = useState(false);
    const [resetpasswordError, setresetpasswordError] = useState('')
    const [conformpasswordError, SetconformpasswordError] = useState('')
    const [resetTrigger, setResetTrigger] = useState(false);
    const [boardUid, setBoardUid] = useState(null);
    const [statusFlag, setStatusFlag] = useState();
    const [status, setStatus] = useState(true);
    const [userData, setUserData] = useState([]);
    const selectedUrid = userroleName ? userroleName.value : null;
    var masterData1;

    // Initial load
    useEffect(() => {
        getMasterList()
        getUserList()
        document.getElementById('headerTitle').innerHTML = 'User';

    }, []);

    useEffect(() => {

        if ((user?.user_previleges.filter(e => Number(e.u_urid) == Number(user?.u_urid) && Number(e.urp_mid) == 2
            && Number(e.urp_smid) == 15
        )).length > 0) {
            console.log('-----')
        } else {
            localStorage.removeItem("authUser");  
            navigate('/login')
        }


    }, [user])


    useEffect(() => {
        console.log('master', masterList)
    }, [masterList])


    const getMasterList = async () => {
        setLoading(true)

        const data = {
            user_id: Number(user.uid),
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.DEFAULTMASTER + "/" + api.ENDPOINT.MASTER, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");  
                     navigate('/login')
                 }, 2000);
 
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");  
                        navigate('/login')
                    } else {
                        await getMasterList();
                    }
                }
            }

            if (response?.data?.body?.userRoleList) {
                const data = response?.data?.body?.userRoleList;
                const filtered_master = data?.filter(e => e.ur_default != 1)
                setMasterList(filtered_master);
                setHospitalList(response?.data?.body?.hospitalList);
                masterData1 = response?.data?.body?.userRoleList;
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("Master List API Error:", error);
        }
    }

    const getUserList = async () => {

        const data = {
            user_id: Number(user.uid),
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.MASTER + "/" + api.ENDPOINT.USERLIST, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");  
                     navigate('/login')
                 }, 2000);
 
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");  
                        navigate('/login')
                    } else {
                        await getUserList();
                    }
                }
            }

            if (response?.data?.body?.userList) {
                setUserList(response.data.body.userList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("student List API Error:", error);
        }
    }


    // Add click
    const handleAddClick = () => {
        validation.resetForm()
        setuserroleName(null)
        setIsEdit(false);
        toggle();

    }
    const handleEditClick = (userData) => {

        setUserData(userData)
        console.log('userData', userData)
        const hp1 = masterData1.filter(e => Number(e.urid) == Number(userData?.u_urid))

        validation.setFieldValue('userdisplayName', userData?.u_name)
        validation.setFieldValue('loginID', userData?.u_loginid)

        validation.setFieldValue({
            userroleName: {
                label: hp1[0].ur_name,
                value: Number(userData?.u_urid)

            },
        });
        setuserroleName({
            label: hp1[0].ur_name,
            value: Number(userData?.u_urid),
        }
        );
        setHospital({
            label: userData?.hospital_name,
            value: Number(userData?.u_hospital_id),
        })
        const event = {
            target: {
                name: 'userroleName', value: {
                    label: hp1[0].ur_name,
                    value: Number(userData?.u_urid),
                }
            }
        };
        validation.handleChange(event);


        if (userData.u_stsid === '1') { //active status
            setStatus(true)
        } else {
            setStatus(false)
        }

        setModal(true)
        setIsEdit(true)
    };

    // Modal Open
    const toggle = () => {
        setModal(prev => !prev);
    };
    const toggleStatus = () => {
        setStatus(!status);
    };

    // Initial Load
    useEffect(() => {
        setCanCreate(true)
    }, [])


    const handleResetPasswordClick = () => {
        // setIsEdit(true)
        if (newpassword === '') {
            SetnewpasswordError(true)
            setresetpasswordError(constants.errorMessages.NEWPASSWORD_ERROR)
        } else if (!password_validation.test(newpassword)) {
            SetnewpasswordError(true)
            setresetpasswordError(constants.errorMessages.PASSWORD_VALIDATIONERROR)

        } else {
            SetnewpasswordError(false)
            setresetpasswordError('')
        }

        if (userconfirmpassword === '') {
            setconformpassword(true)
            SetconformpasswordError(constants.errorMessages.CONFORMPASSWORD_ERROR)
        } else if (!password_validation.test(userconfirmpassword)) {
            setconformpassword(true)
            SetconformpasswordError(constants.errorMessages.PASSWORD_VALIDATIONERROR)
        } else if (newpassword !== userconfirmpassword) {
            setconformpassword(true)
            SetconformpasswordError(constants.errorMessages.EQUALCONFORMPASSWORD_ERROR)
        } else {
            setconformpassword(false)
            SetconformpasswordError('')
        }
        if (newpassword !== '' && userconfirmpassword !== '' && newpassword === userconfirmpassword && password_validation.test(newpassword)) {
            setResetTrigger(true);
            // setIsEdit(true)
            const data = { user_id: Number(user.uid), reset_user_id: Number(boardUid), new_password: newpassword }
            resetPassword(data)
        }

    };

    const resetPassword = async (req) => {
        try {
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.MASTER + "/" + api.ENDPOINT.RESETPASSWORD, req, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");  
                     navigate('/login')
                 }, 2000);
 
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");  
                        navigate('/login')
                    } else {
                        await resetPassword(req);
                    }
                }
            }

            if (response && response?.data && response?.data?.body && response?.data?.body?.statusFlag == 1) {
                toast.success(response?.data?.body?.message, { autoClose: 2000 });
                setModal1(false)
                validation.resetForm()
                getUserList()
            } else if (response && response?.data?.body?.statusFlag === 2) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
            } else {
                toast.error(response?.data?.body?.message, { autoClose: 2000 })
            }
        } catch (error) {
            // console.log('error in add user list', error)
            toast.error("User Added Failed", { autoClose: 2000 });
        }

    }


    const handleDeleteOrder = () => {
        setDeleteTrigger(true)
        setLoading(true)
        const data = { user_id: Number(user.uid), u_uid: Number(userRole) }
        // const result = {
        //     u_uid: Number(userroleName.uid)
        // }
        deleteUserMaster(data)
    };
    const deleteUserMaster = async (data) => {
        try {
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.MASTER + "/" + api.ENDPOINT.DELETEUSER, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");  
                     navigate('/login')
                 }, 2000);
 
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");  
                        navigate('/login')
                    } else {
                        await deleteUserMaster(data);
                    }
                }
            }

            if (response && response?.data?.body?.statusFlag === 1) {
                setDeleteModal(false)
                getUserList()
                toast.success(response.message, { autoClose: 2000 });
            } else {
                setDeleteModal(false)
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
            }
        } catch (error) {
            toast.error("User Deleted Failed", { autoClose: 2000 });
        }
    }

    const onClickreset = (userData) => {
        setIsEdit(true)
        SetnewpasswordError(false)
        setresetpasswordError('')
        setconformpassword(false)
        SetconformpasswordError('')
        setBoardUid('');
        setuserconfirmpassword('')
        setnewpassword('')
        togglePasswordReset(userData.uid);

    };
    const togglePasswordReset = (uid) => {
        setModal1(!modal1);
        SetnewpasswordError(false)
        setresetpasswordError('')
        setconformpassword(false)
        SetconformpasswordError('')
        setBoardUid(uid);

    };
    const handleSelectuserrolename = (selectedOption) => {
        setuserroleName(selectedOption);
    };

    const onClickDelete = (userData) => {

        setUserRole(userData?.uid);
        setDeleteModal(true);
    };
    const addUserList = async (req) => {
        try {
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.MASTER + "/" + api.ENDPOINT.SAVEUSER, req, config);
            console.log('res', response)
            if (response) {

                if (response && response?.data?.body?.statusFlag === 3) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                    setTimeout(() => {
                        localStorage.removeItem("authUser");  
                         navigate('/login')
                     }, 2000);
     
                }

                if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                    const refresh = await getRefreshToken();

                    if (refresh) {
                        if (Number(refresh) === constants.invalidRefreshToken) {
                            localStorage.removeItem("authUser");  
                            navigate('/login')
                        } else {
                            await addUserList(req);
                        }
                    }
                }

                setStatusFlag(response?.data?.body?.statusFlag)
                if (response && response?.data?.body?.statusFlag === 1) {
                    toggle()
                    validation.resetForm()
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                    getUserList()
                } else if (response && response?.data?.body?.statusFlag === 2) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                    setModal(true)
                } else if (response && response?.data?.body?.statusFlag === 4) {
                    toggle()
                    validation.resetForm()
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                    getUserList()
                }
                else {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 })
                }
            }
        } catch (error) {
            console.log('error in add user list', error)
            toast.error("User has not been saved", { autoClose: 2000 });
        }
    }
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            userdisplayName: (userRole && userRole.userdisplayName) || '',
            loginID: (userRole && userRole.loginID) || '',
            userroleName: ''
        },
        validationSchema: Yup.object({
            userdisplayName: Yup.string().required("Please enter user name").matches(/^[a-zA-Z. ]+$/, 'User name can only contain letters.'),
            loginID: Yup.string().required("Please enter login id").matches(/^[a-zA-Z0-9]+$/, 'Login ID can only contain letters and numbers.'),
            userroleName: Yup.object().required("Please select user role"),
            password: Yup.string().matches(password_validation, constants.errorMessages.PASSWORD_VALIDATIONERROR),
            confirmPassword: Yup.string().matches(password_validation, constants.errorMessages.PASSWORD_VALIDATIONERROR)
        }),
        onSubmit: (values, { }) => {
            console.log('hosp', hospital)

            if (isEdit) { //update
                const newaddusermaster = {
                    user_id: Number(user.uid),
                    u_name: values.userdisplayName,
                    process_type: 'update',
                    u_loginid: values.loginID,
                    u_urid: Number(values?.userroleName?.value),
                    u_uid: userData.uid,
                    u_status: status === true ? 1 : 2,
                    u_hospital_id: (hospital?.value == undefined || isNaN(hospital?.value)) ? 0 : hospital?.value
                };
                console.log('newaddusermaster', newaddusermaster);
                addUserList(newaddusermaster)
                // disableButton()
                // update new Exam Board
                // dispatch(addusermaster(newaddusermaster));

            } else { //add
                // Manual validation for password and confirmPassword
                if (!values.password) {
                    // Set errors and prevent form submission
                    validation.setFieldError('password', 'Password is required');
                    validation.setFieldError('confirmPassword', 'Confirm Password is required');
                    setLoading(false);
                    return;
                }

                // Check if passwords match
                // if (values.password !== values.confirmPassword) {
                //     validation.setFieldError('confirmPassword', 'Passwords must match');
                //     setLoading(false);
                //     return;
                // }
                const newaddusermaster = {
                    user_id: Number(user.uid),
                    u_name: values.userdisplayName,
                    process_type: 'save',
                    u_loginid: values.loginID,
                    u_password: values.password,
                    u_urid: values?.userroleName?.value,
                    u_hospital_id: hospital?.value == undefined ? 0 : hospital?.value
                };
                console.log('newadd', newaddusermaster)
                addUserList(newaddusermaster)

                // save new Exam Board
                // disableButton()
                // dispatch(addusermaster(newaddusermaster));

            }

        },
    });

    // useEffect(() => {
    //     console.log('stattus', statusFlag)
    //     if (statusFlag) {
    //         if (statusFlag == 1) {

    //             toggle();
    //             validation.resetForm()
    //         } else if (statusFlag == 2) {
    //             toast.error("User Already exists", { position: toast.POSITION.TOP_RIGHT });

    //         } else if (statusFlag == 4) {

    //             toggle();
    //             validation.resetForm()
    //         }
    //     }
    // }, [statusFlag])

    // Columns Value
    const columns = useMemo(
        () => [
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        #
                    </div>
                ),
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) => {


                    return (
                        <div className="text-center">


                            {(table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1}
                        </div>

                    );
                }
            },
            {
                header: (

                    <div className="text-left" style={{ cursor: 'pointer' }}>
                        User Name ▼
                    </div>
                ),
                accessorKey: 'u_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const u_name = cellProps.row.original.u_name;

                    return (

                        // <div className="d-flex align-items-center">
                        <div className="text-left">
                            {constants.fullCaps(u_name)}

                        </div >
                    );
                },
            },
            {
                header: (

                    <div className="text-left" style={{ cursor: 'pointer' }}>
                        User Role ▼
                    </div>
                ),
                accessorKey: 'ur_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const ur_name = cellProps.row.original.ur_name;

                    return (

                        // <div className="d-flex align-items-center">
                        <div className="text-left">
                            {ur_name}

                        </div >
                    );
                },
            },
            {
                header: (

                    <div className="text-left" style={{ cursor: 'pointer' }}>
                        Login ID ▼
                    </div>
                ),
                accessorKey: 'u_loginid',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const u_loginid = cellProps.row.original.u_loginid;

                    return (

                        // <div className="d-flex align-items-center">
                        <div className="text-left">
                            {u_loginid}


                        </div >
                    );
                },
            },
            {
                header: (

                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Status ▼
                    </div>
                ),
                accessorKey: 'mv_status',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const status = cellProps.row.original.u_stsid;

                    return (
                        <>
                            {Number(status) === 1 && (
                                <div className="text-center">
                                    <span className="badge badge-soft-success rounded-pill ms-1 font-size-12">
                                        <i style={{ fontSize: '16px' }} />
                                        Active
                                    </span>
                                </div>
                            )}
                            {Number(status) === 2 && (
                                <div className="text-center">
                                    <span className="badge badge-soft-danger rounded-pill ms-1 font-size-12">
                                        <i style={{ fontSize: '16px' }} />
                                        Inactive
                                    </span>
                                </div>
                            )}
                        </>
                    );
                },
            },
            {
                header: (

                    <div className="text-left" style={{ cursor: 'pointer' }}>
                        Created By
                    </div>
                ),
                accessorKey: 'u_created',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const u_created = cellProps.row.original.u_created;

                    return (

                        // <div className="d-flex align-items-center">
                        <div className="text-left">
                            {u_created}

                        </div >
                    );
                },
            },
            {
                header: (

                    <div className="text-left" style={{ cursor: 'pointer' }}>
                        Updated By
                    </div>
                ),

                accessorKey: 'u_updated',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const u_updated = cellProps.row.original.u_updated;

                    return (

                        // <div className="d-flex align-items-center">
                        <div className="text-left">
                            {u_updated}

                        </div >
                    );
                },

            },
            {
                header: (

                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Actions ▼
                    </div>
                ),
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const id = cellProps.row.original.uid;
                    const status = cellProps.row.original.u_stsid;

                    return (
                        <div className="d-flex gap-3" style={{ cursor: 'pointer' }}>
                            <div className="btn btn-sm btn-soft-info pointer" onClick={() => handleEditClick(cellProps.row.original)}>
                                <i className="mdi mdi-pencil-outline pointer" id={"edittooltip" + id} />
                                <UncontrolledTooltip placement="top" target={"edittooltip" + id}>
                                    Edit
                                </UncontrolledTooltip>
                            </div>

                            <div className="btn btn-sm btn-soft-danger pointer" onClick={() => onClickDelete(cellProps.row.original)}>
                                <i className="mdi mdi-delete-outline pointer" id={"deletetooltip" + id} />
                                <UncontrolledTooltip placement="top" target={"deletetooltip" + id}>
                                    Delete
                                </UncontrolledTooltip>
                            </div>

                            {Number(status) === 1 &&
                                <div className="btn btn-sm btn-soft-info pointer" onClick={() => onClickreset(cellProps.row.original)}>
                                    <i className="mdi mdi-lock-reset pointer" id={"resettooltip" + id} />
                                    <UncontrolledTooltip placement="top" target={"resettooltip" + id}>
                                        Reset Password
                                    </UncontrolledTooltip>
                                </div>
                            }
                        </div>
                    );
                }
            },
        ],
        []

    );

    useEffect(() => {
        if (submitbtn) {
            if (submitbtn !== 'savecontinue') {

            }
            // inputRef.current.focus();
            // setInputFocus(true)
            setSubmitbtn('')

        }
        if (resetTrigger) {
            setResetTrigger(false);
            setNewPassword('')
            validation.resetForm();
            setModal1(false);
            // togglePasswordReset();   
            SetnewpasswordError(false)
            setresetpasswordError('')
            setconformpassword(false)
            SetconformpasswordError('')
            // setBoardUid('');
            setuserconfirmpassword('')
            setnewpassword('')
        }
    }, [submitbtn])
    const resetForm = () => {

        setModal(false)
        validation.setTouched('labelname', false)
        validation.values.labelname = '';

        validation.values.bold = false;
        // setHospitalName("")
        // setLabel('')
        setStatus(true)

        validation.setFieldValue('userdisplayName', "")
        validation.setFieldValue('loginID', "")

        validation.setFieldValue({
            userroleName: {
                label: "",
                value: ""

            },
        });
        setuserroleName({
            label: "",
            value: "",
        }
        );
        setHospital({
            label: "",
            value: "",
        })
        const event = {
            target: {
                name: 'userroleName', value: {
                    label: "",
                    value: "",
                }
            }
        };
        validation.handleChange(event);

        setModal(false)
    }
    return (
        <React.Fragment >
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    {isLoading ?
                        <Spinners setLoading={setLoading} />
                        :


                        <TableContainer
                            columns={columns}
                            data={userList || []}
                            isGlobalFilter={true}
                            isPagination={true}
                            isAddButton={canCreate}
                            buttonName="Add"
                            SearchPlaceholder="Search..."
                            pagination="pagination"
                            handleUserClick={handleAddClick}
                            buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                            tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                            theadClass="table-light"
                            paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        />
                    }
                    <Modal isOpen={modal} toggle={toggle} backdrop="static">
                        <ModalHeader toggle={toggle} tag="h4">
                            {isEdit ? "Edit User" : "Add User"}
                        </ModalHeader>
                        <ModalBody>
                            <Form onSubmit={validation.handleSubmit}>
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label>Name of the User<span style={{ color: 'red' }}>*</span></Label>
                                            <Input
                                                name="userdisplayName"
                                                className="upper"
                                                type="text"
                                                maxLength={constants.textboxLength.u_name}
                                                autoComplete="off"
                                                placeholder="User Name"
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.userdisplayName || ""}
                                                invalid={validation.touched.userdisplayName && validation.errors.userdisplayName}
                                            />
                                            {validation.touched.userdisplayName && validation.errors.userdisplayName ? (
                                                <FormFeedback type="invalid">{validation.errors.userdisplayName}</FormFeedback>
                                            ) : null}
                                        </div>
                                        <div className="mb-3">
                                            <Label>User Role <span style={{ color: 'red' }}>*</span></Label>
                                            <Select
                                                value={userroleName}
                                                onChange={(selectedOption) => {

                                                    validation.setFieldValue("userroleName", selectedOption);
                                                    handleSelectuserrolename(selectedOption);
                                                }}
                                                options={masterList.map((ur) => ({
                                                    label: ur.ur_name,
                                                    value: ur.urid,
                                                }))}
                                                // ref={inputRef}
                                                validate={{
                                                    required: { value: true },
                                                }}

                                                className={`select2-selection ${validation.touched.userroleName && validation.errors.userroleName ? 'is-invalid' : ''}`}
                                            />
                                            {validation.touched.userroleName && validation.errors.userroleName ? (
                                                <div className="invalid-feedback">{validation.errors.userroleName}</div>
                                            ) : null}

                                        </div>
                                        {userroleName?.value == 5 ?
                                            <div className="mb-3">
                                                <Label>Hospital <span style={{ color: 'red' }}>*</span></Label>
                                                <Select
                                                    value={hospital}
                                                    onChange={(selectedOption) => {

                                                        validation.setFieldValue("hospital", selectedOption);
                                                        setHospital(selectedOption);
                                                    }}
                                                    options={hospitalList.map((mv) => ({
                                                        label: mv.mv_value,
                                                        value: mv.mvid
                                                    }))}
                                                    // ref={inputRef}
                                                    validate={{
                                                        required: { value: true },
                                                    }}

                                                    className={`select2-selection ${validation.touched.userroleName && validation.errors.userroleName ? 'is-invalid' : ''}`}
                                                />
                                                {validation.touched.userroleName && validation.errors.userroleName ? (
                                                    <div className="invalid-feedback">{validation.errors.userroleName}</div>
                                                ) : null}

                                            </div>
                                            : null}


                                        <div className="mb-3">
                                            <Label>Login ID <span style={{ color: 'red' }}>*</span></Label>
                                            <Input
                                                name="loginID"
                                                type="text"
                                                placeholder="Login ID"
                                                maxLength={constants.textboxLength.u_loginid}
                                                autoComplete="off"
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                disabled={isEdit}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.loginID}
                                                invalid={validation.touched.loginID && validation.errors.loginID}
                                            />
                                            {validation.touched.loginID && validation.errors.loginID ? (
                                                <FormFeedback type="invalid">{validation.errors.loginID}</FormFeedback>
                                            ) : null}
                                        </div>
                                        {!isEdit && (
                                            <div>
                                                <div className="mb-3">
                                                    <Label>Password <span style={{ color: 'red' }}>*</span></Label>
                                                    <div className="input-group auth-pass-inputgroup">

                                                        <Input
                                                            name="password"
                                                            type={passwordShow ? "text" : "password"}
                                                            maxLength={constants.textboxLength.u_password}
                                                            autoComplete="off"
                                                            placeholder="Password"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            invalid={validation.touched.password && validation.errors.password}
                                                        />
                                                        {passwordShow == false && <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                                            <i className="mdi mdi-eye-outline"></i>
                                                        </button>}
                                                        {passwordShow == true && <button onClick={() => setPasswordShow(!passwordShow)} className="btn btn-light " type="button" id="password-addon">
                                                            <i className="mdi mdi-eye-off-outline"></i>
                                                        </button>}
                                                        {validation.touched.password && validation.errors.password ? (
                                                            <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {isEdit && (
                                            <div className="mb-3">
                                                <Label>Status</Label>
                                                <Col lg="2">
                                                    <div className="square-switch">
                                                        <input
                                                            type="checkbox"
                                                            id="statusMark"
                                                            className="switch switch-bool"
                                                            defaultChecked={status} // Reflect status state
                                                            onClick={toggleStatus} // Toggle status when switch changes
                                                        />
                                                        <label
                                                            htmlFor="statusMark"
                                                            data-on-label="active"
                                                            data-off-label="Inactive"
                                                        />
                                                    </div>
                                                </Col>
                                            </div>)}

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button color="success" type="submit"
                                                disabled={isButtonDisabled}

                                                onClick={() => {
                                                    setSubmitbtn('save')
                                                }}>
                                                {isEdit ? "Update" : "Save"}
                                            </Button>
                                            {!isEdit && (
                                                // <Button color="primary" type="submit" className="save-and-continue-user"
                                                //     onClick={() => {
                                                //         setSubmitbtn('savecontinue')
                                                //     }}
                                                //     disabled={isButtonDisabled}

                                                //     style={{ marginLeft: '10px' }}>
                                                //     Save and Continue
                                                // </Button>    
                                                <Button
                                                    color="secondary"
                                                    className="save-and-continue-user"
                                                    onClick={() => resetForm()}
                                                    style={{ marginLeft: '10px' }}
                                                // disabled={isButtonDisabled}

                                                >
                                                    Close
                                                </Button>
                                            )}
                                        </div>
                                    </Col>
                                </Row>


                            </Form>
                        </ModalBody>
                    </Modal>
                    <Modal isOpen={modal1} toggle={togglePasswordReset} backdrop="static">
                        <ModalHeader toggle={togglePasswordReset} tag="h4">
                            Reset Password
                        </ModalHeader>
                        <ModalBody>
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleResetPasswordClick();
                                }}
                            >
                                <Row>
                                    <Col className="col-12">
                                        <div className="mb-3">
                                            <Label>New Password</Label>
                                            <div className="input-group auth-pass-inputgroup">

                                                <Input
                                                    // innerRef={passwordInputRef}
                                                    name="newPassword"
                                                    // type="password"
                                                    type={newpasswordShow ? "text" : "password"}
                                                    placeholder="New Password"
                                                    autoComplete="off"
                                                    value={newpassword}
                                                    // onBlur={onBlurnewpassword} 
                                                    onChange={(e) => setnewpassword(e.target.value)}
                                                />
                                                {newpasswordShow == false && <button onClick={() => setNewPasswordShow(!newpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                                                    <i className="mdi mdi-eye-outline"></i>
                                                </button>}
                                                {newpasswordShow == true && <button onClick={() => setNewPasswordShow(!newpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                                                    <span className="mdi mdi-eye-off-outline"></span>
                                                </button>}
                                                {/* <button onClick={() => setNewPasswordShow(!newpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                                                    <i className="mdi mdi-eye-outline"></i>
                                                </button> */}
                                            </div>
                                            <span style={{ color: 'red', fontSize: '10px' }}> {newpasswordError === true ? resetpasswordError : ''} </span>

                                        </div>

                                        <div className="mb-3">
                                            <Label>Confirm Password</Label>
                                            <div className="input-group auth-pass-inputgroup">

                                                <Input
                                                    name="confirmPassword"
                                                    // type="password"
                                                    type={confmpasswordShow ? "text" : "password"}
                                                    autoComplete="off"
                                                    placeholder="Confirm Password"
                                                    value={userconfirmpassword}
                                                    // onBlur={onBlurconforpassword} 
                                                    onChange={(e) => setuserconfirmpassword(e.target.value)}
                                                />
                                                {/* <button onClick={() => setConfmPasswordShow(!confmpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                                                    <i className="mdi mdi-eye-outline"></i>
                                                </button> */}
                                                {confmpasswordShow == false && <button onClick={() => setConfmPasswordShow(!confmpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                                                    <i className="mdi mdi-eye-outline"></i>
                                                </button>}
                                                {confmpasswordShow == true && <button onClick={() => setConfmPasswordShow(!confmpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                                                    <span className="mdi mdi-eye-off-outline"></span>
                                                </button>}
                                            </div>
                                            <span style={{ color: 'red', fontSize: '10px' }}> {conformpassword === true ? conformpasswordError : ''} </span>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-end">
                                            <Button color="success" disabled={isButtonDisabled}
                                                type="submit" className="reset-password">
                                                Save
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </ModalBody>
                    </Modal>

                </Container>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </React.Fragment >
    );
};

UserList.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(UserList);
