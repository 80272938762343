import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState, useRef } from "react";
import {
    Container, Modal, ModalHeader, ModalBody, ModalFooter, Form, Row, Col, Label, Input, Button, FormFeedback, UncontrolledTooltip,
    Card, CardBody
} from "reactstrap";
import Select from "react-select";

import * as constants from "../../constants/layout"
import { useFormik } from "formik";
import Spinners from "../../components/Common/Spinner";
import { ToastContainer } from "react-toastify";

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb3";
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";

//i18n
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// const inputRef = useRef(null);
import DeleteModal from "../../components/Common/DeleteModal";

const UserList = props => {
    //meta title
    document.title = "UserList | Care AKPS";

    // MasterData Hospital
    const hospital = [
        { id: 1, hospital_name: "AKPS" },
        { id: 2, hospital_name: "Kalidas Hospital" }
    ]
    const userRole = [
        { id: 1, role_name: "Admin" },
        { id: 2, role_name: "Super Admin" }
    ]


    const [isEdit, setIsEdit] = useState(false);
    const [selectedHospital, setselectedhospital] = useState(null);
    const [modal, setModal] = useState(false);
    const [canCreate, setCanCreate] = useState(false);
    const [newpasswordShow, setNewPasswordShow] = useState(false);
    const [confmpasswordShow, setConfmPasswordShow] = useState(false);
    const [confmpasswordShow1, setConfmPasswordShow1] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate()
    const data = [
        {
            SNo: 1,
            userrole: "Director",
            u_count: 28,
            office: "Singapore",
            startDate: "2010/11/14",
            salary: "$357,650"
        },
        {
            SNo: 2,
            userrole: "Developer",
            u_count: 42,
            office: "Edinburgh",
            startDate: "2010/12/22",
            salary: "$92,575"
        },
        {
            SNo: 3,
            userrole: "Chief Executive Officer (CEO)",
            u_count: 47,
            office: "London",
            startDate: "2009/10/09",
            salary: "$1,200,000"
        },
        {
            SNo: 4,
            userrole: "Sales Assistant",
            u_count: 23,
            office: "Sidney",
            startDate: "2010/09/20",
            salary: "$85,600"
        },
        {
            SNo: 5,
            userrole: "Pre-Sales Support",
            u_count: 21,
            office: "New York",
            startDate: "2011/12/12",
            salary: "$106,450"
        },
        {
            SNo: 6,
            userrole: "Chief Marketing Officer (CMO)",
            u_count: 40,
            office: "New York",
            startDate: "2009/06/25",
            salary: "$675,000"
        },
        {
            SNo: 7,
            userrole: "Development Lead",
            u_count: 30,
            office: "New York",
            startDate: "2011/09/03",
            salary: "$345,000"
        },
        {
            SNo: 8,
            userrole: "Personnel Lead",
            u_count: 35,
            office: "Edinburgh",
            startDate: "2012/09/26",
            salary: "$217,500"
        },
        {
            SNo: 9,
            userrole: "Software Engineer",
            u_count: 41,
            office: "London",
            startDate: "2012/10/13",
            salary: "$132,000"
        },
        {
            SNo: 10,
            userrole: "Systems Administrator",
            u_count: 59,
            office: "New York",
            startDate: "2009/04/10",
            salary: "$237,500"
        },

    ];

    // Add click

    const handleAddClick = () => {
        // dispatch(resetUserRoleStore({statusFlag: 0, userRoleDetails: []}));
        navigate('/add-userrole', { state: { urid: '' } })
    };
    const handleEditClick = (userData) => {
        console.log('user', userData)
        setModal(true)
        setIsEdit(true)
    };
    const handleDeleteUserRole = () => {
        setDeleteTrigger(true)
        // setLoading(true)
        // dispatch(deleteUserrole({user_id: Number(user.uid), urid: Number(userRole.urid)}))
        // dispatch(deleteUserrole({ user_id: Number(user.uid), urid: Number(userRole.urid) }))
    };
    // Modal Open
    const toggle = () => {
        setModal(prev => !prev);
    };
    const toggleStatus = () => {
        setStatus(!status);
    };

    // Initial Load
    useEffect(() => {
        setCanCreate(true)
    }, [])

    const columns = useMemo(
        () => [
            {
                header: 'Actions',
                // accessorKey: 'action',
                // enableColumnFilter: false,
                // enableSorting: true,

                width: '8%',
                // width: (cell) => cell.value.length * 10, 
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            {/* {user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.UserRole && f.urp_type === constants.menuTypes.Create) && ( */}
                            <Button
                                to="#"
                                className="btn btn-sm btn-soft-warning"
                                onClick={() => {
                                    const orderData = cellProps.row.original;
                                    handleCloneClick(orderData);
                                }}
                            >
                                <i className="mdi mdi-content-duplicate" id="clonetooltip" />
                                <UncontrolledTooltip placement="top" target="clonetooltip">
                                    Clone
                                </UncontrolledTooltip>
                            </Button>
                            {/* )} */}
                            {/* {user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.UserRole && f.urp_type === constants.menuTypes.Modify) && ( */}
                            <Button
                                to="#"
                                className="btn btn-sm btn-soft-info"
                                onClick={() => {
                                    const orderData = cellProps.row.original;
                                    handleEditClick(orderData);
                                }}
                            >
                                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Button>
                            {/* )} */}
                            {/* {user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.UserRole && f.urp_type === constants.menuTypes.Remove) && ( */}
                            <div
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    const orderData = cellProps.row.original; onClickDelete(orderData);
                                }}>
                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </div>
                            {/* )} */}

                        </div>
                    );
                }
            },
            {
                header: 'S.No.',
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1

            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        User Role ▼
                    </span>
                ),

                accessorKey: 'userrole',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        No.of Users ▼
                    </span>
                ),

                accessorKey: 'u_count',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'txt-center'
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Status ▼
                    </span>
                ),

                accessorKey: 'ur_stsid',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const ur_stsid = cellProps.row.original.ur_stsid;

                    return (
                        <div className="d-flex align-items-center">
                            {ur_stsid === 1 ? (
                                <i className="mdi mdi-check-circle text-success me-2" style={{ fontSize: '25px' }} />
                            ) : (
                                <i className="dripicons dripicons-wrong text-danger me-2" style={{ fontSize: '20px' }} />
                            )}
                        </div>
                    );
                },
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Created By ▼
                    </span>
                ),

                accessorKey: 'ur_created',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Updated By ▼
                    </span>
                ),

                accessorKey: 'updated',
                // enableColumnFilter: false,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const mk_stsid = cellProps.row.original.updated;

                    return (
                        <div className="d-flex align-items-center">
                            {mk_stsid ? (
                                <span>{mk_stsid}</span>

                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    );
                },
            }

        ],
        []
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            boardName: "board" || ''
        },
        validationSchema: Yup.object({
            boardName: Yup.string().required("Please Enter Board Name")
        }),
    })
    const handleSelectHospital = (selectedOption) => {
        setselectedhospital(selectedOption);
    };
    return (
        <React.Fragment>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteUserRole}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content" style={{ height: '100%' }}>
                <div className="container-fluid">
                    <Breadcrumbs
                        breadcrumbItem={props.t("UserRole")}
                    />
                    {/* <Breadcrumbs title="Masters" breadcrumbItem="User Role" /> */}
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={data || []}
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                isAddButton={canCreate}
                                                buttonName="Add"
                                                SearchPlaceholder="Search..."
                                                pagination="pagination"
                                                handleUserClick={handleAddClick}
                                                // isCustomPageSize={true}
                                                //  paginationWrapper='dataTables_paginate paging_simple_numbers'

                                                buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                theadClass="table-light"

                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
};

UserList.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(UserList);
