import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
} from "reactstrap";
import axios from "axios";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import * as api from "../../pages/Authentication/api/index"
//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from "reselect";
import withRouter from "components/Common/withRouter";

// import avatar from "../../assets/images/users/avatar-1.jpg";
import avatar from "../../assets/images/Young Boy.jpg";
// actions
import { resetProfileFlag } from "../../store/actions";
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import 'react-toastify/dist/ReactToastify.css';
import * as constants from "../../constants/layout"
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
const UserProfile = () => {

  //meta title
  document.title = "Profile | Care AKPS";
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const [oldpasswordShow, setOldPasswordShow] = useState(false);
  const [newpasswordShow, setNewPasswordShow] = useState(false);
  const [confmpasswordShow, setConfmPasswordShow] = useState(false);
  const password_validation = constants.errorMessages.PASSWORD_VALIDATION
  const [newpasswordError, SetnewpasswordError] = useState(false)
  const [conformpassword, setconformpassword] = useState(false)
  const [resetpasswordError, setresetpasswordError] = useState('')
  const [conformpasswordError, SetconformpasswordError] = useState('')
  const [email, setemail] = useState("");
  const [uname, setUname] = useState("");
  const [name, setname] = useState("");
  const [urname, setUrname] = useState("");
  const [idx, setidx] = useState(1);
  const user = getLoggedInUser();


  const ProfileProperties = createSelector(
    (state) => state.Profile,
    (profile) => ({
      error: profile.error,
      success: profile.success,
    })
  );

  const {
    error,
    success
  } = useSelector(ProfileProperties);
  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Change Password';
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      setUname(obj.u_name)
      setUrname(obj.ur_name)
    }

  }, [])
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        setname(obj.displayName);
        setemail(obj.email);
        setidx(obj.uid);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        setname(obj.username);
        setemail(obj.email);
        setidx(obj.uid);
      }
      setTimeout(() => {
        dispatch(resetProfileFlag());
      }, 3000);
    }
  }, [dispatch, success]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      oldpassword: '',
      newpassword: '',
      // confmpassword: '',
    },
    validationSchema: Yup.object({
      oldpassword: Yup.string().required("Please Enter Your oldPassword"),
      newpassword: Yup.string().required("Please Enter Your New Password"),
      // confmpassword: Yup.string().required("Please Enter Your Confirm Password"),
    }),
    onSubmit: (values) => {
      const data = {
        user_id: Number(user.uid),
        old_password: values.oldpassword,
        new_password: values.newpassword
      }
      if (values.newpassword === '') {
        SetnewpasswordError(true)
        setresetpasswordError(constants.errorMessages.NEWPASSWORD_ERROR)
      } else if (!password_validation.test(values.newpassword)) {
        SetnewpasswordError(true)
        setresetpasswordError(constants.errorMessages.PASSWORD_VALIDATIONERROR)

      } else {
        SetnewpasswordError(false)
        setresetpasswordError('')
      }

      // if (values.confmpassword === '') {
      //   setconformpassword(true)
      //   SetconformpasswordError(constants.errorMessages.CONFORMPASSWORD_ERROR)
      // } else if (!password_validation.test(values.confmpassword)) {
      //   setconformpassword(true)
      //   SetconformpasswordError(constants.errorMessages.PASSWORD_VALIDATIONERROR)
      // } else if (values.newpassword !== values.confmpassword) {
      //   setconformpassword(true)
      //   SetconformpasswordError(constants.errorMessages.EQUALCONFORMPASSWORD_ERROR)
      // } else {
      //   setconformpassword(false)
      //   SetconformpasswordError('')
      // }
      console.log('newpassword', values.newpassword)
      if (values?.newpassword !== '' && values?.oldpassword !== '') {
        // setResetTrigger(true);
        // setIsEdit(true)
        // const data = { user_id: Number(user.uid), reset_user_id: Number(boardUid), new_password: newpassword }
        // resetPassword(data)
        changePassword(data)
      }
      // dispatch(editProfile(values));
    }
  });

  const changePassword = async (values) => {
    try {
      const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
      const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.MASTER + "/" + api.ENDPOINT.CHANGEPASSWORD, values, config);

      if (response && response?.data?.body?.statusFlag === 1) {
        toast.success(response?.data?.body?.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        navigate('/');

      } else {
        toast.error(response.message, { autoClose: 2000 });
      }
    } catch (error) {
      toast.error("User Deleted Failed", { autoClose: 2000 });
    }
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12">
              {error && error ? <Alert color="danger">{error}</Alert> : null}
              {success ? <Alert color="success">{success}</Alert> : null}

              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="ms-3">
                      <img
                        src={avatar}
                        alt=""
                        className="avatar-md rounded-circle img-thumbnail"
                      />
                    </div>
                    <div className="flex-grow-1 align-self-center">
                      <div className="text-muted">
                        <h5>{constants.fullCaps(name)}</h5>
                        <p className="mb-1 text-black"> {constants.fullCaps(uname)}</p>
                        <p className="mb-0 text-black"> {constants.fullCaps(urname)}</p>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <h4 className="card-title mb-4">Change User Password</h4>
          <Row>
            <Col lg={12}>
              <Col lg={5}>

                <Card>
                  <CardBody>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="form-group">

                        <Label className="form-label">Old Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="oldpassword"
                            value={validation.values.oldpassword || ""}
                            type={oldpasswordShow ? "text" : "password"}
                            placeholder="Enter Password"
                            autoComplete="off"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.oldpassword && validation.errors.oldpassword ? true : false
                            }
                          />
                          {oldpasswordShow == false && <button onClick={() => setOldPasswordShow(!oldpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                            <i className="mdi mdi-eye-outline"></i>
                          </button>}
                          {oldpasswordShow == true && <button onClick={() => setOldPasswordShow(!oldpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                            <span className="mdi mdi-eye-off-outline"></span>
                          </button>}
                          {/* <button onClick={() => setOldPasswordShow(!oldpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                            <i className="mdi mdi-eye-outline"></i>
                          </button> */}
                          {validation.touched.oldpassword && validation.errors.oldpassword ? (
                            <FormFeedback type="invalid">{validation.errors.oldpassword}</FormFeedback>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group mt-3">




                        <Label className="form-label">New Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="newpassword"
                            value={validation.values.newpassword || ""}
                            type={newpasswordShow ? "text" : "password"}
                            placeholder="Enter Password"
                            autoComplete="off"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.newpassword && validation.errors.newpassword ? true : false
                            }
                          />
                          {newpasswordShow == false && <button onClick={() => setNewPasswordShow(!newpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                            <i className="mdi mdi-eye-outline"></i>
                          </button>}
                          {newpasswordShow == true && <button onClick={() => setNewPasswordShow(!newpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                            <span className="mdi mdi-eye-off-outline"></span>
                          </button>}
                          {/* <button onClick={() => setNewPasswordShow(!newpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                            <i className="mdi mdi-eye-outline"></i>
                          </button> */}
                          {validation.touched.newpassword && validation.errors.newpassword ? (
                            <FormFeedback type="invalid">{validation.errors.newpassword}</FormFeedback>
                          ) : null}
                        </div>

                      </div>
                      {/* <div className="form-group mt-3">

                        <Label className="form-label">Confirm New Password</Label>
                        <div className="input-group auth-pass-inputgroup">
                          <Input
                            name="confmpassword"
                            value={validation.values.confmpassword || ""}
                            type={confmpasswordShow ? "text" : "password"}
                            placeholder="Enter Password"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.confmpassword && validation.errors.confmpassword ? true : false
                            }
                          />
                          <button onClick={() => setConfmPasswordShow(!confmpasswordShow)} className="btn btn-light " type="button" id="password-addon">
                            <i className="mdi mdi-eye-outline"></i>
                          </button>
                          {validation.touched.confmpassword && validation.errors.confmpassword ? (
                            <FormFeedback type="invalid">{validation.errors.confmpassword}</FormFeedback>
                          ) : null}
                        </div>

                      </div> */}
                      <div className="text-end mt-4">
                        <Button type="submit" color="success">
                          Update
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                </Card>


              </Col>
              <Col lg={7}>
              </Col>
            </Col>
          </Row>

        </Container>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
