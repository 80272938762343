import React, { useState, useEffect, useRef } from "react";
import { withTranslation } from "react-i18next";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";
import ".././../assets/css/custom.css";
import * as api from "../../pages/Authentication/api/index"
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios"
import {
    Container
} from "reactstrap";
import ".././../assets/css/custom.css";
import { NavItem, NavLink, TabContent, TabPane, Nav } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import classnames from "classnames"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Select from "react-select";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import * as constants from '../../../src/constants/layout'
import Logout from "pages/Authentication/Logout";
const TemplateCreation = () => {

    const navigate = useNavigate()


    const user = getLoggedInUser();

    const { rpt_tid } = useParams()

    const mitralValueIframeRef = useRef(null)
    const leftAtriumIframeRef = useRef(null)
    const aorticValueIframeRef = useRef(null)
    const rightAtriumIframeRef = useRef(null)
    const tricuspidValueIframeRef = useRef(null)
    const rightVentricleValueIframeRef = useRef(null)
    const pulmonaryValueIframeRef = useRef(null)
    const greatvesselsValueIframeRef = useRef(null)
    const iasValueIframeRef = useRef(null)
    const pericardiumValueIframeRef = useRef(null)
    const leftventricleValueIframeRef = useRef(null)
    const impressionValueIframeRef = useRef(null)
    const [tab1Flag, setTab1Flag] = useState(false);
    const [tab2Flag, setTab2Flag] = useState(false);
    const [tab3Flag, setTab3Flag] = useState(false);
    const [tab4Flag, setTab4Flag] = useState(false);
    const mitralEditorTab2Ref = useRef(null);
    const aorticEditorTab2Ref = useRef(null);
    const tricuspidEditorTab2Ref = useRef(null);
    const pulmonaryEditorTab2Ref = useRef(null);


    const [isEdit, setIsEdit] = useState(false);
    const [statusFlag, setStatusFlag] = useState();
    const [isLoading, setLoading] = useState(false)

    const [a, seta] = useState('');
    const [e, sete] = useState('');
    const [ivs, setIvs] = useState('');
    const [lvpw, setLvpw] = useState('');
    const [lvef, setLvef] = useState('');
    const [tal1, setTab1] = useState(false);
    const [tab2, setTab2] = useState(false);
    const [tab3, setTab3] = useState(false);
    const [tab4, setTab4] = useState(false);
    const [tab5, setTab5] = useState(false);
    const [systole, setSystole] = useState('');
    const [aorticroot, setAorticRoot] = useState('');
    const [leftatrium, setLeftAtrium] = useState('');
    const [leftDiastole, setLeftDiastole] = useState('');
    const [langugeTab, changeLangugeTab] = useState('13');
    const [rightDiastole, setRightDiastole] = useState('');
    const [templateName, settemplateName] = useState('');
    const [aorticTab2, setAorticTab2] = useState('');
    const [tricuspid, setTricuspid] = useState('');
    const [pulmonary, setPulmonary] = useState('');
    const [editorData, setEditorData] = useState('');
    const [ea, setEA] = useState('E/A');
    const [aorticEditorTab2, setAorticEditorTab2] = useState('');
    const [tricuspidEditorTab2, setTricuspidEditorTab2] = useState('');
    const [pulmonaryEditorTab2, setPulmonaryEditorTab2] = useState('');

    const [mitralEditorTab2, setMitralEditorTab2] = useState('');

    const [editLoadTemplate, setEditLoadTemplate] = useState([]);
    const [editLoadImpression, setEditLoadImpression] = useState([]);
    const [iasselectedOptions, setIasSelectedOptions] = useState([]);
    const [editLoadDopplerData, setEditLoadDopplerData] = useState([]);
    const [greatselectedOptions, setGreatSelectedOptions] = useState([]);
    const [mitralselectedOptions, setMitralSelectedOptions] = useState([]);
    const [editLoadLeftVentricle, setEditLoadLeftVentricle] = useState([]);
    const [leftAtriumselectedOptions, setLeftAtriumSelectedOptions] = useState([]);
    const [percardiumselectedOptions, setPercardiumSelectedOptions] = useState([]);
    const [impressionselectedOptions, setImpressionSelectedOptions] = useState([]);
    const [aorticValueselectedOptions, setAorticValuesSelectedOptions] = useState([]);
    const [rightAtriumselectedOptions, setRightAtriumSelectedOptions] = useState([]);
    const [tricuspidValueselectedOptions, setTricuspidValueSelectedOptions] = useState([]);
    const [rightVentricalselectedOptions, setRightVentricalSelectedOptions] = useState([]);
    const [pulmonaryValueselectedOptions, setPulmonaryValueSelectedOptions] = useState([]);
    const [leftVentricalselectedOptions, setLeftVentricalSelectedOptions] = useState([]);

    const [mitralOptions, setMitralOptions] = useState([]);
    const [leftAtriumOptions, setLeftAtriumOptions] = useState([]);
    const [aorticValueOptions, setAorticValuesOptions] = useState([]);
    const [rightAtriumOptions, setRightAtriumOptions] = useState([]);
    const [tricuspidValueOptions, setTricuspidValueOptions] = useState([]);
    const [rightVentricalOptions, setRightVentricalOptions] = useState([]);
    const [pulmonaryValueOptions, setPulmonaryValueOptions] = useState([]);
    const [greatOptions, setGreatOptions] = useState([]);
    const [iasOptions, setIasOptions] = useState([]);
    const [percardiumOptions, setPercardiumOptions] = useState([]);
    const [leftVentricalOptions, setLeftVentricalOptions] = useState([]);
    const [impressionOptions, setImpressionOptions] = useState([]);

    const [tapHideFlag, setTapHideFlag] = useState(false);

    const [impressionValue, setImpressionValue] = useState('');
    const [leftVentricalValue, setLeftVentricalValue] = useState('');
    const [percardiumValue, setPercardiumValue] = useState('');
    const [iasValue, setIasValue] = useState('');
    const [greatVesselsValue, setGreatVesselsValue] = useState('');
    const [pulmonaryValue, setPulmonaryValue] = useState('');
    const [rightVentricalValue, setRightVentricalValue] = useState('');
    const [tricuspidValue, setTricuspidValue] = useState('');
    const [rightAtriumValue, setRightAtriumValue] = useState('');
    const [aorticValue, setAorticValue] = useState('');
    const [leftAtriumValue, setLeftAtriumValue] = useState('');
    const [mitralValue, setMitralValue] = useState('');

    // const [mitralEditorTab2, setMitralEditorTab2] = useState('');
    // const [aorticEditorTab2, setAorticEditorTab2] = useState('');
    // const [tricuspidEditorTab2, setTricuspidEditorTab2] = useState('');
    // const [pulmonaryEditorTab2, setPulmonaryEditorTab2] = useState('');

    // Initial Load
    useEffect(() => {
        document.getElementById('headerTitle').innerHTML = 'Create Template';
        bindMasterDropdown()
    }, []);
    async function bindMasterDropdown() {


        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.DEFAULTMASTER + "/" + api.ENDPOINT.GETMASTERS, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");  
                     navigate('/login')
                 }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");  
                     navigate('/login')
                    } else {
                        await bindMasterDropdown();
                    }
                }
            }

            if (response?.data?.body?.MasterList) {


                setMitralOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 3))
                setLeftAtriumOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 8))
                setAorticValuesOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 4))
                setRightAtriumOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 14))
                setTricuspidValueOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 5))
                setRightVentricalOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 9))
                setPulmonaryValueOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 6))
                setGreatOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 10))
                setIasOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 7))
                setPercardiumOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 11))
                setLeftVentricalOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 12))
                setImpressionOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 13))
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error(" master List API Error:", error);
        }

    }

    // EditLoadApi Call
    useEffect(() => {
        if (rpt_tid != "0" && rpt_tid != null && rpt_tid != undefined) editLoad(rpt_tid)

    }, [rpt_tid])

    const handlechangeEa = (event) => {
        seta(Number(event?.target?.value))
        setEA(Number(Number(e) / Number(event?.target?.value)))
    }

    // Edit Load API
    const editLoad = async (rpt_tid) => {
        setLoading(true)

        var data = {
            user_id: Number(user.uid),
            rpt_tid: Number(rpt_tid),
        }

        const config = {
            headers: { authorization: "Bearer ".concat(user.accessToken) },
        }
        const response1 = await axios.post(process.env.REACT_APP_BASE_URL + api.API.DEFAULTREPORT + "/" + api.ENDPOINT.EDITLOADTEMPLATE, data, config);

        if (response1 && response1?.data?.body?.statusFlag === 3) {
            toast.error(response1?.data?.body?.message, { autoClose: 2000 });
            setTimeout(() => {
                localStorage.removeItem("authUser");  
                 navigate('/login')
             }, 2000);
        }

        if (
            response1?.data?.body?.status === "401" ||
            response1?.data?.body?.status === 401
        ) {
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    localStorage.removeItem("authUser");  
                 navigate('/login')
                } else {
                    await editLoad(rpt_tid) // calling same function again getting refresh token
                }
            }
        }
        if (
            response1 &&
            response1?.data &&
            response1?.data?.body &&
            response1?.data?.body?.templateData?.length > 0
        ) {
            setIsEdit(true)
            const data = response1?.data?.body?.templateData[0]
            setEA(data?.rpt_t_doopler_data?.e_divide_a_value)
            //tab3 editor values 

            console.log(data?.rpt_t_color_doopler_data?.mitralvalue_editor_value);

            settemplateName(data?.rpt_t_name)
            setMitralValue(data?.rpt_t_color_doopler_data?.mitralvalue_editor_value != undefined ? data?.rpt_t_color_doopler_data?.mitralvalue_editor_value : "")
            setLeftAtriumValue(data?.rpt_t_color_doopler_data?.leftatrium_editor_value != undefined ? data?.rpt_t_color_doopler_data?.leftatrium_editor_value : "")
            setAorticValue(data?.rpt_t_color_doopler_data?.aorticvalue_editor_value != undefined ? data?.rpt_t_color_doopler_data?.aorticvalue_editor_value : "")
            setRightAtriumValue(data?.rpt_t_color_doopler_data?.rightatrium_editor_value != undefined ? data?.rpt_t_color_doopler_data?.rightatrium_editor_value : "")
            setTricuspidValue(data?.rpt_t_color_doopler_data?.tricuspidvalue_editor_value != undefined ? data?.rpt_t_color_doopler_data?.tricuspidvalue_editor_value : "")
            setRightVentricalValue(data?.rpt_t_color_doopler_data?.rightventical_editor_value != undefined ? data?.rpt_t_color_doopler_data?.rightventical_editor_value : "")
            setPulmonaryValue(data?.rpt_t_color_doopler_data?.pulmonaryvalue_editor_value != undefined ? data?.rpt_t_color_doopler_data?.pulmonaryvalue_editor_value : "")
            setGreatVesselsValue(data?.rpt_t_color_doopler_data?.greatvessels_editor_value != undefined ? data?.rpt_t_color_doopler_data?.greatvessels_editor_value : "")
            setIasValue(data?.rpt_t_color_doopler_data?.ias_editor_value != undefined ? data?.rpt_t_color_doopler_data?.ias_editor_value : "")
            setPercardiumValue(data?.rpt_t_color_doopler_data?.percardium_editor_value != undefined ? data?.rpt_t_color_doopler_data?.percardium_editor_value : "")

            //tab4 editor values

            setLeftVentricalValue(data?.rpt_t_left_ventricle?.leftventrical_editor_value != undefined ? data?.rpt_t_left_ventricle?.leftventrical_editor_value : "")
            setImpressionValue(data?.rpt_t_impression?.impression_editor_value != undefined ? data?.rpt_t_impression?.impression_editor_value : "")
        }
    }

    // Save And Update Call
    const saveReport = () => {
        if (isEdit) {
            const req = {
                user_id: Number(user.uid),
                rpt_t_name: templateName,
                rpt_t_status: 1,
                rpt_tid: Number(rpt_tid),
                process_type: "update",
                rpt_t_echodiagram_data: {
                    aorticroot_value: Number(validation.values.aorticroot) || '',
                    leftatrium_value: Number(validation.values.leftatrium) || '',
                    left_ventrical_diastole: Number(validation.values.leftdiastole) || '',
                    systole_value: Number(validation.values.systole) || '',
                    ivs_value: Number(validation.values.ivs) || '',
                    lvpw_value: Number(validation.values.lvpw) || '',
                    lvef_value: Number(validation.values.lvef) || '',
                    right_ventrical_diastole: Number(validation.values.rightdiastole) || ''
                },
                rpt_t_doopler_data: {
                    a_value: Number(tab2validation.values.a) || '',
                    e_value: Number(tab2validation.values.e) || '',
                    e_divide_a_value: Number(e / a) || '',
                    aortic: Number(tab2validation.values.aorticTab2) || '',
                    tricuspid: Number(tab2validation.values.tricuspid) || '',
                    pulmonary: Number(tab2validation.values.pulmonary) || '',
                    mitral_editor_value: mitralEditorTab2 || '',
                    aortic_editor_value: aorticEditorTab2 || '',
                    tricuspid_editor_value: tricuspidEditorTab2 || '',
                    pulmonary_editor_value: pulmonaryEditorTab2 || ''
                },
                rpt_t_color_doopler_data: {
                    mitralvalue_editor_value: mitralValue || '',
                    leftatrium_editor_value: leftAtriumValue || '',
                    aorticvalue_editor_value: aorticValue || '',
                    rightatrium_editor_value: rightAtriumValue || '',
                    tricuspidvalue_editor_value: tricuspidValue || '',
                    rightventical_editor_value: rightVentricalValue || '',
                    pulmonaryvalue_editor_value: pulmonaryValue || '',
                    greatvessels_editor_value: greatVesselsValue || '',
                    ias_editor_value: iasValue || '',
                    percardium_editor_value: percardiumValue || ''
                },
                rpt_t_left_ventricle: {
                    leftventrical_editor_value: leftVentricalValue || ''
                },
                rpt_t_impression: {
                    impression_editor_value: impressionValue || ''
                },
            }
            createTemplate(req)

        } else {

            const req = {
                user_id: Number(user.uid),
                rpt_t_name: templateName,
                rpt_t_status: 1,
                rpt_tid: Number(0),
                process_type: "save",
                rpt_t_echodiagram_data: {
                    aorticroot_value: Number(validation.values.aorticroot) || '',
                    leftatrium_value: Number(validation.values.leftatrium) || '',
                    left_ventrical_diastole: Number(validation.values.leftdiastole) || '',
                    systole_value: Number(validation.values.systole) || '',
                    ivs_value: Number(validation.values.ivs) || '',
                    lvpw_value: Number(validation.values.lvpw) || '',
                    lvef_value: Number(validation.values.lvef) || '',
                    right_ventrical_diastole: Number(validation.values.rightdiastole) || ''
                },
                rpt_t_doopler_data: {
                    a_value: Number(tab2validation.values.a) || '',
                    e_value: Number(tab2validation.values.e) || '',
                    e_divide_a_value: Number(e / a) || '',
                    aortic: Number(tab2validation.values.aorticTab2) || '',
                    tricuspid: Number(tab2validation.values.tricuspid) || '',
                    pulmonary: Number(tab2validation.values.pulmonary) || '',
                    mitral_editor_value: mitralEditorTab2 || '',
                    aortic_editor_value: aorticEditorTab2 || '',
                    tricuspid_editor_value: tricuspidEditorTab2 || '',
                    pulmonary_editor_value: pulmonaryEditorTab2 || ''
                },
                rpt_t_color_doopler_data: {
                    mitralvalue_editor_value: mitralValue || '',
                    leftatrium_editor_value: leftAtriumValue || '',
                    aorticvalue_editor_value: aorticValue || '',
                    rightatrium_editor_value: rightAtriumValue || '',
                    tricuspidvalue_editor_value: tricuspidValue || '',
                    rightventical_editor_value: rightVentricalValue || '',
                    pulmonaryvalue_editor_value: pulmonaryValue || '',
                    greatvessels_editor_value: greatVesselsValue || '',
                    ias_editor_value: iasValue || '',
                    percardium_editor_value: percardiumValue || ''
                },
                rpt_t_left_ventricle: {
                    leftventrical_editor_value: leftVentricalValue || ''
                },
                rpt_t_impression: {
                    impression_editor_value: impressionValue || ''
                },
            }
            createTemplate(req)

        }

    }

    // API Save Template
    const createTemplate = async (req) => {
        try {
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.DEFAULTREPORT + "/" + api.ENDPOINT.SAVETEMPLATE, req, config);
            if (response) {

                if (response && response?.data?.body?.statusFlag === 3) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                    setTimeout(() => {
                        localStorage.removeItem("authUser");  
                         navigate('/login')
                     }, 2000);
                }

                if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                    const refresh = await getRefreshToken();

                    if (refresh) {
                        if (Number(refresh) === constants.invalidRefreshToken) {
                            localStorage.removeItem("authUser");  
                            navigate('/login')
                        } else {
                            await createTemplate(req);
                        }
                    }
                }

                setStatusFlag(response?.data?.body?.statusFlag)
                if (response && response?.data?.body?.statusFlag === 1) {
                    // navigate('/report-template')
                    toast.success(response?.data?.body?.message, { autoClose: 2000 }); setTimeout(() => navigate('/report-template'), 700);
                } else if (response && response?.data?.body?.statusFlag === 2) {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 });
                } else if (response && response?.data?.body?.statusFlag === 4) {
                    toggle()
                    toast.success(response?.data?.body?.message, { autoClose: 2000 });
                }
                else {
                    toast.error(response?.data?.body?.message, { autoClose: 2000 })
                }
            }
        } catch (error) {
            console.log('er', error)
            toast.error("Template creation Failed", { autoClose: 2000 });
        }
    }

    const modules = {
        toolbar: [
            [{ bold: true }, { italic: true }, { underline: true }],
        ],
    };

    const formats = [
        "bold",
        "italic",
        "underline",
    ];

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            aorticroot: (aorticroot) || '',
            leftatrium: (leftatrium) || '',
            leftdiastole: (leftDiastole) || '',
            systole: (systole) || '',
            ivs: (ivs) || '',
            lvpw: (lvpw) || '',
            lvef: (lvef) || '',
            rightdiastole: (rightDiastole) || '',
        },
        validationSchema: Yup.object({
            aorticroot: Yup.string().required("Please enter aortic root value"),
            leftatrium: Yup.string().required("Please enter left atrium value"),
            leftdiastole: Yup.string().required("Please enter left diastole value"),
            systole: Yup.string().required("Please enter systole value"),
            ivs: Yup.string().required("Please enter ivs value"),
            lvpw: Yup.string().required("Please enter lvpw value"),
            lvef: Yup.string().required("Please enter lvef value"),
            rightdiastole: Yup.string().required("Please enter right diastole value"),
        }),
        onSubmit: (values, { }) => {
            console.log(values.aorticroot);
            setTab1Flag(true)
            changeLangugeTab("12")
            setTab1(true)
        },
    });

    const tab2validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            e: (e) || '',
            a: (a) || '',
            aorticTab2: (aorticTab2) || '',
            tricuspid: (tricuspid) || '',
            pulmonary: (pulmonary) || '',
        },
        validationSchema: Yup.object({
            e: Yup.string().required("Please enter e value"),
            a: Yup.string().required("Please enter a value"),
            aorticTab2: Yup.string().required("Please enter aortic valve"),
            tricuspid: Yup.string().required("Please enter tricuspid valve"),
            pulmonary: Yup.string().required("Please enter pulmonary valve"),
        }),
        onSubmit: (values, { }) => {
            console.log('fgfdg', values.aorticTab2);
            // setTab1Flag(true)
            // changeLangugeTab("13")
            // setTab1(true)
            editorValuesCheck()
        },
    });

    const editorValuesCheck = () => {

        var mitrlhtmlContent = ''
        var aortichtmlContent = ''
        var tricuspidhtmlContent = ''
        var pulmonaryhtmlContent = ''

        const editor1 = mitralEditorTab2Ref.current.getEditor();
        mitrlhtmlContent = editor1.getText().trim();

        const editor2 = aorticEditorTab2Ref.current.getEditor();
        aortichtmlContent = editor2.getText().trim();

        const editor3 = tricuspidEditorTab2Ref.current.getEditor();
        tricuspidhtmlContent = editor3.getText().trim();

        const editor4 = pulmonaryEditorTab2Ref.current.getEditor();
        pulmonaryhtmlContent = editor4.getText().trim();

        if (mitrlhtmlContent === '') {
            toast.error('Please enter mitral editor value')
        } else if (aortichtmlContent === '') {
            toast.error('Please enter aortic editor value')
        } else if (tricuspidhtmlContent === '') {
            toast.error('Please enter tricuspid editor value')
        } else if (pulmonaryhtmlContent === '') {
            toast.error('Please enter pulmonary editor value')
        }

        if (mitrlhtmlContent !== '' && aortichtmlContent !== '' && tricuspidhtmlContent !== '' && pulmonaryhtmlContent !== '') {
            setTab2Flag(true)
            changeLangugeTab("13")
            setTab2(true)
        }
    }


    const tab3EditorValuesCheck = () => {

        var mitrlhtmlContent = ''
        var leftatriumhtmlContent = ''
        var aortichtmlContent = ''
        var rightatriumhtmlContent = ''
        var tricuspidhtmlContent = ''
        var leftventricalhtmlContent = ''
        var pulmonaryhtmlContent = ''
        var greathtmlContent = ''
        var iashtmlContent = ''
        var percardiumhtmlContent = ''
        var leftventricalhtmlContent = ''
        var impressionhtmlContent = ''

        const editor1 = mitralValueIframeRef.current.getEditor();
        mitrlhtmlContent = editor1.getText().trim();

        const editor2 = leftAtriumIframeRef.current.getEditor();
        leftatriumhtmlContent = editor2.getText().trim();

        const editor3 = aorticValueIframeRef.current.getEditor();
        aortichtmlContent = editor3.getText().trim();

        const editor4 = rightAtriumIframeRef.current.getEditor();
        rightatriumhtmlContent = editor4.getText().trim();

        const editor5 = tricuspidValueIframeRef.current.getEditor();
        tricuspidhtmlContent = editor5.getText().trim();

        const editor6 = rightVentricleValueIframeRef.current.getEditor();
        leftventricalhtmlContent = editor6.getText().trim();

        const editor7 = pulmonaryValueIframeRef.current.getEditor();
        pulmonaryhtmlContent = editor7.getText().trim();

        const editor8 = greatvesselsValueIframeRef.current.getEditor();
        greathtmlContent = editor8.getText().trim();

        const editor9 = iasValueIframeRef.current.getEditor();
        iashtmlContent = editor9.getText().trim();

        const editor10 = pericardiumValueIframeRef.current.getEditor();
        percardiumhtmlContent = editor10.getText().trim();

        const editor11 = leftventricleValueIframeRef.current.getEditor();
        leftventricalhtmlContent = editor11.getText().trim();

        const editor12 = impressionValueIframeRef.current.getEditor();
        impressionhtmlContent = editor12.getText().trim();

        if (templateName === '') {
            toast.error('Please enter template name')
        }

        else if (mitrlhtmlContent === '' && leftatriumhtmlContent === '' && aortichtmlContent === '' && rightatriumhtmlContent === '' && tricuspidhtmlContent === '' && leftventricalhtmlContent === '' && pulmonaryhtmlContent === '' && greathtmlContent === '' && iashtmlContent === '' && percardiumhtmlContent === '' && leftventricalhtmlContent === '' && impressionhtmlContent === '') {
            toast.error('Please enter atleast one value to create template')
        }
        else {
            saveReport()
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <div>
                        <div className="mb-3 row">
                            <label className="col-lg-2 col-md-4 col-sm-6 col-form-label template-label">Template Name<span className="text-danger">*</span>
                            </label>
                            <div className="col-md-4"><input className="form-control" type="text" placeholder='Template Name' value={templateName}
                                maxLength={constants.textboxLength.template_name}

                                onChange={e => settemplateName(e.target.value)} /></div></div>
                    </div>
                    <TabContent
                        activeTab='1'
                        className="crypto-buy-sell-nav-content p-4"
                        style={{
                            backgroundColor: "white",
                        }}
                    >
                        <TabPane tabId="1" id="buy">
                            <Nav tabs className="nav-tabs-custom nav-justified tab-font-size">
                                {tapHideFlag == true && <><NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: langugeTab == "11",
                                        })}
                                    // onClick={() => {
                                    //     changeLangugeTab("11")
                                    //     setTab1(true)
                                    // }}
                                    >
                                        <span className="d-block d-sm-none">
                                            M
                                        </span>
                                        <span className="d-none d-sm-block">
                                            M-MODE ECHOCARDIOGRAM REPORT
                                        </span>
                                    </NavLink>
                                </NavItem>
                                    <NavItem>
                                        <NavLink
                                            style={{ cursor: "pointer" }}
                                            className={classnames({
                                                active: langugeTab == "12",
                                            })}
                                        // onClick={() => {
                                        //     changeLangugeTab("12")
                                        //     setTab2(true)
                                        // }}
                                        >
                                            <span className="d-block d-sm-none">
                                                D
                                            </span>
                                            <span className="d-none d-sm-block">
                                                DOPPLER REPORT AND DOPPLER WAVEFORM ANALYSIS
                                            </span>
                                        </NavLink>
                                    </NavItem></>}
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: langugeTab == "13",
                                        })}
                                        onClick={() => {
                                            changeLangugeTab("13")
                                            setTab3(true)
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            2D
                                        </span>
                                        <span className="d-none d-sm-block">
                                            2D & COLOUR FLOW DOPPLER REPORT
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: langugeTab == "14",
                                        })}
                                        onClick={() => {
                                            changeLangugeTab("14")
                                            setTab4(true)
                                        }}
                                    >
                                        <span className="d-block d-sm-none">
                                            LV
                                        </span>
                                        <span className="d-none d-sm-block">
                                            LEFT VENTRICAL & IMPRESSION COMMENT
                                        </span>
                                    </NavLink>
                                </NavItem>
                                <Col
                                    lg="12"
                                    style={{
                                        backgroundColor: "white",
                                    }}
                                >
                                    <TabContent
                                        activeTab={langugeTab}
                                        className="text-muted"
                                    >
                                        {tapHideFlag == true && <>
                                            <TabPane tabId="11">
                                                <div className="card-body">
                                                    <div className="mt-3">
                                                        <Row>
                                                            <Col lg="4" className="sm-12">
                                                                <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                    <tbody>
                                                                        <tr className="table-light grid-back-color">
                                                                            <th className="text-nowrap" scope="row" style={{ backgroundColor: '#d7dade' }}>DIMENSIONS</th>
                                                                            <th className="text-nowrap text-center" scope="row" style={{ backgroundColor: '#d7dade' }}>VALUES</th>
                                                                            <th className="text-nowrap" scope="row" style={{ backgroundColor: '#d7dade' }}>NORMAL</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="td-align">AORTIC ROOT(ED)<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text"
                                                                                    name="aorticroot"
                                                                                    id="example-date-input"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.aorticroot || ""}
                                                                                    invalid={validation.touched.aorticroot && validation.errors.aorticroot}
                                                                                />
                                                                                <div className="input-group-text">cm</div></div>
                                                                            </td>
                                                                            <td className="td-align">{aorticRootDefault}</td>
                                                                            {validation.touched.aorticroot && validation.errors.aorticroot ? (
                                                                                <FormFeedback type="invalid">{validation.errors.aorticroot}</FormFeedback>
                                                                            ) : null}
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="td-align">LEFT ATRIUM(ES)<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="leftatrium"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.leftatrium || ""}
                                                                                    invalid={validation.touched.leftatrium && validation.errors.leftatrium}
                                                                                /><div className="input-group-text">cm</div></div></td>
                                                                            <td className="td-align">{leftAtriumDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.leftatrium && validation.errors.leftatrium ? (
                                                                            <FormFeedback type="invalid">{validation.errors.leftatrium}</FormFeedback>
                                                                        ) : null}
                                                                    </tbody>
                                                                </table>
                                                            </Col>
                                                            <Col lg="4" className="sm-12">
                                                                <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                    <tbody>
                                                                        <tr className="table-light grid-back-color">
                                                                            <th className="text-nowrap" scope="row">LEFT VENTRICAL</th>
                                                                            <th className="text-nowrap text-center" scope="row">VALUES</th>
                                                                            <th className="text-nowrap" scope="row">NORMAL</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="td-align">DIASTOLE<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="leftdiastole"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.leftdiastole || ""}
                                                                                    invalid={validation.touched.leftdiastole && validation.errors.leftdiastole}
                                                                                /><div className="input-group-text">cm</div></div></td>
                                                                            <td className="td-align">{leftVentricalDiastoleDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.leftdiastole && validation.errors.leftdiastole ? (
                                                                            <FormFeedback type="invalid">{validation.errors.leftdiastole}</FormFeedback>
                                                                        ) : null}
                                                                        <tr>
                                                                            <td className="td-align">SYSTOLE<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="systole"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.systole || ""}
                                                                                    invalid={validation.touched.systole && validation.errors.systole}
                                                                                /><div className="input-group-text">cm</div></div></td>
                                                                            <td className="td-align">{systoleDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.systole && validation.errors.systole ? (
                                                                            <FormFeedback type="invalid">{validation.errors.systole}</FormFeedback>
                                                                        ) : null}
                                                                        <tr>
                                                                            <td className="td-align">IVS(D)<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="ivs"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.ivs || ""}
                                                                                    invalid={validation.touched.ivs && validation.errors.ivs}
                                                                                />
                                                                                <div className="input-group-text">cm</div></div></td>
                                                                            <td className="td-align">{ivsDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.ivs && validation.errors.ivs ? (
                                                                            <FormFeedback type="invalid">{validation.errors.ivs}</FormFeedback>
                                                                        ) : null}
                                                                        <tr>
                                                                            <td className="td-align">LVPW(D)<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="lvpw"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lvpw || ""}
                                                                                    invalid={validation.touched.lvpw && validation.errors.lvpw}
                                                                                /><div className="input-group-text">cm</div></div></td>
                                                                            <td className="td-align">{lvpwDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.lvpw && validation.errors.lvpw ? (
                                                                            <FormFeedback type="invalid">{validation.errors.lvpw}</FormFeedback>
                                                                        ) : null}
                                                                        <tr>
                                                                            <td className="td-align">LV EF<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="lvef"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.lvef || ""}
                                                                                    invalid={validation.touched.lvef && validation.errors.lvef}
                                                                                /><div className="input-group-text">%</div></div></td>
                                                                            <td className="td-align">{lvefDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.lvef && validation.errors.lvef ? (
                                                                            <FormFeedback type="invalid">{validation.errors.lvef}</FormFeedback>
                                                                        ) : null}
                                                                    </tbody>
                                                                </table>
                                                            </Col>
                                                            <Col lg="4" className="sm-12">
                                                                <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                    <tbody>
                                                                        <tr className="table-light grid-back-color">
                                                                            <th className="text-nowrap" scope="row">RIGHT VENTRICAL</th>
                                                                            <th className="text-nowrap text-center" scope="row">VALUES</th>
                                                                            <th className="text-nowrap" scope="row">NORMAL</th>
                                                                        </tr>
                                                                        <tr>
                                                                            <td className="td-align">DIASTOLE<span className="text-danger">*</span></td>
                                                                            <td className="td-align"><div className="input-group">
                                                                                <Input className="form-control grid-input text-end" type="text" id="example-date-input"
                                                                                    name="rightdiastole"
                                                                                    onChange={validation.handleChange}
                                                                                    onBlur={validation.handleBlur}
                                                                                    value={validation.values.rightdiastole || ""}
                                                                                    invalid={validation.touched.rightdiastole && validation.errors.rightdiastole}
                                                                                /><div className="input-group-text">cm</div></div></td>
                                                                            <td className="td-align">{rightVentricalDiastoleDefault}</td>
                                                                        </tr>
                                                                        {validation.touched.rightdiastole && validation.errors.rightdiastole ? (
                                                                            <FormFeedback type="invalid">{validation.errors.rightdiastole}</FormFeedback>
                                                                        ) : null}
                                                                    </tbody>
                                                                </table>
                                                                <div className="text-end mt-2 mb-3">
                                                                    <Button
                                                                        color="info"
                                                                        type="submit"
                                                                        className="save-and-continue-user me-2"
                                                                        onClick={
                                                                            validation.handleSubmit

                                                                            // changeLangugeTab("12")
                                                                            // setTab2(true)
                                                                        }
                                                                    // style={{ marginLeft: '10px' }}
                                                                    // disabled={isButtonDisabled}

                                                                    >
                                                                        Next
                                                                    </Button>
                                                                    {/* <Button
                                                                        color="success"
                                                                        // type="submit"
                                                                        className="save-user"
                                                                        onClick={saveReport}
                                                                    // disabled={isButtonDisabled}
                                                                    >
                                                                        {isEdit ? "Update" : "Save"}
                                                                    </Button> */}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="12">
                                                <div className="card-body">
                                                    <Row className="mt-4">
                                                        <Col lg="3" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center report-th" scope="row">MITRAL<span className="text-danger">*</span></th>
                                                                        <th>
                                                                            <Input className="form-control grid-input" type="text" id="example-date-input"
                                                                                name="e"
                                                                                placeholder="E"
                                                                                onChange={tab2validation.handleChange}
                                                                                onBlur={tab2validation.handleBlur}
                                                                                value={tab2validation.values.e || ""}
                                                                                invalid={tab2validation.touched.e && tab2validation.errors.e}
                                                                            />
                                                                            <Input className="form-control grid-input" type="text" id="example-date-input"
                                                                                name="a"
                                                                                placeholder="A"
                                                                                onChange={tab2validation.handleChange}
                                                                                onBlur={tab2validation.handleBlur}
                                                                                value={tab2validation.values.a || ""}
                                                                                invalid={tab2validation.touched.a && tab2validation.errors.a}
                                                                            />
                                                                        </th>
                                                                        {tab2validation.touched.e && tab2validation.errors.e ? (
                                                                            <FormFeedback type="invalid">{tab2validation.errors.e}</FormFeedback>
                                                                        ) : null}
                                                                        {tab2validation.touched.a && tab2validation.errors.a ? (
                                                                            <FormFeedback type="invalid">{tab2validation.errors.a}</FormFeedback>
                                                                        ) : null}
                                                                        <th className="input-width report-th">
                                                                            E/A: <span className="text-bold">{ea}</span>
                                                                        </th>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={3} style={{ height: "175px" }}>
                                                                            {/* <TextEditor /> */}
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow"
                                                                                    ref={mitralEditorTab2Ref}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    style={{ height: "135px" }
                                                                                    }
                                                                                    onChange={setMitralEditorTab2}
                                                                                    value={mitralEditorTab2}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col lg="3" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center report-th" scope="row">AORTIC<span className="text-danger">*</span></th>
                                                                        <th className="report-th">
                                                                            <Input className="form-control grid-input" type="text"
                                                                                name="aorticTab2"
                                                                                id="example-date-input"
                                                                                onChange={tab2validation.handleChange}
                                                                                onBlur={tab2validation.handleBlur}
                                                                                value={tab2validation.values.aorticTab2 || ""}
                                                                                invalid={tab2validation.touched.aorticTab2 && tab2validation.errors.aorticTab2}
                                                                            />
                                                                        </th>
                                                                        {tab2validation.touched.aorticTab2 && tab2validation.errors.aorticTab2 ? (
                                                                            <FormFeedback type="invalid">{tab2validation.errors.aorticTab2}</FormFeedback>
                                                                        ) : null}
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow" ref={aorticEditorTab2Ref}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    style={{ height: "135px" }}
                                                                                    onChange={setAorticEditorTab2}
                                                                                    value={aorticEditorTab2}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col lg="3" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center report-th" scope="row">TRICUSPID<span className="text-danger">*</span></th>
                                                                        <th className="report-th">
                                                                            <Input className="form-control grid-input" type="text"
                                                                                name="tricuspid" id="example-date-input"
                                                                                onChange={tab2validation.handleChange}
                                                                                onBlur={tab2validation.handleBlur}
                                                                                value={tab2validation.values.tricuspid || ""}
                                                                                invalid={tab2validation.touched.tricuspid && tab2validation.errors.tricuspid}
                                                                            />
                                                                        </th>
                                                                        {tab2validation.touched.tricuspid && tab2validation.errors.tricuspid ? (
                                                                            <FormFeedback type="invalid">{tab2validation.errors.tricuspid}</FormFeedback>
                                                                        ) : null}
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow" ref={tricuspidEditorTab2Ref}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    style={{ height: "135px" }}
                                                                                    onChange={setTricuspidEditorTab2}
                                                                                    value={tricuspidEditorTab2}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </Col>
                                                        <Col lg="3" className="col-sm-12">
                                                            <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                                <tbody>
                                                                    <tr className="table-light grid-back-color">
                                                                        <th className="text-nowrap text-center report-th" scope="row">PULMONARY<span className="text-danger">*</span></th>
                                                                        <th className="report-th">
                                                                            <Input className="form-control grid-input" type="text"
                                                                                name="pulmonary" id="example-date-input"
                                                                                onChange={tab2validation.handleChange}
                                                                                onBlur={tab2validation.handleBlur}
                                                                                value={tab2validation.values.pulmonary || ""}
                                                                                invalid={tab2validation.touched.pulmonary && tab2validation.errors.pulmonary}
                                                                            />
                                                                        </th>
                                                                        {tab2validation.touched.pulmonary && tab2validation.errors.pulmonary ? (
                                                                            <FormFeedback type="invalid">{tab2validation.errors.pulmonary}</FormFeedback>
                                                                        ) : null}
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} style={{ height: "175px" }}>
                                                                            <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                                <ReactQuill theme="snow" ref={pulmonaryEditorTab2Ref}
                                                                                    modules={modules}
                                                                                    formats={formats}
                                                                                    style={{ height: "135px" }}
                                                                                    onChange={setPulmonaryEditorTab2}
                                                                                    value={pulmonaryEditorTab2}
                                                                                />
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            <div className="text-end mt-2 mb-3">
                                                                <Button
                                                                    color="warning"
                                                                    // type="submit"
                                                                    className="save-user me-2"
                                                                    onClick={() => {
                                                                        changeLangugeTab("11")
                                                                        setTab1(true)
                                                                    }}
                                                                // disabled={isButtonDisabled}

                                                                >
                                                                    Previous
                                                                </Button>
                                                                <Button
                                                                    color="info"
                                                                    type="submit"
                                                                    className="save-and-continue-user me-2"
                                                                    onClick={tab2validation.handleSubmit}
                                                                // style={{ marginLeft: '10px' }}
                                                                // disabled={isButtonDisabled}

                                                                >
                                                                    Next
                                                                </Button>
                                                                {/* <Button
                                                                    color="success"
                                                                    // type="submit"
                                                                    className="save-user"
                                                                    onClick={() => saveReport()}
                                                                // disabled={isButtonDisabled}

                                                                >
                                                                    {isEdit ? "Update" : "Save"}
                                                                </Button> */}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </TabPane>
                                        </>}
                                        <TabPane tabId="13">
                                            <div className="card-body">
                                                <Row className="mt-3">
                                                    <Col lg="6" className="col-sm-12">
                                                        <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                            <tbody>
                                                                <tr className="table-light grid-back-color">
                                                                    <th className="text-nowrap text-center col-sm-2" scope="row">
                                                                        MITRAL VALVE
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Row>
                                                                            <Col xs={12} md={11}>
                                                                                <Select
                                                                                    id="mitral"
                                                                                    placeholder='Search'
                                                                                    value={mitralselectedOptions}
                                                                                    onChange={
                                                                                        setMitralSelectedOptions}

                                                                                    options={mitralOptions.map(op => ({
                                                                                        label: op.mv_value,
                                                                                        value: op.mvid,
                                                                                    }))}
                                                                                    className={`select2-selection $`}
                                                                                // invalid={validation.touched.selectedHospital && validation.errors.selectedHospital}
                                                                                />
                                                                            </Col>
                                                                            <Col xs={12} md={1} className="div-plus-icon">
                                                                                <div onClick={() => {
                                                                                    var mitral_value = mitralValue + mitralselectedOptions?.label

                                                                                    if (mitralselectedOptions?.label === undefined) {
                                                                                        toast.error('Please select the value')
                                                                                    } else {
                                                                                        setMitralValue(mitral_value)
                                                                                    }
                                                                                }}
                                                                                    className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: "175px" }}>
                                                                        <div style={{ backgroundColor: 'white', height: "175px" }}>

                                                                            <ReactQuill theme="snow" ref={mitralValueIframeRef}
                                                                                modules={modules}
                                                                                formats={formats}
                                                                                value={mitralValue}
                                                                                onChange={setMitralValue}
                                                                                style={{ height: "135px" }} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                    <Col lg="6" className="col-sm-12">
                                                        <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                            <tbody>
                                                                <tr className="table-light grid-back-color">
                                                                    <th className="text-nowrap text-center" scope="row">
                                                                        LEFT ATRIUM
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Row>
                                                                            <Col md={11}>
                                                                                <Select
                                                                                    id="la"
                                                                                    placeholder='Search'
                                                                                    value={leftAtriumselectedOptions}
                                                                                    onChange={(selectedOption) => {
                                                                                        setLeftAtriumSelectedOptions(selectedOption)

                                                                                    }}

                                                                                    options={leftAtriumOptions.map(op => ({
                                                                                        label: op.mv_value,
                                                                                        value: op.mvid,
                                                                                    }))}
                                                                                    className={`select2-selection $`}

                                                                                />
                                                                            </Col>
                                                                            <Col md={1} className="div-plus-icon">
                                                                                <div onClick={() => {
                                                                                    var leftatrium_value = leftAtriumValue + leftAtriumselectedOptions?.label

                                                                                    if (leftAtriumselectedOptions?.label === undefined) {
                                                                                        toast.error('Please select the value')
                                                                                    } else {
                                                                                        setLeftAtriumValue(leftatrium_value)
                                                                                    }
                                                                                }}
                                                                                    className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: "175px" }}>
                                                                        <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                            <ReactQuill theme="snow" ref={leftAtriumIframeRef}
                                                                                modules={modules}
                                                                                formats={formats}
                                                                                value={leftAtriumValue} onChange={setLeftAtriumValue}
                                                                                style={{ height: "135px" }} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col lg="6" className="col-sm-12">
                                                        <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                            <tbody>
                                                                <tr className="table-light grid-back-color">
                                                                    <th className="text-nowrap text-center" scope="row">
                                                                        AORTIC VALVE
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Row>
                                                                            <Col md={11}>
                                                                                <Select
                                                                                    id="av"
                                                                                    placeholder='Search'
                                                                                    value={aorticValueselectedOptions}
                                                                                    onChange={(selectedOption) => {
                                                                                        setAorticValuesSelectedOptions(selectedOption)

                                                                                    }}

                                                                                    options={aorticValueOptions.map(op => ({
                                                                                        label: op.mv_value,
                                                                                        value: op.mvid,
                                                                                    }))}
                                                                                    className={`select2-selection $`}

                                                                                />
                                                                            </Col>
                                                                            <Col md={1} className="div-plus-icon">
                                                                                <div onClick={() => {
                                                                                    var aortic_value = aorticValue + aorticValueselectedOptions?.label

                                                                                    if (aorticValueselectedOptions?.label === undefined) {
                                                                                        toast.error('Please select the value')
                                                                                    } else {
                                                                                        setAorticValue(aortic_value)
                                                                                    }
                                                                                }}
                                                                                    className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: "175px" }}>
                                                                        <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                            <ReactQuill theme="snow"
                                                                                ref={aorticValueIframeRef}
                                                                                modules={modules}
                                                                                formats={formats}
                                                                                value={aorticValue} onChange={setAorticValue} style={{ height: "135px" }} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                    <Col lg="6" className="col-sm-12">
                                                        <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                            <tbody>
                                                                <tr className="table-light grid-back-color">
                                                                    <th className="text-nowrap text-center" scope="row">
                                                                        RIGHT ATRIUM
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Row>
                                                                            <Col md={11}>
                                                                                <Select
                                                                                    id="av"
                                                                                    placeholder='Search'
                                                                                    value={rightAtriumselectedOptions}
                                                                                    onChange={(selectedOption) => {
                                                                                        setRightAtriumSelectedOptions(selectedOption)

                                                                                    }}

                                                                                    options={rightAtriumOptions.map(op => ({
                                                                                        label: op.mv_value,
                                                                                        value: op.mvid,
                                                                                    }))}
                                                                                    className={`select2-selection $`}

                                                                                />
                                                                            </Col>
                                                                            <Col md={1} className="div-plus-icon">
                                                                                <div onClick={() => {
                                                                                    var rightatrium_value = rightAtriumValue + rightAtriumselectedOptions?.label

                                                                                    if (rightAtriumselectedOptions?.label === undefined) {
                                                                                        toast.error('Please select the value')
                                                                                    } else {
                                                                                        setRightAtriumValue(rightatrium_value)
                                                                                    }
                                                                                }}
                                                                                    className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: "175px" }}>
                                                                        <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                            <ReactQuill theme="snow"
                                                                                ref={rightAtriumIframeRef}
                                                                                modules={modules}
                                                                                formats={formats}
                                                                                value={rightAtriumValue} onChange={setRightAtriumValue}
                                                                                style={{ height: "135px" }} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col lg="6" className="col-sm-12">
                                                        <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                            <tbody>
                                                                <tr className="table-light grid-back-color">
                                                                    <th className="text-nowrap text-center" scope="row">
                                                                        TRICUSPID VALVE
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Row>
                                                                            <Col md={11}>
                                                                                <Select
                                                                                    id="tv"
                                                                                    placeholder='Search'
                                                                                    value={tricuspidValueselectedOptions}
                                                                                    onChange={(selectedOption) => {
                                                                                        setTricuspidValueSelectedOptions(selectedOption)

                                                                                    }}

                                                                                    options={tricuspidValueOptions.map(op => ({
                                                                                        label: op.mv_value,
                                                                                        value: op.mvid,
                                                                                    }))}
                                                                                    className={`select2-selection $`}

                                                                                />
                                                                            </Col>
                                                                            <Col md={1} className="div-plus-icon">
                                                                                <div onClick={() => {
                                                                                    var tricuspid_value = tricuspidValue + tricuspidValueselectedOptions?.label

                                                                                    if (tricuspidValueselectedOptions?.label === undefined) {
                                                                                        toast.error('Please select the value')
                                                                                    } else {
                                                                                        setTricuspidValue(tricuspid_value)
                                                                                    }
                                                                                }}
                                                                                    className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: "175px" }}>
                                                                        <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                            <ReactQuill theme="snow"
                                                                                ref={tricuspidValueIframeRef}
                                                                                modules={modules}
                                                                                formats={formats}
                                                                                value={tricuspidValue} onChange={setTricuspidValue}
                                                                                style={{ height: "135px" }} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                    <Col lg="6" className="col-sm-12">
                                                        <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                            <tbody>
                                                                <tr className="table-light grid-back-color">
                                                                    <th className="text-nowrap text-center" scope="row">
                                                                        RIGHT VENTRICAL
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Row>
                                                                            <Col md={11}>
                                                                                <Select
                                                                                    id="rv"
                                                                                    placeholder='Search'
                                                                                    value={rightVentricalselectedOptions}
                                                                                    onChange={(selectedOption) => {
                                                                                        setRightVentricalSelectedOptions(selectedOption)

                                                                                    }}

                                                                                    options={rightVentricalOptions.map(op => ({
                                                                                        label: op.mv_value,
                                                                                        value: op.mvid,
                                                                                    }))}
                                                                                    className={`select2-selection $`}

                                                                                />
                                                                            </Col>
                                                                            <Col md={1} className="div-plus-icon">
                                                                                <div onClick={() => {
                                                                                    var rightventrical_value = rightVentricalValue + rightVentricalselectedOptions?.label

                                                                                    if (rightVentricalselectedOptions?.label === undefined) {
                                                                                        toast.error('Please select the value')
                                                                                    } else {
                                                                                        setRightVentricalValue(rightventrical_value)
                                                                                    }
                                                                                }}
                                                                                    className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: "175px" }}>
                                                                        <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                            <ReactQuill theme="snow"
                                                                                ref={rightVentricleValueIframeRef}
                                                                                modules={modules}
                                                                                formats={formats}
                                                                                value={rightVentricalValue} onChange={setRightVentricalValue}
                                                                                style={{ height: "135px" }} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col lg="6" className="col-sm-12">
                                                        <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                            <tbody>
                                                                <tr className="table-light grid-back-color">
                                                                    <th className="text-nowrap text-center" scope="row">
                                                                        PULMONARY VALVE
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Row>
                                                                            <Col md={11}>
                                                                                <Select
                                                                                    id="pv"
                                                                                    placeholder='Search'
                                                                                    value={pulmonaryValueselectedOptions}
                                                                                    onChange={(selectedOption) => {
                                                                                        setPulmonaryValueSelectedOptions(selectedOption)

                                                                                    }}

                                                                                    options={pulmonaryValueOptions.map(op => ({
                                                                                        label: op.mv_value,
                                                                                        value: op.mvid,
                                                                                    }))}
                                                                                    className={`select2-selection $`}

                                                                                />
                                                                            </Col>
                                                                            <Col md={1} className="div-plus-icon">
                                                                                <div onClick={() => {
                                                                                    var pulmonary_value = pulmonaryValue + pulmonaryValueselectedOptions?.label

                                                                                    if (pulmonaryValueselectedOptions?.label === undefined) {
                                                                                        toast.error('Please select the value')
                                                                                    } else {
                                                                                        setPulmonaryValue(pulmonary_value)
                                                                                    }
                                                                                }}
                                                                                    className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: "175px" }}>
                                                                        <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                            <ReactQuill theme="snow"
                                                                                ref={pulmonaryValueIframeRef}
                                                                                modules={modules}
                                                                                formats={formats}
                                                                                value={pulmonaryValue} onChange={setPulmonaryValue}
                                                                                style={{ height: "135px" }} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                    <Col lg="6" className="col-sm-12">
                                                        <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                            <tbody>
                                                                <tr className="table-light grid-back-color">
                                                                    <th className="text-nowrap text-center" scope="row">
                                                                        GREAT VESSELS
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Row>
                                                                            <Col md={11}>
                                                                                <Select
                                                                                    id="gv"
                                                                                    placeholder='Search'
                                                                                    value={greatselectedOptions}
                                                                                    onChange={(selectedOption) => {
                                                                                        setGreatSelectedOptions(selectedOption)

                                                                                    }}

                                                                                    options={greatOptions.map(op => ({
                                                                                        label: op.mv_value,
                                                                                        value: op.mvid,
                                                                                    }))}
                                                                                    className={`select2-selection $`}

                                                                                />
                                                                            </Col>
                                                                            <Col md={1} className="div-plus-icon">
                                                                                <div onClick={() => {
                                                                                    var greatvessels_value = greatVesselsValue + greatselectedOptions?.label

                                                                                    if (greatselectedOptions?.label === undefined) {
                                                                                        toast.error('Please select the value')
                                                                                    } else {
                                                                                        setGreatVesselsValue(greatvessels_value)
                                                                                    }
                                                                                }}
                                                                                    className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: "175px" }}>
                                                                        <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                            <ReactQuill theme="snow"
                                                                                ref={greatvesselsValueIframeRef}
                                                                                modules={modules}
                                                                                formats={formats}
                                                                                value={greatVesselsValue} onChange={setGreatVesselsValue}
                                                                                style={{ height: "135px" }} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                </Row>
                                                <Row className="mt-3">
                                                    <Col lg="6" className="col-sm-12">
                                                        <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                            <tbody>
                                                                <tr className="table-light grid-back-color">
                                                                    <th className="text-nowrap text-center" scope="row">
                                                                        IAS & IVS
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Row>
                                                                            <Col md={11}>
                                                                                <Select
                                                                                    id="iav"
                                                                                    placeholder='Search'
                                                                                    value={iasselectedOptions}
                                                                                    onChange={(selectedOption) => {
                                                                                        setIasSelectedOptions(selectedOption)

                                                                                    }}

                                                                                    options={iasOptions.map(op => ({
                                                                                        label: op.mv_value,
                                                                                        value: op.mvid,
                                                                                    }))}
                                                                                    className={`select2-selection $`}

                                                                                />
                                                                            </Col>
                                                                            <Col md={1} className="div-plus-icon">
                                                                                <div onClick={() => {
                                                                                    var ias_value = iasValue + iasselectedOptions?.label

                                                                                    if (iasselectedOptions?.label === undefined) {
                                                                                        toast.error('Please select the value')
                                                                                    } else {
                                                                                        setIasValue(ias_value)
                                                                                    }
                                                                                }}
                                                                                    className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: "175px" }}>
                                                                        <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                            <ReactQuill theme="snow"
                                                                                ref={iasValueIframeRef}
                                                                                modules={modules}
                                                                                formats={formats}
                                                                                value={iasValue} onChange={setIasValue}
                                                                                style={{ height: "135px" }} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                    <Col lg="6" className="col-sm-12">
                                                        <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                            <tbody>
                                                                <tr className="table-light grid-back-color">
                                                                    <th className="text-nowrap text-center" scope="row">
                                                                        PERCARDIUM
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Row>
                                                                            <Col md={11}>
                                                                                <Select
                                                                                    id="pv"
                                                                                    placeholder='Search'
                                                                                    value={percardiumselectedOptions}
                                                                                    onChange={(selectedOption) => {
                                                                                        setPercardiumSelectedOptions(selectedOption)

                                                                                    }}

                                                                                    options={percardiumOptions.map(op => ({
                                                                                        label: op.mv_value,
                                                                                        value: op.mvid,
                                                                                    }))}
                                                                                    className={`select2-selection $`}

                                                                                />
                                                                            </Col>
                                                                            <Col md={1} className="div-plus-icon">
                                                                                <div onClick={() => {
                                                                                    var percardium_value = percardiumValue + percardiumselectedOptions?.label

                                                                                    if (percardiumselectedOptions?.label === undefined) {
                                                                                        toast.error('Please select the value')
                                                                                    } else {
                                                                                        setPercardiumValue(percardium_value)
                                                                                    }
                                                                                }}
                                                                                    className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: "175px" }}>
                                                                        <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                            <ReactQuill theme="snow"
                                                                                ref={pericardiumValueIframeRef}
                                                                                modules={modules}
                                                                                formats={formats}
                                                                                value={percardiumValue} onChange={setPercardiumValue}
                                                                                style={{ height: "135px" }} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <div className="text-end mt-2 mb-3">
                                                            {/* <Button
                                                                color="warning"
                                                                // type="submit"
                                                                className="save-user me-2"
                                                                onClick={() => {
                                                                    changeLangugeTab("12")
                                                                    setMitralEditorTab2(true)
                                                                }}
                                                            // disabled={isButtonDisabled}

                                                            >
                                                                Previous
                                                            </Button> */}
                                                            <Button
                                                                color="info"
                                                                type="submit"
                                                                className="save-and-continue-user me-2"
                                                                onClick={() => {
                                                                    changeLangugeTab("14")
                                                                    setMitralEditorTab2(true)
                                                                }}
                                                            // style={{ marginLeft: '10px' }}
                                                            // disabled={isButtonDisabled}

                                                            >
                                                                Next
                                                            </Button>
                                                            {/* <Button
                                                                    color="success"
                                                                    // type="submit"
                                                                    className="save-user"
                                                                    onClick={() => saveReport()}
                                                                // disabled={isButtonDisabled}

                                                                >
                                                                    Save
                                                            </Button> */}
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </TabPane>
                                        <TabPane tabId="14">
                                            <div className="card-body">
                                                <Row className="mt-3">
                                                    <Col lg="6" className="col-sm-12">
                                                        <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                            <tbody>
                                                                <tr className="table-light grid-back-color">
                                                                    <th className="text-nowrap text-center" scope="row">
                                                                        LEFT VENTRICAL
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Row>
                                                                            <Col md={11}>
                                                                                <Select
                                                                                    id="lfv"
                                                                                    placeholder='Search'
                                                                                    value={leftVentricalselectedOptions}
                                                                                    onChange={(selectedOption) => {
                                                                                        setLeftVentricalSelectedOptions(selectedOption)

                                                                                    }}

                                                                                    options={leftVentricalOptions.map(op => ({
                                                                                        label: op.mv_value,
                                                                                        value: op.mvid,
                                                                                    }))}
                                                                                    className={`select2-selection $`}

                                                                                />
                                                                            </Col>
                                                                            <Col md={1}>
                                                                                <div onClick={() => {
                                                                                    var leftventrical_value = leftVentricalValue + leftVentricalselectedOptions?.label

                                                                                    if (leftVentricalselectedOptions?.label === undefined) {
                                                                                        toast.error('Please select the value')
                                                                                    } else {
                                                                                        setLeftVentricalValue(leftventrical_value)
                                                                                    }
                                                                                }}
                                                                                    className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div>
                                                                            </Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: "175px" }}>
                                                                        <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                            <ReactQuill theme="snow" ref={leftventricleValueIframeRef}
                                                                                modules={modules}
                                                                                formats={formats}
                                                                                value={leftVentricalValue} onChange={setLeftVentricalValue}
                                                                                style={{ height: "135px" }} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                    <Col lg="6" className="col-sm-12">
                                                        <table className="table table-bordered table-nowrap table-warning border-color mb-1">
                                                            <tbody>
                                                                <tr className="table-light grid-back-color">
                                                                    <th className="text-nowrap text-center" scope="row">
                                                                        IMPRESSION COMMENT
                                                                    </th>
                                                                </tr>
                                                                <tr>
                                                                    <td>
                                                                        <Row>
                                                                            <Col md={11}>
                                                                                <Select
                                                                                    id="ic"
                                                                                    placeholder='Search'
                                                                                    value={impressionselectedOptions}
                                                                                    onChange={(selectedOption) => {
                                                                                        setImpressionSelectedOptions(selectedOption)

                                                                                    }}

                                                                                    options={impressionOptions.map(op => ({
                                                                                        label: op.mv_value,
                                                                                        value: op.mvid,
                                                                                    }))}
                                                                                    className={`select2-selection $`}

                                                                                /></Col>
                                                                            <Col md={1}> <div onClick={() => {
                                                                                var impression_value = impressionValue + impressionselectedOptions?.label

                                                                                if (impressionselectedOptions?.label === undefined) {
                                                                                    toast.error('Please select the value')
                                                                                } else {
                                                                                    setImpressionValue(impression_value)
                                                                                }
                                                                            }}
                                                                                className="plus-icon"><i className="mdi mdi-plus mdi-14px"></i></div></Col>
                                                                        </Row>
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td colSpan={2} style={{ height: "175px" }}>
                                                                        <div style={{ backgroundColor: 'white', height: "175px" }}>
                                                                            <ReactQuill theme="snow"
                                                                                ref={impressionValueIframeRef}
                                                                                modules={modules}
                                                                                formats={formats}
                                                                                value={impressionValue} onChange={setImpressionValue}
                                                                                style={{ height: "135px" }} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Col>
                                                    <div className="text-end mt-2 mb-3">
                                                        <Button
                                                            color="info"
                                                            type="submit"
                                                            className="save-and-continue-user me-2"
                                                            onClick={() => navigate('/report-template')}
                                                        // style={{ marginLeft: '10px' }}
                                                        // disabled={isButtonDisabled}

                                                        >
                                                            Close
                                                        </Button>
                                                        <Button
                                                            color="warning"
                                                            // type="submit"
                                                            className="save-user me-2"
                                                            onClick={() => {
                                                                changeLangugeTab("13")
                                                                setTab3(true)
                                                            }}
                                                        // disabled={isButtonDisabled}

                                                        >
                                                            Previous
                                                        </Button>
                                                        <Button
                                                            color="success"
                                                            className="save-user"
                                                            onClick={tab3EditorValuesCheck}

                                                        >
                                                            {isEdit ? 'Update' : 'Save'}
                                                        </Button>
                                                    </div>
                                                </Row>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </Col>
                            </Nav>
                        </TabPane>
                    </TabContent>

                </Container>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </React.Fragment>
    );
};

export default withTranslation()(TemplateCreation);
