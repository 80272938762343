import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import { MENUDETAILS } from "constants/layout";
// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";
import './sidebarcontent.scss'
//i18n
import { withTranslation } from "react-i18next";

import { useNavigate } from 'react-router-dom';
import { getLoggedInUser } from "helpers/fakebackend_helper";
import * as constants from "../../constants/layout"
const SidebarContent = props => {
  const user = getLoggedInUser()
  const ref = useRef();
  let navigate = useNavigate();
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  // useEffect(() => {
  // }, [user])


  var details = MENUDETAILS

  // console.log(details.MenuDetails.m);


  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback((clickPath) => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      console.log('pathName', pathName);
      console.log('pathName', path);
      if (clickPath === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu(path.pathname);
  }, []);

  useEffect(() => {
    console.log('user', user?.u_urid)
    console.log('user', user?.user_previleges.find(e => e.u_urid == user?.u_urid && e.urp_mid != 1 && e.urp_smid != 3))
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu(path.pathname);
  }, []);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function togglemenu(menu_id) {
    var element = document.getElementById("menu_li_active_user");
    var element1 = document.getElementById("menu_link_active_user");
    element?.classList?.remove("mm-active");
    element1?.classList?.remove("active");
    details.MenuDetails.forEach(row => {
      var element4 = document.getElementById(row.MenuId + "menu_li_active");
      var element5 = document.getElementById(row.MenuId + "menu_link_active");
      element4?.classList?.remove("mm-active");
      element5?.classList?.remove("active");
      if (row.MenuId === menu_id) {
        var element2 = document.getElementById(menu_id + "menu_li_active");
        var element3 = document.getElementById(menu_id + "menu_link_active");
        element2?.classList?.add("mm-active");
        element3?.classList?.add("active");
      } else {
        if (menu_id === 0) {
          var element = document.getElementById("menu_li_active_user");
          var element1 = document.getElementById("menu_link_active_user");
          element?.classList?.add("mm-active");
          element1?.classList?.add("active");
        }
      }
    })
    if (menu_id > 0) {
      navigate('/master-screen', {
        state: {
          menuId: Number(menu_id)
        }
      })
    }
  }
  function myFunction() {
    var element = document.getElementById("myDIV");
    element.classList.add("mystyle");
  }
  let sidebarObj;

  function closeClick() {
    sidebarObj.hide();
  }
  function tToggle() {
    var body = document.body;
    console.log('body', body)
    console.log('window', window.screen.width)
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      // body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }

  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li>
              <Link to="/#" className="has-arrow">
                <i className="dripicons-article"></i>
                <span className="side-name">{props.t("Echo Reports")}</span>
              </Link>
              <ul className="sub-menu">

                {
                  ((user?.user_previleges?.find(e => e?.u_urid == user?.u_urid && Number(e?.urp_mid) ==
                    Number(constants?.menu_id?.reportreq) && Number(e.urp_smid) != Number(constants?.menu_id?.report)))
                  ) &&

                  < li onClick={() => activeMenu('/report-request')} >


                    <Link to="/report-request" onClick={() => {
                      tToggle();
                    }}>{props.t("Report Request")}</Link>

                  </li>
                }
                {
                  ((user?.user_previleges?.find(e => e?.u_urid == user?.u_urid && Number(e?.urp_mid) == Number(constants?.menu_id?.reportreq) && Number(e.urp_smid) != Number(constants?.menu_id?.report))) && (user?.user_previleges?.find(e => e?.u_urid == user?.u_urid && Number(e.urp_mid) == Number(constants?.menu_id?.reportreq) && Number(e.urp_smid) != Number(constants?.menu_id?.reportreq)))) &&

                  < li onClick={() => activeMenu('/report-approval')} >
                      <Link to="/report-approval" onClick={() => {
                        tToggle();
                      }}>{props.t("Report Approval")}</Link>
                    </li>
                }
                    {/* {
                  (

                    (user?.user_previleges.find(e => e.u_urid == user?.u_urid && e.urp_mid == '1' && e.urp_smid == '3'))
                  ) && */}
<li onClick={() => activeMenu('/report-list')} >
    <Link to="/report-list" onClick={() => {
      tToggle();
    }}>{props.t("Reports")}</Link>
  </li>
  {
    (user?.user_previleges?.find(e => e?.u_urid == user?.u_urid && Number(e?.urp_mid) == Number(constants?.menu_id?.reportreq) && Number(e.urp_smid) != Number(constants?.menu_id?.report)) && (user?.user_previleges?.find(e => e.u_urid == user.u_urid && Number(e?.urp_mid) == Number(constants?.menu_id?.reportreq) && Number(e?.urp_smid) != Number(constants?.menu_id?.reportreq))) && (user?.user_previleges?.find(e => e.u_urid == user?.u_urid && Number(e.urp_mid) != Number(constants?.menu_id?.reportreq) && Number(e.urp_smid) != Number(constants?.menu_id?.reportapp)))) &&
    <li onClick={() => activeMenu('/report-template')}>
        <Link to="/report-template" onClick={() => {
          tToggle();
        }}>{props.t("Report Template")}</Link>
      </li>
                }


    </ul>
            </li>


{
  (user?.user_previleges?.find(e => e.u_urid == user?.u_urid && Number(e.urp_mid) != Number(constants?.menu_id?.reportreq) && Number(e.urp_smid) != Number(constants?.menu_id?.reportapp))) &&

    < li >
      <Link to="/#" className="has-arrow">
        <i className="bx bx-home-circle"></i>
        <span className="side-name" >{props.t("Masters")}</span>
      </Link>
      <ul className="sub-menu">
        {details.MenuDetails.map((row) => {
          const hasPrivilege = user?.user_previleges?.find(e => e?.u_urid === user?.u_urid && Number(e?.urp_mid) == Number(constants?.menu_id?.reportapp) && e?.urp_smid == Number(row?.MenuId));

          if (hasPrivilege) {
            return (
              <li onClick={() => togglemenu(row.MenuId)} key={row.MenuId} id={row.MenuId + 'menu_li_active'}>
                <Link to="#" id={row.MenuId + 'menu_link_active'} onClick={() => {
                  tToggle();
                }}>{row.MenuTitle}</Link>
              </li>
            );
          }
          return null;
        })}
        {
          user?.user_previleges.find(e => e.u_urid == user?.u_urid && Number(e?.urp_mid) != Number(constants?.menu_id?.reportapp) && Number(e?.urp_smid) == Number(constants?.menu_id?.reportapp)) &&

          <li onClick={() => activeMenu('/user-list')} id='menu_li_active_user'>
              <Link to="/user-list" id='menu_link_active_user' onClick={() => {
                tToggle();
              }}>{props.t("User")}</Link>
            </li>
                  }
          </ul>
              </li>
}
{/* } */ }


{/* <li>
                  <Link to="/user-role">{props.t("User Role")}</Link>
                </li> */}
{/* <li>
              <Link to="/template-creation" >
                <i className="fas fa-head-side-mask
 font-size-16 align-middle me-1" />
                <span className="side-name">{props.t("Report Template")}</span>
              </Link>
            </li> */}
{/* <li>
              <Link to="/#" className="has-arrow">
                <i className="dripicons-user"></i>
                <span>{props.t("User Management")}</span>
              </Link>
              <ul className="sub-menu">
                <li>
                  <Link to="/user-list">{props.t("User List")}</Link>
                </li>
                <li>
                  <Link to="/user-role">{props.t("User Role")}</Link>
                </li>


              </ul>
            </li> */}


          </ul >
        </div >
      </SimpleBar >
    </React.Fragment >
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
