const ENDPOINT = {
    REFRESH_TOKEN: "/refreshToken",
}
const API = {
    USER_MASTER: "api/user-master"
}

export {
    ENDPOINT,
    API
}