import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState, useRef } from "react";
import {
    Row, Col, Button, UncontrolledTooltip,
    Card, CardBody
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import { useFormik } from "formik";
import Spinners from "../../components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import '../../components/Common/common.scss'
//i18n
import * as constants from "../../constants/layout"
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DeleteModal from "../../components/Common/DeleteModal";
import { API, ENDPOINT } from '../Authentication/api/index';
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import axios from "axios"
import moment from 'moment';
import '../Report/report.scss'
import CommonFilter from "pages/Common/CommonFilter";
import Logout from "pages/Authentication/Logout";
import '../Report/report.scss'

const ReportRequest = props => {
    //meta title
    document.title = "ReportApproval | Care AKPS";
    const navigate = useNavigate()
    const [hospital, setHospital] = useState([]);
    const [doctor, setDoctor] = useState([]);
    const [patient, setPatient] = useState([]);

    const [canCreate, setCanCreate] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [reportEntryList, setReportEntryList] = useState(false);
    const [deleteData, setDeleteDate] = useState('');
    const hrRef = useRef(null);
    const user = getLoggedInUser();
    const [reffererOptions, setReffererOptions] = useState([]);

    useEffect(() => {
        document.getElementById('headerTitle').innerHTML = 'Report Request';
    }, []);
    useEffect(() => {

        if ((user?.user_previleges.filter(e => Number(e.u_urid) == Number(user?.u_urid)
            && Number(e.urp_mid) == 1 && Number(e.urp_smid) == 1
        )).length > 0) {
            console.log('----')
        } else {
            localStorage.removeItem("authUser");
            navigate('/login')
        }


    }, [user])



    // Initial Load
    useEffect(() => {
        setCanCreate(true)
        getReportEntryList()
        getHospitalList()
        getPatientList()
        bindMasterDropdown()
    }, [])

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            patientname: props.patientname || '',
            uhid: '',
            selectedHospital: '',
            selectedreferrername: '',
            reportno: '',
            newpatientname: '',
            selectedNewHp: '',
            selectedUHIDPATIENTEXIST: '',
            age: '',
            gender: '',
            phnum: '',
            address: '',
            city: '',
            reportDate: '',

        },
        validationSchema: Yup.object({
            patientname: Yup.string().required("Please enter patient name"),
            selectedHospital: Yup.object().required("Please select hospital"),
            selectedreferrername: Yup.object().required("Please select referrer name"),
            newpatientname: Yup.string().required("Please enter Patient Name"),
            selectedNewHp: Yup.string().required("Please enter hospital"),
            gender: Yup.string().required("Please select gender"),
            reportno: Yup.string().required("Please enter report no"),
            reportDate: Yup.date().required('Report Date is required')
        }),


    });

    const handleEditPatient = (row) => {
        navigate(`/report-request-add/${row?.rpt_id}`, { state: { statusFlag: 1, value: row } })

    };

    async function bindMasterDropdown() {
        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + API.DEFAULTMASTER + "/" + ENDPOINT.GETMASTERS, data, config);


            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await bindMasterDropdown();
                    }
                }
            }

            if (response?.data?.body?.MasterList) {


                setReffererOptions(response.data.body.MasterList.filter(item => item.mv_mrid === 2))

            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error(" master List API Error:", error);
        }

    }

    const columns = useMemo(
        () => [
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        #
                    </div>
                ),
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) => {

                    return (

                        <div className="text-center">
                            {(table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1}
                        </div>
                    );
                }
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Report No. ▼
                    </div>
                ),

                accessorKey: 'rpt_report_no',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const rpt_report_no = cellProps.row.original.rpt_report_no;

                    return (

                        <div className="text-center">

                            <Row>

                                <div>{rpt_report_no}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Report Date ▼
                    </div>
                ),

                accessorKey: 'rpt_report_date',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const rpt_report_date = moment(cellProps.row.original.rpt_report_date).format("DD-MM-YYYY");

                    return (

                        <div className="text-center">

                            <Row>

                                <div>{rpt_report_date}</div>
                            </Row>

                        </div >
                    );
                },
            },

            {
                header: (
                    <div className="text-right" style={{ cursor: 'pointer' }}>
                        Patient Details ▼
                    </div>
                ),

                accessorKey: 'rpt_patient_data.patient_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const patient = cellProps.row.original.rpt_patient_data.patient_name;
                    const age = cellProps.row.original.rpt_patient_data.patient_age;
                    const gender = cellProps.row.original.rpt_patient_data.patient_gender == 1 ? "Male" : cellProps.row.original.rpt_patient_data.patient_gender == 2 ? "Female" : cellProps.row.original.rpt_patient_data.patient_gender == 3 ? "Transgender" : "";
                    const uhid = cellProps.row.original.rpt_patient_data.patient_uhid;

                    return (
                        <div className="text-right">

                            <Row>

                                <div>{constants?.fullCapsBold(patient)}</div>
                            </Row>
                            <Row>

                                <div>
                                    {age} Yrs| {gender} {uhid && <>| UHID:{uhid} </>}
                                </div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-right" style={{ cursor: 'pointer' }}>
                        Doctor ▼
                    </div>
                ),

                accessorKey: 'rpt_doctor_data.referrer_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const hospital_name = cellProps.row.original.rpt_hospital_data.hospital_name;
                    const doctor_name = cellProps.row.original.rpt_doctor_data.referrer_name;
                    return (
                        // <div className="d-flex align-items-center">
                        <div className="text-right">

                            <Row>
                                <div>{constants?.fullCapsBold('DR')}.{constants?.fullCapsBold(doctor_name)}</div>
                                <div>{hospital_name}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Status ▼
                    </div>
                ),

                accessorKey: 'status',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const status = cellProps.row.original.rpt_status;

                    return (

                        status === 0 ? <div className="text-center">
                            <span style={{ width: 'auto' }} className="badge badge-soft-info rounded-pill  ms-1 font-size-12">

                                <i className="me-1" style={{ fontSize: '16px' }} />
                                {"Requested"}
                            </span>

                        </div> : <div className="text-center">
                            <span style={{ width: 'auto' }} className="badge badge-soft-primary rounded-pill  ms-1 font-size-12">

                                <i className="me-1" style={{ fontSize: '16px' }} />
                                {"Rejected"}
                            </span>

                        </div>
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Actions ▼
                    </div>
                ),
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,

                width: '8%',
                cell: (cellProps) => {
                    const rows = cellProps.row.original
                    const id = rows.rpt_id;
                    console.log(rows.rpt_id);
                    return (
                        <div className="d-flex gap-3 justify-content-center" style={{ cursor: 'pointer' }}>



                            <Button
                                to="#"
                                className="btn btn-sm btn-soft-warning pointer"
                                onClick={() => handleEditPatient(rows)}

                            >
                                {/* <i className="mdi mdi-file-document-edit-outline" id="reportedit" /> */}
                                <i className="mdi mdi-account-outline pointer" id={"reportedit" + id} />
                                <UncontrolledTooltip placement="top" target={"reportedit" + id}>
                                    Edit Details
                                </UncontrolledTooltip>
                            </Button>

                            <Button
                                to="#"
                                className="btn btn-sm btn-soft-secondary pointer"
                                onClick={() => {

                                    navigate(`/echo-report/${cellProps.row.original.rpt_id}`);

                                }}
                            >
                                <i className="mdi mdi-file-plus-outline pointer" id={"edittooltip" + id} />
                                <UncontrolledTooltip placement="top" target={"edittooltip" + id}>
                                    Report Entry
                                </UncontrolledTooltip>
                            </Button>

                        </div >
                    );
                }
            },

        ],
        []
    );

    const getReportEntryList = async (fildata) => {
        setLoading(true)
        var varFromDate = new Date()
        var varToDate = new Date()
        if (fildata != undefined) {
            if (fildata[0].selectedDates != null) {
                varFromDate = fildata[0].selectedDates[0]
                varToDate = fildata[0].selectedDates[1]
            }
        }

        var date_options = fildata !== undefined && fildata[0].DateOption !== '' && fildata[0].DateOption !== null && fildata[0].DateOption !== undefined ? fildata[0].DateOption : false

        const data = {
            user_id: Number(user.uid),
            hospital_id: fildata !== undefined && fildata[0].selectedHospital !== '' && fildata[0].selectedHospital?.value !== null && fildata[0].selectedHospital?.value !== undefined ? String(fildata[0].selectedHospital.value) : String(0),
            patient_id: fildata !== undefined && fildata[0].selectedPatient?.value !== '' && fildata[0].selectedPatient?.value !== null && fildata[0].selectedPatient?.value !== undefined ? String(fildata[0].selectedPatient.value) : String(0),
            referrer_id: fildata !== undefined && fildata[0].selectedDoctor?.value !== '' && fildata[0].selectedDoctor?.value !== null && fildata[0].selectedDoctor?.value !== undefined ? String(fildata[0].selectedDoctor.value) : String(0),
            from_date: moment(varFromDate).format("YYYY-MM-DD"),
            to_date: moment(varToDate).format("YYYY-MM-DD"),
            rpt_status: [0, 3],
            data_fetch: date_options === false ? 0 : 1
        };

        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTLIST, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);

            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getReportEntryList(fildata);
                    }
                }
            }

            if (response?.data?.body?.reportEntryList) {
                setReportEntryList(response.data.body.reportEntryList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("Report Entry API Error:", error);
        }
    }

    const getHospitalList = async () => {
        setLoading(true)

        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + API.DEFAULTMASTER + ENDPOINT.GETHOSPITALMASTER, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);

            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getHospitalList();
                    }
                }
            }

            if (response?.data?.body?.hospitalMasterList) {
                setHospital(response.data.body.hospitalMasterList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error("Hospital master List API Error:", error);
        }
    }

    const getPatientList = async () => {
        setLoading(true)

        const data = {
            user_id: Number(user.uid)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + API.DEFAULTMASTER + ENDPOINT.GETMASTERS, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await getPatientList();
                    }
                }
            }
            if (response?.data?.body?.PatientList) {

                setPatient(response.data.body.PatientList);
                setDoctor(response.data.body.MasterList.filter(item => item.mv_mrid === 2));

            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error("Hospital master List API Error:", error);
        }
    }

    const deleteReport = async () => {
        try {
            const data = {
                user_id: Number(user.uid),
                rpt_id: Number(deleteData.rpt_id),
            }
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTDELETE, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await deleteReport();
                    }
                }
            }

            if (response && response?.data?.body?.statusFlag === 1) {
                setDeleteModal(false)
                getReportEntryList()
                toast.success(response?.data?.body?.message, { autoClose: 2000 });
            } else {
                toast.error(response.message, { autoClose: 2000 });
            }
        } catch (error) {
            toast.error("Report Deleted Failed", { autoClose: 2000 });
        }
    };

    const reportApprove = async (rpt_id) => {
        // setList(data);
        setLoading(true)

        const data = {
            user_id: Number(user.uid),
            rpt_id: Number(rpt_id)
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + API.DEFAULTMASTER + ENDPOINT.GETHOSPITALMASTER, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/login')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/login')
                    } else {
                        await reportApprove();
                    }
                }
            }

            if (response?.data?.body?.hospitalMasterList) {
                setHospital(response.data.body.hospitalMasterList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            setLoading(false)
            console.error("Report Approve API Error:", error);
        }
    }


    const handleAddClick1 = () => {
        navigate('/report-request-add/0')
    }

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={deleteReport}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content" style={{ height: '100%' }}>
                <div className="container-fluid">

                    <CommonFilter onFilter={getReportEntryList} />

                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={reportEntryList || []}
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                isAddButton={canCreate}
                                                buttonName="Add"
                                                SearchPlaceholder="Search..."
                                                pagination="pagination"
                                                handleUserClick={handleAddClick1}
                                                buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                theadClass="table-light"

                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                </div>
            </div >
            <ToastContainer />
        </React.Fragment >
    );
};

ReportRequest.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ReportRequest);
