import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { Routes, Route } from "react-router-dom";
import { layoutTypes } from "./constants/layout";
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
import { logoutUser } from 'store/actions';
// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import { useDispatch } from "react-redux";
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from "react-router-dom";

// Activating fake backend
fakeBackend();




// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);


const getLayout = (layoutType) => {
  let Layout = VerticalLayout;
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout;
      break;
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout;
      break;
    default:
      break;
  }
  return Layout;
};

const App = () => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const selectLayoutState = (state) => state.Layout;
  const LayoutProperties = createSelector(
    selectLayoutState,
    (layout) => ({
      layoutType: layout.layoutType,
    })
  );

  const {
    layoutType
  } = useSelector(LayoutProperties);

  const Layout = getLayout(layoutType);

  const handleOnIdle = () => { //logout when user idle
   
    localStorage.removeItem("authUser");
    dispatch(logoutUser(history));
  }
  const handleOnAction = (event) => {
    if (event) {
      // setTimeout(() => {
    
          var currentmilli = new Date().getTime();
          // if (localStorage.getItem('lastInsertTime')) {
          //   var localtime = parseFloat(localStorage.getItem('lastInsertTime'));
          //   var dif = Math.abs(currentmilli - localtime) / 1000;
          //   if (dif > 300) {
          //     localStorage.setItem('lastInsertTime', currentmilli);

          //   }
          // } else {
            localStorage.setItem('lastInsertTime', currentmilli);
         
          // }
       
      // }, 2000);
    }
  };

  const idleTimer = useIdleTimer({
    timeout: 1000 * 60 * 30 , //15minutes
    onIdle: handleOnIdle,
    onAction: handleOnAction,
    debounce: 500
  })

  useEffect(() => {

    // setTimeout(() => {
      if (localStorage.getItem('authUser')) {
        var currentmilli = new Date().getTime();
        if (localStorage.getItem('lastInsertTime')) {
          var localtime = parseFloat(localStorage.getItem('lastInsertTime'));
          var dif = Math.abs(currentmilli - localtime) / 1000;
          if (dif > 300 ) {
            localStorage.removeItem("authUser");
            dispatch(logoutUser(history));
            // localStorage.setItem('lastInsertTime', currentmilli);

          }
        } else {
          // localStorage.setItem('lastInsertTime', currentmilli);
       
        }
      }
    // }, 2000);
    // const handleBeforeUnload = (event) => {
    //   localStorage.removeItem("authUser");
    //   dispatch(logoutUser(history));
    //   return ''
    // };

    // Add event listener
    // window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup event listener on component unmount
    // return () => {
    //   window.removeEventListener('beforeunload', handleBeforeUnload);
    // };
  }, []);
  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <NonAuthLayout>
                {route.component}
              </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>}
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

export default App;