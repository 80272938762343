import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import {
    Row, Col, Button, UncontrolledTooltip,
    Card, CardBody
} from "reactstrap";
import "flatpickr/dist/themes/material_blue.css";
import Spinners from "../../components/Common/Spinner";

import TableContainer from '../../components/Common/TableContainer';
import '../../components/Common/common.scss'
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getLoggedInUser, getRefreshToken } from "../../helpers/fakebackend_helper"
import DeleteModal from "../../components/Common/DeleteModal";
import * as api from "../../pages/Authentication/api/index"
import axios from "axios"
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Logout from "pages/Authentication/Logout";

const ReportTemplate = props => {
    document.title = "ReportTemplate | Care AKPS";

    const navigate = useNavigate()

    const [deleteTrigger, setDeleteTrigger] = useState(false)

    const [canCreate, setCanCreate] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [templateID, setTemplateID] = useState();
    const [templateList, setTemplateList] = useState([]);
    const user = getLoggedInUser();


    useEffect(() => {
        document.getElementById('headerTitle').innerHTML = 'Report Template';
        getReportTemplateList()
    }, []);
    useEffect(() => {

        if ((user?.user_previleges.filter(e => Number(e.u_urid) == Number(user?.u_urid) && Number(e.urp_mid) == 1 && Number(e.urp_smid) == 4)).length > 0) {
            console.log('----')
            // return true
        } else {
            localStorage.removeItem("authUser");  
            navigate('/login')
        }


    }, [user])

    const getReportTemplateList = async () => {
        setLoading(true)
        const data = {
            user_id: Number(user.uid),
        };
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.DEFAULTREPORT + "/" + api.ENDPOINT.GETTEMPLATE, data, config);
            setLoading(false)

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");  
                     navigate('/login')
                 }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");  
                     navigate('/login')
                    } else {
                        await getReportTemplateList();
                    }
                }
            }

            if (response?.data?.body?.reportTemplateList) {
                setTemplateList(response.data.body.reportTemplateList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("student List API Error:", error);
        }
    }

    const onClickDelete = async (templateData) => {
        setTemplateID(templateData?.rpt_tid);
        setDeleteModal(true);
    }


    // Add click
    const handleAddClick = () => {
        navigate('/template-creation/0')
    };

    const handleDeleteTemplate = () => {
        setDeleteTrigger(true)
        setLoading(true)
        const data = { user_id: Number(user.uid), rpt_tid: Number(templateID) }

        deleteTemplate(data)
    };

    const deleteTemplate = async (data) => {
        try {
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + api.API.DEFAULTREPORT + "/" + api.ENDPOINT.DELETETEMPLATE, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");  
                     navigate('/login')
                 }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");  
                         navigate('/login')
                    } else {
                        await deleteTemplate(data);
                    }
                }
            }

            if (response && response?.data?.body?.statusFlag === 1) {
                setDeleteModal(false)
                getReportTemplateList()
                toast.success(response?.data?.body?.message, { autoClose: 2000 });
            } else {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
            }
        } catch (error) {
            toast.error("Template Deleted Failed", { autoClose: 2000 });
        }
    }

    // Initial Load
    useEffect(() => {
        setCanCreate(true)
    }, [])

    const columns = useMemo(
        () => [
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        #
                    </div>
                ),
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) => {


                    return (
                        <div className="text-center">


                            {(table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1}
                        </div>

                    );
                }
            },
            {
                header: (
                    <div className="text-left" style={{ cursor: 'pointer' }}>
                        Template Name ▼
                    </div>
                ),

                accessorKey: 'rpt_t_name',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'txt-center',
                cell: (cellProps) => {
                    const rpt_t_name = cellProps.row.original.rpt_t_name;

                    return (

                        <div className="text-left" style={{ cursor: 'pointer' }}>
                            <span style={{ width: 'auto' }} className="ms-1 font-size-12">
                                {rpt_t_name}
                            </span>

                        </div>
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Status ▼
                    </div>
                ),

                accessorKey: 'sts_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const sts_name = cellProps.row.original.sts_name;

                    return (

                        <div className="d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                            {sts_name == 'Active' ?

                                <span style={{ width: 'auto' }} className="badge badge-soft-success rounded-pill  ms-1 font-size-12">

                                    <i className="mdi mdi-check-circle text-success me-1" style={{ fontSize: '14px' }} />
                                    {sts_name}
                                </span> : <span style={{ width: 'auto' }} className="badge badge-soft-danger rounded-pill  ms-1 font-size-12">

                                    <i className="mdi mdi-check-circle text-danger me-1" style={{ fontSize: '14px' }} />
                                    {sts_name}
                                </span>
                            }
                        </div>
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Actions ▼
                    </div>
                ),
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,

                width: '8%',
                cell: (cellProps) => {
                    const id = cellProps.row.original.rpt_tid
                    console.log(id);
                    return (
                        <div className="d-flex justify-content-center  gap-3" style={{ cursor: 'pointer' }}>
                            <Button
                                to="#"
                                className="btn btn-sm btn-soft-info pointer"
                                onClick={() => {

                                    navigate(`/template-creation/${cellProps.row.original.rpt_tid}`);

                                }}

                            >
                                <i className="mdi mdi-pencil-outline pointer" id={"edittooltip" + id} />
                                <UncontrolledTooltip placement="top" target={"edittooltip" + id}>
                                    Edit
                                </UncontrolledTooltip>
                            </Button>

                            <div
                                to="#"
                                className="btn btn-sm btn-soft-danger pointer"
                                onClick={() => {
                                    const templateData = cellProps.row.original;
                                    onClickDelete(templateData);
                                }}>
                                <i className="mdi mdi-delete-outline pointer" id={"deletetooltip" + id} />
                                <UncontrolledTooltip placement="top" target={"deletetooltip" + id}>
                                    Delete
                                </UncontrolledTooltip>
                            </div>


                        </div>
                    );
                }
            },

        ],
        []
    );

    return (
        <React.Fragment>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteTemplate}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content" style={{ height: '100%' }}>
                <div className="container-fluid">
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={templateList || []}
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                isAddButton={canCreate}
                                                buttonName="Add"
                                                SearchPlaceholder="Search..."
                                                pagination="pagination"
                                                handleUserClick={handleAddClick}
                                                buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                theadClass="table-light"

                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                </div>
            </div >
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </React.Fragment >
    );
};

ReportTemplate.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ReportTemplate);
