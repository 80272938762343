import React from "react";
import { Navigate } from "react-router-dom";
import UserProfile from "../pages/Authentication/user-profile";

import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

import Usermaster from "pages/usermaster";
import MasterScreens from "pages/MasterScreen/MasterScreens";
import EchoReportEntry from "pages/Report/EchoReportEntry";
import ReportApproval from "pages/reportApproval/index";


import PatientRegistration from "pages/patientRegistration";
import Userrole from "pages/userrole";
import AddUser from "pages/userrole/add-userrole"
import Reportlist from "pages/reports/reportlist";
import TemplateCreation from "pages/Template/TemplateCreation";
import ReportTemplate from "pages/report-template/index";
import ReportView from "pages/reports/reportView";
import ReportRequest from "pages/ReportRequest/ReportRequest";
import ReportRequestAdd from "pages/ReportRequest/reportRequestAdd";

const authProtectedRoutes = [
  { path: "/profile", exact: true, component: <UserProfile /> },
  { path: "/user-list", exact: true, component: <Usermaster /> },
  { path: "/master-screen", exact: true, component: <MasterScreens /> },
  { path: "/echo-report/:editId", exact: true, component: <EchoReportEntry /> },
  { path: "/patientRegistration", exact: true, component: <PatientRegistration /> },
  { path: "/user-role", exact: true, component: <Userrole /> },
  { path: "/report-list", exact: true, component: <Reportlist /> },
  { path: "/report-approval", exact: true, component: <ReportApproval /> },
  { path: "/report-request", exact: true, component: <ReportRequest /> },
  {
    path: "/add-userrole",
    exact: true,
    component: <AddUser />,
  },
  {
    path: "/report-request-add/:rptid"
    , exact: true,
    component: <ReportRequestAdd />,
  },

  { path: "/template-creation/:rpt_tid", exact: true, component: <TemplateCreation /> },
  { path: "/report-template", exact: true, component: <ReportTemplate /> },
  { path: "/report-view", exact: true, component: <ReportView /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/report-list" />,
  },
];

const publicRoutes = [
  { path: "/login", exact: true, component: <Login /> },
  { path: "/logout", exact: true, component: <Logout /> },
];

export { authProtectedRoutes, publicRoutes };
