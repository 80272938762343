import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState, useRef } from "react";
import {
    Row, Col, Label, Button, UncontrolledTooltip,
    Card, CardBody, FormGroup, InputGroup, FormFeedback, Form, Collapse,
} from "reactstrap";
import Select from "react-select";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useFormik } from "formik";
import Spinners from "../../components/Common/Spinner";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import TableContainer from '../../components/Common/TableContainer';
import * as Yup from "yup";
import '../../components/Common/common.scss'
//i18n
import { withTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
// const inputRef = useRef(null);
import DeleteModal from "../../components/Common/DeleteModal";
import { API, ENDPOINT } from '../Authentication/api/index';
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import { getRefreshToken } from "../../helpers/fakebackend_helper";
import axios from "axios"
import CommonFilter from "pages/Common/CommonFilter";
import moment from 'moment';
import * as constants from "../../constants/layout"
import Logout from "pages/Authentication/Logout";

import { logoutUser } from "store/actions";

const ReportApproval = props => {
    //meta title
    document.title = "ReportApproval | Care AKPS";
    const navigate = useNavigate()

    const user = getLoggedInUser();

    // MasterData Hospital

    const [isFilterOpen, setIsFilterOpen] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(false);
    const [hospital, setHospital] = useState([]);

    const [reportDataFetch, setReportDataFetch] = useState(false);

    const [isEdit, setIsEdit] = useState(false);
    const [selectedHospital, setselectedhospital] = useState(null);
    const [modal, setModal] = useState(false);
    const [canCreate, setCanCreate] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [selectedDates, setSelectedDates] = useState([new Date(), new Date()]);
    const [view, setView] = useState(false);
    const [reportEntryList, setReportEntryList] = useState(false);
    const [defaultDate, setDefaultDate] = useState([new Date(), new Date()]);
    const [deleteData, setDeleteDate] = useState('');



    useEffect(() => {
        // getExamBoardList()
        document.getElementById('headerTitle').innerHTML = 'Report Approval';
    }, []);


    useEffect(() => {

        if ((user?.user_previleges.filter(e => Number(e.u_urid) == Number(user?.u_urid) && Number(e.urp_mid) == 1 && Number(e.urp_smid) == 2)).length > 0) {
            console.log('----')
        } else {
            localStorage.removeItem("authUser");
            navigate('/login')
        }


    }, [user])


    // Add click

    const handleAddClick = () => {
        navigate('/add-userrole', { state: { urid: '' } })
    };

    const handleEditClick = (userData) => {
        setModal(true)
        setIsEdit(true)
    };

    const handleDeleteUserRole = () => {
        // setDeleteTrigger(true)
    };
    // Modal Open
    const toggle = () => {
        setModal(prev => !prev);
    };
    const toggleStatus = () => {
        setStatus(!status);
    };

    // Initial Load
    useEffect(() => {
        setCanCreate(true)
        getReportEntryList()
    }, [])

    const onClickView = async (data) => {
        console.log('data', data)
        // navigate('/report-view')
        navigate('/report-view', { state: { rpt_id: data?.rpt_id } })

    }

    const columns = useMemo(
        () => [
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        #
                    </div>
                ),
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) => {

                    return (

                        <div className="text-center">
                            {(table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1}
                        </div>
                    );
                }
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Report No. ▼
                    </div>
                ),

                accessorKey: 'rpt_report_no',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const rpt_report_no = cellProps.row.original.rpt_report_no;

                    return (

                        <div className="text-center">

                            <Row>

                                <div>{rpt_report_no}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Report Date ▼
                    </div>
                ),

                accessorKey: 'rpt_report_date',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const rpt_report_date = moment(cellProps.row.original.rpt_report_date).format("DD-MM-YYYY");

                    return (

                        <div className="text-center">

                            <Row>

                                <div>{rpt_report_date}</div>
                            </Row>

                        </div >
                    );
                },
            },

            {
                header: (
                    <div className="text-right" style={{ cursor: 'pointer' }}>
                        Patient Details ▼
                    </div>
                ),

                accessorKey: 'rpt_patient_data.patient_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const patient = cellProps.row.original.rpt_patient_data.patient_name;
                    const age = cellProps.row.original.rpt_patient_data.patient_age;
                    const gender = cellProps.row.original.rpt_patient_data.patient_gender == 1 ? "Female" : "Male";
                    const uhid = cellProps.row.original.rpt_patient_data.patient_uhid;

                    return (
                        // <div className="d-flex align-items-center">
                        <div className="text-right">

                            <Row>

                                <div>{constants?.fullCapsBold(patient)}</div>
                            </Row>
                            <Row>

                                <div>
                                    {age} Yrs| {gender} {uhid && <>| UHID:{uhid} </>}
                                </div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-right" style={{ cursor: 'pointer' }}>
                        Doctor ▼
                    </div>
                ),

                accessorKey: 'rpt_doctor_data.referrer_name',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const hospital_name = cellProps.row.original.rpt_hospital_data.hospital_name;
                    const doctor_name = cellProps.row.original.rpt_doctor_data.referrer_name;
                    return (
                        // <div className="d-flex align-items-center">
                        <div className="text-right">

                            <Row>
                                <div>{constants?.fullCapsBold('DR')}.{constants?.fullCapsBold(doctor_name)}</div>
                                <div>{hospital_name}</div>
                            </Row>

                        </div >
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Status ▼
                    </div>
                ),

                accessorKey: 'rpt_status',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const ur_stsid = cellProps.row.original.rpt_status;

                    return (

                        <div className="text-center">
                            <span style={{ width: 'auto' }} className="badge badge-soft-warning rounded-pill  ms-1 font-size-12">

                                <i className="me-1" style={{ fontSize: '16px' }} />
                                {"Pending"}
                            </span>
                        </div>
                    );
                },
            },
            {
                header: (
                    <div className="text-center" style={{ cursor: 'pointer' }}>
                        Actions ▼
                    </div>
                ),
                // header: 'Actions',
                accessorKey: 'action',
                enableColumnFilter: false,
                enableSorting: true,

                width: '8%',
                // width: (cell) => cell.value.length * 10, 
                cell: (cellProps) => {
                    const id = cellProps.row.original.rpt_id;
                    return (
                        <div className="d-flex gap-3 justify-content-center" style={{ cursor: 'pointer' }}>

                            <Button
                                to="#"
                                className="btn btn-sm btn-soft-warning pointer"
                                onClick={() => {

                                    navigate(`/echo-report/${cellProps.row.original.rpt_id}`);

                                }}
                            >
                                <i className="mdi mdi-file-document-edit-outline pointer" id={"reportedit" + id} />
                                <UncontrolledTooltip placement="top" target={"reportedit" + id}>
                                    Edit Report Info
                                </UncontrolledTooltip>
                            </Button>

                            <div
                                to="#"
                                className="btn btn-sm btn-soft-info pointer"
                                onClick={() => {
                                    const orderData = cellProps.row.original;
                                    onClickView(orderData);
                                }}>
                                <i className="mdi mdi-eye pointer" id={"viewtooltip" + id} />
                                <UncontrolledTooltip placement="top" target={"viewtooltip" + id}>
                                    View
                                </UncontrolledTooltip>
                            </div>

                            {/* <div
                                to="#"
                                className="btn btn-sm btn-soft-info"
                                onClick={() => {
                                    const orderData = cellProps.row.original; onClickView(orderData);
                                }}>
                                <i className="mdi mdi-eye" id="viewtooltip" />
                                <UncontrolledTooltip placement="top" target="viewtooltip">
                                    View
                                </UncontrolledTooltip>
                            </div> */}

                        </div >
                    );
                }
            },

        ],
        []
    );

    const getReportEntryList = async (fildata) => {
        setLoading(true)
        var varFromDate = new Date()
        var varToDate = new Date()
        if (fildata != undefined) {
            if (fildata[0].selectedDates != null) {
                varFromDate = fildata[0].selectedDates[0]
                varToDate = fildata[0].selectedDates[1]
            }
        }
        var date_options = fildata !== undefined && fildata[0].DateOption !== '' && fildata[0].DateOption !== null && fildata[0].DateOption !== undefined ? fildata[0].DateOption : false

        const data = {
            user_id: Number(user.uid),
            hospital_id: fildata !== undefined && fildata[0].selectedHospital !== '' && fildata[0].selectedHospital?.value !== null && fildata[0].selectedHospital?.value !== undefined ? String(fildata[0].selectedHospital.value) : String(0),
            patient_id: fildata !== undefined && fildata[0].selectedPatient?.value !== '' && fildata[0].selectedPatient?.value !== null && fildata[0].selectedPatient?.value !== undefined ? String(fildata[0].selectedPatient.value) : String(0),
            referrer_id: fildata !== undefined && fildata[0].selectedDoctor?.value !== '' && fildata[0].selectedDoctor?.value !== null && fildata[0].selectedDoctor?.value !== undefined ? String(fildata[0].selectedDoctor.value) : String(0),
            from_date: moment(varFromDate).format("YYYY-MM-DD"),
            to_date: moment(varToDate).format("YYYY-MM-DD"),
            rpt_status: [1, 1],
            data_fetch: date_options === false ? 0 : 1
        };
        console.log('data0, data', data)
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTLIST, data, config);
            setLoading(false)


            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/logout')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/logout')
                    } else {
                        await getReportEntryList(fildata);
                    }
                }
            }

            if (response?.data?.body?.reportEntryList) {
                setReportDataFetch(true)
                setReportEntryList(response.data.body.reportEntryList);
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error("Report Entry API Error:", error);
        }
    }

    const onClickDelete = (deleteData) => {
        setDeleteDate(deleteData);
        setDeleteModal(true);
    };

    const deleteReport = async () => {
        try {
            const data = {
                user_id: Number(user.uid),
                rpt_id: Number(deleteData.rpt_id),
            }
            const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
            const response = await axios.post(process.env.REACT_APP_BASE_URL + ENDPOINT.REPORTDELETE, data, config);

            if (response && response?.data?.body?.statusFlag === 3) {
                toast.error(response?.data?.body?.message, { autoClose: 2000 });
                setTimeout(() => {
                    localStorage.removeItem("authUser");
                    navigate('/logout')
                }, 2000);
            }

            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();

                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        localStorage.removeItem("authUser");
                        navigate('/logout')
                    } else {
                        await deleteReport();
                    }
                }
            }

            if (response && response?.data?.body?.statusFlag === 1) {
                setDeleteModal(false)
                getReportEntryList()
                toast.success(response?.data?.body?.message, { autoClose: 2000 });
            } else {
                toast.error(response.message, { autoClose: 2000 });
            }
        } catch (error) {
            toast.error("Report Deleted Failed", { autoClose: 2000 });
        }
    };

    // const reportApprove = async (rpt_id) => {
    //     // setList(data);
    //     setLoading(true)

    //     const data = {
    //         user_id: Number(user.uid),
    //         rpt_id: Number(rpt_id)
    //     };
    //     const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    //     try {
    //         const response = await axios.post(process.env.REACT_APP_BASE_URL + API.DEFAULTMASTER + ENDPOINT.GETHOSPITALMASTER, data, config);
    //         setLoading(false)
    //         if (response && response.statusFlag === 3) {
    //             toast.error(response.message, { autoClose: 2000 });
    //             localStorage.removeItem("authUser");
    //             window.location.reload();
    //             return;
    //         }

    //         if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
    //             const refresh = await getRefreshToken();

    //             if (refresh) {
    //                 if (Number(refresh) === constants.invalidRefreshToken) {
    //                     Logout();
    //                 } else {
    //                     await dataFetch();
    //                 }
    //             }
    //         }

    //         if (response?.data?.body?.hospitalMasterList) {
    //             setHospital(response.data.body.hospitalMasterList);
    //         } else {
    //             toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
    //         }
    //     } catch (error) {
    //         // Handle error
    //         setLoading(false)
    //         console.error("Report Approve API Error:", error);
    //     }
    // }

    return (
        <React.Fragment>

            <DeleteModal
                show={deleteModal}
                onDeleteClick={deleteReport}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content" style={{ height: '100%' }}>
                <div className="container-fluid">
                    <CommonFilter onFilter={getReportEntryList} />
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={reportEntryList || []}
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                // isAddButton={canCreate}
                                                // buttonName="Add"
                                                SearchPlaceholder="Search..."
                                                pagination="pagination"
                                                handleUserClick={handleAddClick}
                                                // isCustomPageSize={true}
                                                //  paginationWrapper='dataTables_paginate paging_simple_numbers'

                                                buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                theadClass="table-light"

                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                </div>
            </div >
            <ToastContainer />
        </React.Fragment >
    );
};

ReportApproval.propTypes = {
    t: PropTypes.any,
    chartsData: PropTypes.any,
    onGetChartsData: PropTypes.func,
};

export default withTranslation()(ReportApproval);
